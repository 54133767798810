import { createContext, useContext, useState } from 'react';

const PlayContext = createContext();

export function usePlayContext() {
  return useContext(PlayContext);
}

export function PlayProvider({ children }) {
  const [play, setPlay] = useState(false);
  const [idHls, setIdHls] = useState(null);
  const [mp3Ref, setMp3Ref] = useState(null);
  const [VideoRef, setVideoRef] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);

  return (
    <PlayContext.Provider value={{ play, idHls, mp3Ref, currentTime,VideoRef, setVideoRef, setCurrentTime, setIdHls, setPlay, setMp3Ref}}>
      {children}
    </PlayContext.Provider>
  );
}
