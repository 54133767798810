import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import AlbumSpotify from './components-spotify/albumSpotify';
import { useToken } from './components-spotify/TokenContext';
import PlaylistSpotify from './components-spotify/playlistSpotify';
import withAuth from './auth/withAuth';
import SpotifyNavbar from './spotifyNavbar';
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';
import { useLocation,useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';



const SpotifyLibrary = () => {
  const [data, setData] = useState(null);
  const [dataPlaylist, setDataPlaylist] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token, refreshAccessToken } = useToken(); // Access token and refreshAccessToken from context
  const { width, setWidth } = useSpotifyStreamLink();
  const { navColor, setNavColor } = useSpotifyStreamLink();
  const { userId, setSetUserId } = useSpotifyStreamLink();
  const { userName, setUserName } = useSpotifyStreamLink();
  const location = useLocation();
  const navigate = useNavigate();



  useEffect(() => {
    fetchData();
    fetchPlaylistData();
  }, [location]);

  const apiURL = `http://65.109.181.122:3000/show-saved-albums/${userId}`;
  const playlistApiURL = `http://65.109.181.122:3000/show-saved-playlists/${userId}`;



  // Create an Axios instance for Spotify API requests
  const albumApi = Axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `${localStorage.getItem('authToken')}`
    },
  });
  const playlistApi = Axios.create({
    baseURL: playlistApiURL,
    headers: {
      Authorization: `${localStorage.getItem('authToken')}`
    },
  });


  const fetchData = async () => {
    try {
      const response = await albumApi.get('');
      if (response.status === 200) {
        const result = response.data;
        setData(result);
      } else {
        console.error('Failed to fetch data from the Spotify API');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    } finally {
      setNavColor("#212121")
      setLoading(false);
    }
  };




  const fetchPlaylistData = async () => {
    try {
      const response = await playlistApi.get('');
      if (response.status === 200) {
        const result = response.data;
        setDataPlaylist(result);
      } else {
        console.error('Failed to fetch data from the Spotify API');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const likeSongPage = () => {
    navigate(`/spotify/collection/tracks`);
};

  const albumsSection = dataPlaylist && data ? data : null;
  const playlistsSection = dataPlaylist ? dataPlaylist : null;

  return (
    <div className="rounded-lg mt-3 md:mr-2 relative overflow-scroll bg-gradient-to-b from-[#212121] to-[#121212] no-scrollbar">
      <div className='w-full h-16'>
      </div>
      <h1 className='ml-10 mt-10 mb-5 font-bold text-2xl'>Liked Songs</h1>
      <div className=' mb-4 ml-12 w-44 rounded-lg p-4  cursor-pointer hover:bg-neutral-800' onClick={likeSongPage}>
        <LazyLoadImage
          src="https://misc.scdn.co/liked-songs/liked-songs-640.png"
          effect="blur"
          className='rounded-lg'
        />
          <div className='truncate mt-3 mb-5'>
            <p className='truncate text-white font-bold'>{userName}</p>
          </div>
      </div>
      <h1 className='ml-10 mt-10 mb-5 font-bold text-2xl'>Liked Playlists</h1>
      <PlaylistSpotify playlistsSection={playlistsSection} />
      <h1 className='ml-10 mt-10 mb-5 font-bold text-2xl'>Liked Albums</h1>
      <AlbumSpotify albumsSection={albumsSection} />
    </div>
  );
};

export default withAuth(SpotifyLibrary);
