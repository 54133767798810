import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { usePlaylistContext } from '../context/PlaylistContext';
import { useMusicContext } from '../context/MusicContext';
import { usePlayContext } from '../context/PlayContext';
import { useIdpContext } from '../context/IdpContext';
import { useIdContext } from '../context/IdContext';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate





const HlsPlayer = () => {
    const { selectedPlaylist, setSelectedPlaylist } = usePlaylistContext();
    const { playlistItem, setPlaylistItem } = usePlaylistContext();
    const { showPlaylist, setShowPlaylist } = usePlaylistContext();
    const { selectedIdp, setSelectedIdp } = useIdpContext();
    const { selectedId, setSelectedId } = useIdContext();
    const { image, setImage } = useMusicContext();
    const { song, setSong } = useMusicContext();
    const { artist, setArtist } = useMusicContext();
    const { bg, setBg } = useMusicContext();
    const { currentIndex, setCurrentIndex } = useMusicContext();
    const { play, setPlay } = usePlayContext();
    const { hls, setHls } = usePlayContext();
    const { idHls, setIdHls } = usePlayContext();
    const { mp3Ref, setMp3Ref } = usePlayContext();
    const { VideoRef, setVideoRef } = usePlayContext();
    const { currentTime, setCurrentTime } = usePlayContext();
    let hlsUrl = selectedPlaylist.type === 'video' ? selectedPlaylist.hls : selectedPlaylist.hq_hls;
    const next = playlistItem ? playlistItem[currentIndex] : null;
    const prev = playlistItem ? playlistItem[currentIndex - 1] : null;
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const hlsInstance = useRef(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const location = useLocation()
    const isSpotify = location.pathname.includes('/spotify');










    useEffect(() => {
        if (hlsUrl.endsWith('.m3u8')) {


            // Check if HLS is supported in the browser
            if (Hls.isSupported()) {
                // Initialize hls.js
                hlsInstance.current = new Hls();
                if (hlsUrl.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    hlsUrl = hlsUrl.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
                } else if (hlsUrl.startsWith('https://host2.mediacon-rj.app')) {
                    hlsUrl = hlsUrl.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
                } else (
                    hlsUrl = hlsUrl
                )
                hlsInstance.current.loadSource(hlsUrl);
                hlsInstance.current.attachMedia(videoRef.current);

                hlsInstance.current.on(Hls.Events.MANIFEST_PARSED, () => {
                    setIsLoading(false); // Data is loaded, set isLoading to false
                });

                // Handle errors
                hlsInstance.current.on(Hls.Events.ERROR, (event, data) => {
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                setIsLoading(true); // Set isLoading to true when there's a network error
                                // Handle network errors
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                setIsLoading(true); // Set isLoading to true when there's a network error
                                // Handle media errors
                                break;
                            default:
                                setIsLoading(true); // Set isLoading to true when there's a network error
                                // Handle other errors
                                break;
                        }
                    }
                });
            } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                // Use the built-in HLS support in Safari
                if (hlsUrl.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    hlsUrl = hlsUrl.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
                } else if (hlsUrl.startsWith('https://host2.mediacon-rj.app')) {
                    hlsUrl = hlsUrl.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
                }
                videoRef.current.src = hlsUrl;
                setIsLoading(false);



            }
        };


        const handleTimeUpdate = () => {
            if (videoRef.current) {
                setCurrentTime(videoRef.current.currentTime);
            }
            setIsLoading(false);

        };
        if (videoRef.current) {
            videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
            setMp3Ref(videoRef.current);
            setVideoRef(videoRef);
            videoRef.current.addEventListener('ended', IndexNext);
        };

        return () => {
            // Remove event listener and clean up HLS.js when the component unmounts
            if (videoRef.current) {
                videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            }




            if (hlsInstance.current) {
                hlsInstance.current.destroy();
            }
        };
    }, [hlsUrl]);

    const IndexNext = () => {
        // Increment the currentIndex to play the next item
        if (currentIndex < playlistItem.length - 1) {
            // hlsInstance.current = new Hls();
            // if (hlsInstance.current) {
            //     let hlsNext = next.hq_hls ? next.hq_hls : next.hls
            //     if (hlsNext.startsWith('https://host1.mediacon-rj.app')) {
            //         // Replace the starting part of the URL
            //         hlsNext = hlsNext.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
            //     } else if (hlsNext.startsWith('https://host2.mediacon-rj.app')) {
            //         hlsNext = hlsNext.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
            //     }
            //     hlsInstance.current.loadSource(hlsNext);
            //     hlsInstance.current.attachMedia(videoRef.current);
                if (next.podcast_artist) {
                    setSelectedPlaylist(next);
                    setSelectedIdp(null);
                    setImage(next.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                    setSong(next.title);
                    setArtist(next.podcast_artist);
                    setIdHls(next.id);
                    setBg(next.bg_colors);
                } else {
                    setSelectedPlaylist(next);
                    setImage(next.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                    setSong(next.song);
                    setArtist(next.artist);
                    setSelectedId(null);
                    setIdHls(next.id);
                    setBg(next.bg_colors);
                }
            // }
            setCurrentIndex(currentIndex + 1);
        } else {
            // If at the end, loop back to the beginning (assuming a circular playlist)
            setCurrentIndex(0);
        }

    };


    const IndexPrev = () => {
        // Increment the currentIndex to play the prev item
        if (currentIndex < playlistItem.length - 1) {
            // hlsInstance.current = new Hls();
            // if (hlsInstance.current) {
            //     let hlsPrev = prev.hq_hls ? prev.hq_hls : prev.hls
            //     if (hlsPrev.startsWith('https://host1.mediacon-rj.app')) {
            //         // Replace the starting part of the URL
            //         hlsPrev = hlsPrev.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
            //     } else if (hlsPrev.startsWith('https://host2.mediacon-rj.app')) {
            //         hlsPrev = hlsPrev.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
            //     }
            //     hlsInstance.current.loadSource(hlsPrev);
                // hlsInstance.current.attachMedia(videoRef.current);
                if (prev.podcast_artist) {
                    setSelectedPlaylist(prev);
                    setImage(prev.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                    setSong(prev.title);
                    setArtist(prev.podcast_artist);
                    setSelectedIdp(null);
                    setIdHls(prev.id);
                    setBg(prev.bg_colors);

                } else {
                    setSelectedPlaylist(prev);
                    setImage(prev.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                    setSong(prev.song);
                    setArtist(prev.artist);
                    setSelectedId(null);
                    setBg(prev.bg_colors);
                }
            // }
            setCurrentIndex(currentIndex - 1);
        } else {
            // If at the end, loop back to the beginning (assuming a circular playlist)
            setCurrentIndex(0);
        }
    };


    const handlePlay = () => {
        if (!isLoading) {
            mp3Ref.play();
            setPlay(true);
        }
    };

    const handlePause = () => {
        if (!isLoading) {
            mp3Ref.pause();
            setPlay(false);
        }
    };

    const handleShowPlaylist = () => {
        if (showPlaylist) {
            setShowPlaylist(false);
        } else {
            setShowPlaylist(true);
        }
    };


    const handleSeek = (seekTime) => {
        if (videoRef.current) {
            videoRef.current.currentTime = seekTime;
            setCurrentTime(seekTime); // Update the current time state if needed
        }
    };



    const formatTime = (timeInSeconds) => {
        if (!isNaN(timeInSeconds)) {
            const minutes = Math.floor(timeInSeconds / 60);
            const seconds = Math.floor(timeInSeconds % 60);
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        } else {
            // Handle the case when timeInSeconds is not a valid number
            return '00:00';
        }
    };

    const handleDownloadClick = () => {
        // Create an invisible anchor element
        const link = document.createElement('a');
        link.href = selectedPlaylist.link;
        if (link.href.startsWith('https://host1.mediacon-rj.app')) {
            // Replace the starting part of the URL
            link.href = link.href.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
        } else if (link.href.startsWith('https://host2.mediacon-rj.app')) {
            link.href = link.href.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
        }
        link.target = '_blank'; // Open the link in a new tab
        link.click();
    };
    const [volume, setVolume] = useState(1); // Initialize volume to maximum (1)

    // Function to handle volume changes
    const handleVolumeChange = (newVolume) => {
        videoRef.current.volume = newVolume;
        setVolume(newVolume);
    };
    const secondsNext = () => {
        if (videoRef.current) {
            videoRef.current.currentTime += 10
        }
    }
    const secondsPrev = () => {
        if (videoRef.current) {
            videoRef.current.currentTime -= 10
        }
    }

    navigator.mediaSession.metadata = new MediaMetadata({
        title: song,
        artist: artist,
        artwork: [
            {
                src: selectedPlaylist && selectedPlaylist.thumbnail
                    ? selectedPlaylist.thumbnail.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com')
                    : selectedPlaylist && selectedPlaylist.photo,
                type: 'image/jpeg'
            },
            // You can provide multiple artwork sizes and types for better compatibility
        ],
    });
    // const isIOS = /(iPhone|iPad)/i.test(navigator.userAgent);

    // if (!isIOS) {
        navigator.mediaSession.setActionHandler('previoustrack', function () {
            IndexPrev();
        });
    
        navigator.mediaSession.setActionHandler('nexttrack', function () {
            IndexNext();
        });
        navigator.mediaSession.setActionHandler('seekto', (evt) => {
            handleSeek(evt.seekTime)
          });
          navigator.mediaSession.setActionHandler('play', function () {
            handlePlay();
          });
          navigator.mediaSession.setActionHandler('pause', function () {
            handlePause();
          });
    // }

    const toggleFullScreen = () => {
        const videoElement = videoRef.current;

        if (videoElement) {
            if (videoElement.requestFullscreen) {
                videoElement.requestFullscreen();
            } else if (videoElement.mozRequestFullScreen) {
                videoElement.mozRequestFullScreen();
            } else if (videoElement.webkitRequestFullscreen) {
                videoElement.webkitRequestFullscreen();
            } else if (videoElement.msRequestFullscreen) {
                videoElement.msRequestFullscreen();
            }
        }
    };
    if (isSpotify) {
        // Return null to hide the menu
        return (
          null
        );
      }





    return (
        <>
            {window.innerWidth < 768 ?
                <div className='w-full md:hidden mb-[70px]' onClick={handleShowPlaylist}>

                    <Slider
                        min={0}
                        max={videoRef.current && !isNaN(videoRef.current.duration) ? videoRef.current.duration.toString() : '0'}
                        value={currentTime}
                        keyboard="false"
                        className="custom-slider-mobile"

                    />
                    <div className="flex  items-center justify-around">
                        <div className='flex gap-x-4 items-center'>
                            {hlsUrl.endsWith('.m3u8') ? (
                                <video
                                    className={`w-24 ${selectedPlaylist.type === 'video' ? '' : 'hidden'}`}
                                    ref={videoRef}
                                    autoPlay
                                    playsInline
                                    onEnded={IndexNext}
                                    onClick={toggleFullScreen}
                                />
                            ) : hlsUrl.endsWith('.mp3') ? (
                                <audio
                                    ref={videoRef}
                                    className='fixed'
                                    autoPlay
                                    playsInline
                                    onEnded={IndexNext}
                                >
                                    <source src={hlsUrl} ></source>
                                </audio>
                            ) : null}                            <img className={`w-16 ${selectedPlaylist.type === 'video' ? 'hidden' : ''}`} src={image}></img>
                            <div className='w-2/3'>
                                <p className="truncate font-semibold text-sm">{song}</p>
                                <p className="truncate text-gray-400 text-xs">{artist}</p>
                            </div>
                        </div>

                        <button onClick={play === false ? handlePlay : handlePause}>{play === false
                            ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                            </svg>

                            : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                            </svg>

                        }
                        </button>


                    </div>


                </div>
                :
                <div className="z-50 md:flex hidden flex-wrap justify-around items-center w-full">
                    <div className="flex w-1/6 gap-x-4 items-center my-2 ">

                        {hlsUrl.endsWith('.m3u8') ? (
                            <video
                                className={`w-24 ${selectedPlaylist.type === 'video' ? '' : 'hidden'}`}
                                ref={videoRef}
                                autoPlay
                                playsInline
                                onEnded={IndexNext}
                                onClick={toggleFullScreen}
                            />
                        ) : hlsUrl.endsWith('.mp3') ? (
                            <audio
                                ref={videoRef}
                                className='fixed'
                                autoPlay
                                playsInline
                                onEnded={IndexNext}
                            >
                                <source src={hlsUrl} ></source>
                            </audio>
                        ) : null}
                        <img className={`w-16 ${selectedPlaylist.type === 'video' ? 'hidden' : ''}`} src={image}></img>
                        <div className='w-3/4'>
                            <p className="truncate font-semibold text-sm">{song}</p>
                            <p className="truncate text-gray-400 text-xs">{artist}</p>
                        </div>
                    </div>
                    <div className=" flex items-center md:w-1/2 my-2 w-full flex-wrap">
                        <div className=" flex justify-center w-full gap-x-5 items-center">
                            <button onClick={IndexPrev} className=" disabled:text-gray-400" disabled={currentIndex === 0}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z" />
                            </svg>
                            </button>
                            <button onClick={secondsPrev} className=" disabled:text-gray-400" >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                </svg>
                            </button>
                            <button onClick={isLoading ? null : (play === false ? handlePlay : handlePause)}>
                                {isLoading ? (
                                    // isLoading indicator (replace with your own isLoading component)
                                    <svg aria-hidden="true" className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-[#282828] fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                ) : (play === false
                                    ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                    </svg>

                                    : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                                    </svg>

                                )}
                            </button>
                            <button onClick={secondsNext}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                                </svg>
                            </button>
                            <button onClick={IndexNext}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z" />
                            </svg>
                            </button>

                        </div>
                        <div className="flex w-full mt-3 items-center gap-x-7">
                            <p className="text-xs text-gray-400">
                                {formatTime(currentTime)}
                            </p>

                            <Slider
                                min={0}
                                max={videoRef.current && !isNaN(videoRef.current.duration) ? videoRef.current.duration.toString() : '0'}
                                value={currentTime}
                                onChange={handleSeek} // Pass the function directly
                                keyboard="false"
                                className="custom-slider"

                            />

                            <p className="text-xs text-gray-400">
                                {formatTime(videoRef.current ? videoRef.current.duration : 0)}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center my-2 justify-center gap-x-4">
                        <div className='flex flex-wrap justify-center'>
                            <button onClick={handleDownloadClick}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                            </svg>
                            </button>
                            <p className='text-center text-[6px] w-full'>
                                Download
                            </p>
                        </div>
                        <button className="" onClick={handleShowPlaylist}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-auto">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>
                        </button>
                        <div className="flex items-center gap-x-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-400">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                            </svg>
                            <Slider
                                min={0}
                                max={1}
                                step={0.01}
                                value={volume}
                                onChange={handleVolumeChange} // Pass the function directly
                                keyboard="false"
                                className="custom-slider handleVolume "
                            />
                        </div>

                    </div>
                </div>
            }
        </>
    );
};

export default HlsPlayer;
