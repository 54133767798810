import React, { useEffect, useState } from 'react';

import { PlaylistProvider } from './components/context/PlaylistContext'; // Import the PlaylistProvider
import { IdProvider } from './components/context/IdContext'; // Import the PlaylistProvider
import { IdpProvider } from './components/context/IdpContext'; // Import the PlaylistProvider
import { IdplaylistProvider } from './components/context/IdplaylistContext'; // Import the PlaylistProvider
import { IdvProvider } from './components/context/IdvContext'; // Import the PlaylistProvider
import HomeItem from './components/api/HomeItem.js';
import { MusicProvider } from './components/context/MusicContext';
import { PlayProvider } from './components/context/PlayContext';
import Menu from './Menu.js';
import PlayerBar from './components/player/PlayerBar.js';
import Search from './components/api/Search.js';
import ItemPlaylist from './components/player/ItemPlaylist';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import Effic from './components/api/effic';
import EfficArtist from './components/api/EfficArtist';
import SpotifyHome from './test';
import SpotifySearch from './spotifySearch';
import SpotifyTracklistAlbum from './spotifyTracklistAlbum';
import { TokenProvider } from './components-spotify/TokenProvider';
import { SpotifyStreamLinkProvider } from './components-spotify/spotifyStreamLinkContext';
import AudioPlayer from './spotifyPlayer';
import SpotifyTracklistPlaylist from './spotifyTracklistPlaylist';
import Queue from './queue';
import Home from './home';
import SpotifyGenre from './spotifyGenre';
import { AuthProvider } from './auth/AuthContext';
import Register from './auth/register';
import SpotifyNavbar from './spotifyNavbar';
import SpotifyTracklistLikedSong from './spotifyTracklistLikedSong';
import Lyrics from './lyrics';
import SpotifyLibrary from './spotifyLibrary.js';
import UserProfile from './auth/userProfile';
import SpotifyTracklistArtist from './spotifyTracklistArtist';
import UserAdmin from './auth/userAdmin';










function App() {

  // const handleContextMenu = (e) => {
  //   e.preventDefault(); // Prevent the default context menu from appearing
  // };

  return (

    // <div onContextMenu={handleContextMenu}>
    <Router>
      {/* <AuthProvider> */}
      <TokenProvider>
        <SpotifyStreamLinkProvider>
          <PlaylistProvider>
            <IdProvider>
              <IdpProvider>
                <IdvProvider>
                  <IdplaylistProvider>
                    <MusicProvider>
                      <PlayProvider>
                        <div className='w-full h-screen grid grid-cols-[min-content_auto] grid-rows-[7fr_1fr] bg-black'>
                          {/* <div className=" w-[1000px] bg-green-500">Sidebar</div> */}
                          <Menu />
                          <SpotifyNavbar />
                          <Routes>
                            <Route path="/search/:query" element={<Search />} />
                            <Route path="/search" element={<Search />} />
                            <Route path="/dj_effic" element={<EfficArtist />} />
                            <Route path="/spotify" element={<SpotifyHome />} />
                            <Route path="/spotify/library" element={<SpotifyLibrary />} />
                            <Route path="/spotify/profile" element={<UserProfile />} />
                            <Route path="/spotify/admin" element={<UserAdmin />} />
                            <Route path="/spotify/album/:playlistId" element={<SpotifyTracklistAlbum />} />
                            <Route path="/spotify/playlist/:playlistId" element={<SpotifyTracklistPlaylist />} />
                            <Route path="/spotify/artist/:playlistId" element={<SpotifyTracklistArtist />} />
                            <Route path="/spotify/collection/tracks" element={<SpotifyTracklistLikedSong />} />
                            <Route path="/spotify/genre/:genreId" element={<SpotifyGenre />} />
                            <Route path="/spotifySearch/:query" element={<SpotifySearch />} />
                            <Route path="/spotifySearch" element={<SpotifySearch />} />
                            <Route path="/spotify/queue" element={<Queue />} />
                            <Route path="/spotify/lyrics" element={<Lyrics />} />
                            <Route path="/radiojavan" element={<HomeItem />} />
                            <Route path="/" element={<Home />} />
                            <Route path="/login" element={<Register />} />
                          </Routes>
                          <div className="col-span-2 bg-black"><AudioPlayer /></div>

                          <PlayerBar />
                          <ItemPlaylist />
                          
                          
                        </div>
                      </PlayProvider>
                    </MusicProvider>
                  </IdplaylistProvider>
                </IdvProvider>
              </IdpProvider>
            </IdProvider>
          </PlaylistProvider>
        </SpotifyStreamLinkProvider>
      </TokenProvider>
      {/* </AuthProvider> */}
    </Router>

  );
}

export default App;
