import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpotifyStreamLink } from '../components-spotify/spotifyStreamLinkContext';
// import { useAuth } from './AuthContext';

const withAuth = (WrappedComponent) => {
  const WithAuth = (props) => {
    // const { authTokens, isAuthenticated, setAuthTokens } = useAuth();
    const navigate = useNavigate();
    const lastToken= localStorage.getItem('authToken');
    const {userId, setSetUserId} = useSpotifyStreamLink();
    const {userName, setUserName} = useSpotifyStreamLink();




    const checkTokenExpiration = async () => {
      console.log('Checking token expiration...');
      if (!lastToken) {
        // Redirect to login or handle the authentication state accordingly
        navigate('/login');
       
        return;
      }

      try {
        const response = await fetch('http://65.109.181.122:3000/protected', {
          headers: {
            Authorization: `${localStorage.getItem('authToken')}`,
          },
        });

        

        if (!response.ok) {
          // Token is expired, refresh i
          await refreshToken();
        }else{
          const result = await response.json();
          setSetUserId(result.user.id);
          setUserName(result.user.username);
        }
      } catch (error) {
        console.error('Error checking token expiration:', error);
      }
    };
    const setTokensToLocalStorage = ({ token, refreshToken }) => {
      localStorage.setItem('authToken', token);
      localStorage.setItem('refreshToken', refreshToken);
    };
    
    // Get tokens from localStorage
    const getTokensFromLocalStorage = () => {
      const token = localStorage.getItem('authToken');
      const refreshToken = localStorage.getItem('refreshToken');
      return { token, refreshToken };
    };
    
    // Clear tokens from localStorage


    const refreshToken = async () => {
      try {
        const response = await fetch('http://65.109.181.122:3000/refresh-token-user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refreshToken: getTokensFromLocalStorage().refreshToken }),
        });
    
        if (response.ok) {
          const { token } = await response.json();
          // setAuthTokens({ token, refreshToken: getTokensFromLocalStorage().refreshToken });
          setTokensToLocalStorage({ token, refreshToken: getTokensFromLocalStorage().refreshToken });
        } else {
          console.error('Refresh token failed');
          // Redirect to login or handle the authentication state accordingly
          navigate('/login');
        }
      } catch (error) {
        console.error('Error during token refresh:', error);
      }
    };

    useEffect(() => {
      if (lastToken) {
        checkTokenExpiration();
      }else{
        navigate('/login');
      }
    }, [lastToken, navigate]);

    return <WrappedComponent {...props} />;
  };

  return WithAuth;
};

export default withAuth;
