import React, { createContext, useContext, useState } from 'react';

const PlaylistContext = createContext();
  
export function usePlaylistContext() {
  return useContext(PlaylistContext);
}

export function PlaylistProvider({ children }) {
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [playlistItem, setPlaylistItem] = useState(null);
  const [showPlaylist, setShowPlaylist] = useState(false);

  const value = {
    showPlaylist,
    setShowPlaylist,
    playlistItem,
    setPlaylistItem,
    selectedPlaylist,
    setSelectedPlaylist,
  };

  return (
    <PlaylistContext.Provider value={value}>
      {children}
    </PlaylistContext.Provider>
  );
}
