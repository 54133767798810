import React, { useState, useEffect } from 'react';
import Exclusive from '../home_item/Exclusive.js';
import HotTrack from '../home_item/HotTrack.js';
import Playlist from '../home_item/Playlist.js';
import Mp3 from './Mp3.js';
import Podcast from './Podcast.js';
import PlaylistMp3 from './PlaylistMp3.js';
import DjMixes from '../home_item/DjMixes.js';
import Search from './Search.js';
import Effic from './effic.js';
import { useNavigate, useParams } from 'react-router-dom'; // Updated imports




function HomeItem() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const apiUrl = 'https://play.radiojavan.com/api/p/home_items?v=2';

    fetch(`http://65.109.181.122:3000/proxy?url=${encodeURIComponent(apiUrl)}`, {
      method: 'GET',
      headers: {
        // You can add any headers needed for your proxy server here
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData);
        console.log(responseData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  const navigate = useNavigate(); // Use navigate for URL navigation


  return (
    <div className='rounded-lg mt-3 md:mr-2 relative overflow-scroll bg-gradient-to-b from-[#212121] to-[#121212] no-scrollbar' >
      <Search />
      <div className=''>
        <div className=' pl-10 mt-28' >
          <h2 className="text-2xl text-left font-bold mb-2 cursor-pointer" onClick={() => navigate('/dj_effic/')} >DJ Effic</h2>
        </div>
      </div>
      <Effic />
      <Playlist data={data} />
      <Exclusive data={data} />
      <HotTrack data={data} />
      <DjMixes data={data} />
      <Mp3 data={data} />
      <Podcast data={data} />
      <PlaylistMp3 data={data} />
    </div>
  );
}
export default HomeItem;
