import React, { createContext, useContext, useState } from 'react';

const IdContext = createContext();
  
export function useIdContext() {
  return useContext(IdContext);
}

export function IdProvider({ children }) {
  const [selectedId, setSelectedId] = useState(null);

  const value = {
    selectedId,
    setSelectedId,
  };

  return (
    <IdContext.Provider value={value}>
      {children}
    </IdContext.Provider>
  );
}
