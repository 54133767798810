import { useSpotifyStreamLink } from "./spotifyStreamLinkContext";
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Color, { Palette } from "color-thief-react";





const TrackSpotify = (props) => {

    const [playlistData, setPlaylistData] = useState([]);

    const { streamLink, setStreamLink } = useSpotifyStreamLink();

    const { currentIndex, setCurrentIndex } = useSpotifyStreamLink(); // Provide a default value

    const { selectedTrack, setselectedTrack } = useSpotifyStreamLink(); // Provide a default value

    const { selectedPlaylist, setSelectedPlaylist } = useSpotifyStreamLink(); // Provide a default value

    const { isPlaying, setIsPlaying } = useSpotifyStreamLink(); // Store play/pause state

    const tracksSection = props.tracksSection.items





    const formatTime = (timeInSeconds) => {
        if (!isNaN(timeInSeconds)) {
            const totalSeconds = Math.floor(timeInSeconds / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        } else {
            return '00:00';
        }
    };
    const handlePlayPause = () => {
        setIsPlaying(false);
    };


    const playAlbum = async (albumId, index) => {
        const newPlaylist = tracksSection.map(item => ({
            track: {
                duration_ms: item.duration_ms,
                artists: item.artists,
                name: item.name,
                id: item.id,
                album: {
                    images: [{ url: item.album.images[0].url }]
                }
            },
        }));
        console.log(newPlaylist);
        const newTrack = {
            track: {
                duration_ms: albumId.duration_ms,
                artists: albumId.artists,
                name: albumId.name,
                id: albumId.id,
                album: {
                    images: [{ url: albumId.album.images[0].url }]
                }
            },
        }
        console.log(newTrack);
        setselectedTrack(newTrack);
        setSelectedPlaylist(newPlaylist)
        setCurrentIndex(index + 1);
        setIsPlaying(false)
        try {
            const response = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${albumId.id}`);
            if (response.status === 200) {
                const Link = response.data.link;
                setStreamLink(Link);
            } else {
                console.error('Failed to fetch stream link from the external API');
            }
        } catch (error) {
            console.error('Error while fetching the stream link:', error);
        } finally {
            setIsPlaying(true);
            if (selectedPlaylist) {
                const responseNext = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${selectedPlaylist[currentIndex + 1].track ? selectedPlaylist[currentIndex + 1].track.id : null}`);
            }

        }
    };



    return (
        <>
            <h1 className='ml-10 mt-10 mb-5 font-bold text-3xl'>Tracks</h1>
            <div className=' bg-neutral-900 rounded-lg mx-4'>
                {tracksSection && (
                    <div className=''>
                        {tracksSection[0] !== undefined && tracksSection.map((playlist, index) => (
                            <div className='flex items-center justify-between py-1 rounded-md spotify-list px-4' onClick={() => playAlbum(playlist, index)} key={index}>
                                <div className='flex items-center '>
                                    <p className='mx-5 text-neutral-400 w-4 number-s-list md:inline hidden'>{index + 1}</p>
                                    <button className='px-5 py-4 hidden play-s-list'>
                                        {isPlaying && selectedTrack && playlist.id === selectedTrack.track.id ?
                                            <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto" onClick={handlePlayPause} ><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>
                                            :
                                            <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto" onClick={() => playAlbum(playlist, index)}><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>
                                        }
                                    </button>
                                    <Palette src={playlist.album.images[0] ? playlist.album.images[0].url : null} crossOrigin="anonymous" format="hex" colorCount={4}>
                                        {({ data, loading }) => (
                                            <div className="w-10 h-10 flex justify-center items-center" style={{ backgroundColor: data ? data[1] : null }}>
                                                {isPlaying && selectedTrack && playlist.id === selectedTrack.track.id ?
                                                    <img className='w-6 h-6' src='https://open.spotifycdn.com/cdn/images/equaliser-animated-green.f5eb96f2.gif' ></img>
                                                    :
                                                    // <img className=' md:rounded-none rounded-lg' src={playlist.album.images[0] ? playlist.album.images[0].url : null}></img>
                                                    <LazyLoadImage
                                                        src={playlist.album.images[0] ? playlist.album.images[0].url : null}
                                                        effect="blur"
                                                        className=' md:rounded-none rounded-lg w-full h-full'
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    />
                                                }
                                            </div>
                                        )}
                                    </Palette>
                                    <div className='ml-5 w-48 truncate'>
                                        <p className='font-semibold  truncate'>{playlist.name}</p>
                                        {playlist.artists && (
                                            <p className='truncate'>
                                                {playlist.artists.map((artist, index) => (
                                                    <p className='inline mr-1 text-sm text-neutral-400 truncate' key={index} >{artist.name},</p>
                                                ))}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className='md:inline hidden'>
                                    <p className='mr-5 text-neutral-400'>{playlist.album.name}</p>
                                </div>
                                <div >
                                    <p className='text-neutral-400'>
                                        {formatTime(playlist.duration_ms)}
                                    </p>
                                </div>
                            </div>
                        ))}

                    </div>
                )}

            </div>
        </>
    )
}

export default TrackSpotify;