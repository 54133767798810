import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import Color, { Palette } from "color-thief-react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';





import { useParams } from 'react-router-dom';
import { useToken } from './components-spotify/TokenContext';
import AudioPlayer from './spotifyPlayer';
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';
import { useNavigate } from 'react-router-dom';
import SpotifyNavbar from './spotifyNavbar';
import SpotifyAlbumLike from './spotifyAlbumLike';
import SpotifyTrackLike from './spotifyTrackLike';

const SpotifyTracklistArtist = () => {
    const navigate = useNavigate();
    const { playlistId } = useParams();
    const [albumData, setAlbumData] = useState([]);
    const [color, setColor] = useState([]);
    const { token, refreshAccessToken } = useToken(); // Access token and refreshAccessToken from context
    const [loading, setLoading] = useState(true);
    const [isDataLoaded, setDataLoaded] = useState(false); // New state for data loading
    const { streamLink, setStreamLink } = useSpotifyStreamLink();
    const { currentIndex, setCurrentIndex } = useSpotifyStreamLink(); // Provide a default value
    const { selectedTrack, setselectedTrack } = useSpotifyStreamLink(); // Provide a default value
    const { selectedPlaylist, setSelectedPlaylist } = useSpotifyStreamLink(); // Provide a default value
    const { isPlaying, setIsPlaying } = useSpotifyStreamLink(); // Store play/pause state
    const { navColor, setNavColor } = useSpotifyStreamLink();
    const { tokenSpotify, refreshAccessTokenSpotify } = useToken(); // Access token and refreshAccessToken from context
    const apiURL = `http://65.109.181.122:3000/proxyParams?url=https://api-partner.spotify.com/pathfinder/v1/query?operationName=queryArtistOverview&variables=%7B%22uri%22%3A%22spotify%3Aartist%3A${playlistId}%22%2C%22locale%22%3A%22%22%2C%22includePrerelease%22%3Atrue%7D&extensions=%7B%22persistedQuery%22%3A%7B%22version%22%3A1%2C%22sha256Hash%22%3A%229bd4b303bbc7e5a7c008b90197edb7c79c6d8d9c96e9d4e6ac7dfaabf71a9e58%22%7D%7D`;
    const [nextTracksUrl, setNextTracksUrl] = useState(null);
    const scrollContainerRef = useRef(null);
    const [like, setLike] = useState(false);
    const [trackLike, setTrackLike] = useState(null);
    const { isClicked, setIsClicked } = useSpotifyStreamLink();
    const { userId, setSetUserId } = useSpotifyStreamLink();
    const [album, setAlbum] = useState(true)
    const [compilations, setCompilations] = useState(false)
    const [singles, setSingles] = useState(false)
    const [popularReleases, setPopularReleases] = useState(false)


    useEffect(() => {
        setLike(false)
        setIsClicked(false)
        setTrackLike(null)
        setAlbumData([])
        fetchDataUser()
        fetchData();
    }, [playlistId, tokenSpotify]);



    const spotifyApi = Axios.create({
        baseURL: apiURL,
        headers: {
            'Authorization': `Bearer ${tokenSpotify}`,
        },
    });
    spotifyApi.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response && error.response.status === 401) {
                // Token is expired, attempt to refresh it
                const newToken = await refreshAccessTokenSpotify();
                if (newToken) {
                    // Retry the original request with the new token
                    error.config.headers['Authorization'] = `Bearer ${newToken}`;
                    return Axios.request(error.config);
                }
            }
            return Promise.reject(error);
        }
    );

    const fetchData = async () => {
        try {
            const response = await spotifyApi.get('');
            if (response.status === 200) {
                const result = response.data.data.artistUnion;
                setAlbumData(result); // Set the retrieved data to the state
                console.log(result);
                setDataLoaded(true); // Mark data as loaded
            } else {
                console.error('Failed to fetch data from the Spotify API');
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
    };











    const formatTime = (timeInSeconds) => {
        if (!isNaN(timeInSeconds)) {
            const totalSeconds = Math.floor(timeInSeconds / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        } else {
            return '00:00';
        }
    };

    const imgSrc = albumData.visuals && albumData.visuals.headerImage ? albumData.visuals.headerImage.sources[0].url : (albumData.visuals ? albumData.visuals.avatarImage.sources[0].url : null);


    const playAlbum = async (albumId, index) => {
        const newPlaylist = albumData.discography.topTracks.items.map(item => ({
            track: {
                duration_ms: item.track ? item.track.duration.totalMilliseconds : null,
                artists: [{ name: item.track.artists.items[0].profile.name }],
                name: item.track.name,
                id: item.track.id,
                album: {
                    images: [{ url: item.track.albumOfTrack.coverArt.sources[0].url }]
                }
            },
        }));
        console.log(newPlaylist);
        const newTrack = {
            track: {
                duration_ms: albumId.track.duration ? albumId.track.duration.totalMilliseconds : null,
                artists: [{ name: albumId.track.artists ? albumId.track.artists.items[0].profile.name : null }],
                name: albumId.track.name,
                id: albumId.track.id,
                album: {
                    images: [{ url: albumId.track.albumOfTrack ? albumId.track.albumOfTrack.coverArt.sources[0].url : null }]
                }
            },
        }
        console.log(newTrack);
        setselectedTrack(newTrack);
        setSelectedPlaylist(newPlaylist)
        setCurrentIndex(index + 1);
        setIsPlaying(false)
        try {
            const response = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${albumId.track.id}`);
            if (response.status === 200) {
                const Link = response.data.link;
                setStreamLink(Link);
            } else {
                console.error('Failed to fetch stream link from the external API');
            }
        } catch (error) {
            console.error('Error while fetching the stream link:', error);
        } finally {
            setIsPlaying(true);
            if (selectedPlaylist) {
                const responseNext = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${selectedPlaylist[currentIndex + 1].track.id}`);
            }

        }
    };



    const fetchDataUser = async () => {
        try {
            // Perform the initial data fetch
            const customHeaders = {
                Authorization: `${localStorage.getItem('authToken')}`
            };
            const response = await fetch(`http://65.109.181.122:3000/show-saved-albums/${userId}`, {
                headers: customHeaders,
            });
            const data = await response.json();

            console.log(data);

            // Check if the current playlist is saved
            const foundItem = data.items.find(item => item.id === playlistId);
            setLike(!!foundItem);  // Set like to true if the playlist is found, otherwise false
        } catch (error) {
            console.error('Error fetching saved playlists:', error);
        }
    };





    const handlePlayPause = () => {
        if (selectedTrack) {
            setIsPlaying(!isPlaying); // Toggle play/pause state
        }
    };






    const fetchDataTracksUser = async () => {
        try {
            // Perform the initial data fetch
            const customHeaders = {
                Authorization: `${localStorage.getItem('authToken')}`
            };
            const response = await fetch(`http://65.109.181.122:3000/show-saved-tracks/${userId}`, {
                // headers: customHeaders,
            });
            const data = await response.json();

            const updatedItems = albumData.discography.topTracks.items.map(item => {
                // Check if the current item's track id is present in the saved tracks data
                const isTrackLiked = data.items.some(savedItem => savedItem.track.id === item.track.id);

                // Set the trackLike property based on the condition
                return {
                    ...item,
                    trackLike: isTrackLiked,
                };
            });

            // Update the state after processing all items
            setTrackLike(updatedItems);
            console.log(trackLike);
        } catch (error) {
            console.error('Error fetching saved playlists:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchDataTracksUser()
    }, [albumData]);

    const playlistPage = (playlistId) => {
        navigate(`/spotify/album/${playlistId}`);
    };
    const handleAlbum = () => {
        setAlbum(true)
        setCompilations(false)
        setSingles(false)
        setPopularReleases(false)
    }
    const handleCompilations = () => {
        setCompilations(true)
        setAlbum(false)
        setSingles(false)
        setPopularReleases(false)
    }
    const handleSingles = () => {
        setSingles(true)
        setCompilations(false)
        setAlbum(false)
        setPopularReleases(false)
    }
    const handlePopularReleases = () => {
        setPopularReleases(true)
        setCompilations(false)
        setAlbum(false)
        setSingles(false)
    }

    // const { data, loading, error } = useColor(IMAGE_URL, 'hex');
    return (
        <div className='rounded-lg md:mr-2 right-0 mt-3 overflow-scroll no-scrollbar' ref={scrollContainerRef}>
            {isDataLoaded && !loading && albumData ? (
                <div className="flex justify-end rounded-lg flex-wrap">
                    <Palette src={imgSrc} crossOrigin="anonymous" format="hex" colorCount={4}>
                        {({ data, loading }) => {
                            setColor(data ? data[0] : null)
                            setNavColor(data ? data[0] : null)
                            return (
                                null
                            );
                        }}
                    </Palette>
                    <div className="w-full rounded-lg " style={{
                        background: `linear-gradient(to bottom, ${color} 0%, #121212 75vh, #121212 100%)`,
                    }} >
                        <div className='w-[92%] h-16'></div>
                        <div className='md:flex gap-x-5 items-center p-5 mx-auto h-80 w-full overflow-y-scroll bg-cover bg-fixed bg-center bg-no-repeat' style={{ backgroundImage: albumData.visuals.headerImage ? `url(${imgSrc})` : null }}>
                            {albumData.visuals.headerImage ? null : <img src={imgSrc} className='md:w-56 md:h-56 w-40 h-40 md:mx-0 mx-auto shadow-2xl' alt="playlist Cover"></img>}
                            <div className='mx-8 truncate mt-24'>
                                {albumData.profile && albumData.profile.verified
                                    ?
                                    <div className='flex gap-x-2 items-center'>
                                        <svg data-encore-id="icon" role="img" aria-hidden="true" class="w-6 h-6 fill-blue-500" viewBox="0 0 24 24"><path d="M10.814.5a1.658 1.658 0 0 1 2.372 0l2.512 2.572 3.595-.043a1.658 1.658 0 0 1 1.678 1.678l-.043 3.595 2.572 2.512c.667.65.667 1.722 0 2.372l-2.572 2.512.043 3.595a1.658 1.658 0 0 1-1.678 1.678l-3.595-.043-2.512 2.572a1.658 1.658 0 0 1-2.372 0l-2.512-2.572-3.595.043a1.658 1.658 0 0 1-1.678-1.678l.043-3.595L.5 13.186a1.658 1.658 0 0 1 0-2.372l2.572-2.512-.043-3.595a1.658 1.658 0 0 1 1.678-1.678l3.595.043L10.814.5zm6.584 9.12a1 1 0 0 0-1.414-1.413l-6.011 6.01-1.894-1.893a1 1 0 0 0-1.414 1.414l3.308 3.308 7.425-7.425z"></path></svg>
                                        <p className='text-sm text-neutral-300 font-semibold truncate'>{albumData.profile.verified ? 'Verified Artist' : null}</p>
                                    </div>
                                    : null
                                }
                                <h1 className='font-black md:text-8xl text-2xl truncate'>{albumData.profile ? albumData.profile.name : null}</h1>
                                <p className='mt-16 mb-5 text-neutral-300 font-semibold truncate'>{albumData.stats ? albumData.stats.monthlyListeners : null}{" monthly listeners"}</p>
                            </div>
                        </div>
                        <div className="rounded-lg" style={{
                            // background: `linear-gradient(to bottom, ${color}, #121212)`,
                        }} >
                            <div className='p-5' style={{ backgroundColor: 'rgba(18, 18, 18, 0.15)' }}>
                                {trackLike && (
                                    <div className=''>
                                        <div className='flex items-center gap-x-8'>

                                            {selectedTrack && albumData.discography.topTracks.items[0].id === selectedPlaylist[0].track.id
                                                ? <button className={`bg-green-500 relative hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 w-16 h-16 rounded-full cursor-pointer shadow-xl `} onClick={handlePlayPause}>

                                                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto top-4 left-4 absolute transition-all duration-500 ${!isPlaying ? 'rotate-90 opacity-0 -scale-50 w-0' : ''
                                                        }`} ><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>

                                                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto top-4 left-4 absolute transition-all duration-500 ${isPlaying ? '-rotate-90 opacity-0 -scale-50 w-0' : ''
                                                        }`}><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>
                                                </button>
                                                : <button className={`bg-green-500 relative hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 w-16 h-16 rounded-full cursor-pointer shadow-xl `} onClick={() => playAlbum(albumData.tracks.items[0], 0)}>

                                                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto top-4 left-4 absolute transition-all duration-500 ${albumData.discography.topTracks.items == selectedPlaylist ? 'hidden' : ''
                                                        }`}><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>
                                                </button>
                                            }
                                            <SpotifyAlbumLike albumData={albumData} like={like} />
                                        </div>
                                        <div className='border-b-[1px] flex justify-between items-center border-neutral-400 border-opacity-40 my-4'>
                                            <div className='flex gap-x-5 my-2'>
                                                <p className='ml-5 text-neutral-400'>#</p>
                                                <p className=' text-neutral-400'>Title</p>
                                            </div>
                                            <div className='md:inline hidden ml-48'>
                                                <p className=' text-neutral-400'>Album</p>
                                            </div>
                                            <div className='mr-6'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-neutral-400">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                        {trackLike.map((playlist, index) => (
                                            <div className='flex items-center justify-between py-1 rounded-md spotify-list' key={index} onClick={() => playAlbum(playlist, index)}>
                                                <div className='flex items-center '>
                                                    <p className='mx-5 text-neutral-400 w-4 number-s-list md:inline hidden'>{index + 1}</p>
                                                    <button className='px-5 py-4 hidden play-s-list' onClick={() => playAlbum(playlist.track, index)}>
                                                        {isPlaying && selectedTrack && playlist.track.id === selectedTrack.track.id ?
                                                            <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto"><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>
                                                            :
                                                            <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto"><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>
                                                        }
                                                    </button>
                                                    <div>
                                                        {isPlaying && selectedTrack && playlist.track.id === selectedTrack.track.id ?
                                                            <div className='w-10 flex justify-center' >
                                                                <img className='w-4 h-4 ' src='https://open.spotifycdn.com/cdn/images/equaliser-animated-green.f5eb96f2.gif' ></img>
                                                            </div>
                                                            : (playlist.track.albumOfTrack ?
                                                                // <img className='w-10 h-10 md:rounded-none rounded-lg' src={playlist.track.albumOfTrack.coverArt.sources[0].url}></img>
                                                                <Palette src={playlist.track.albumOfTrack.coverArt.sources[0].url} crossOrigin="anonymous" format="hex" colorCount={4}>
                                                                    {({ data, loading }) => (
                                                                        <div className='aspect-square md:rounded-none rounded-lg' style={{ backgroundColor: data ? data[1] : null }}>
                                                                            <LazyLoadImage
                                                                                src={playlist.track.albumOfTrack.coverArt.sources[0].url}
                                                                                alt={playlist.track.name}
                                                                                effect="blur"
                                                                                className='w-10 h-10 md:rounded-none rounded-lg inline'
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Palette>
                                                                : null
                                                            )
                                                        }
                                                    </div>
                                                    <div className='ml-5 w-48 '>
                                                        {selectedTrack && playlist.id === selectedTrack.track.id
                                                            ? <p className='font-semibold truncate text-green-400'>{playlist.track.name}</p>
                                                            : <p className='font-semibold truncate'>{playlist.track.name}</p>
                                                        }

                                                        {playlist.track.artists && (
                                                            <p className='truncate'>
                                                                {playlist.track.artists.items.map((artist, index) => (
                                                                    <p className='inline mr-1 text-sm text-neutral-400 truncate' key={index} >{artist.profile.name},</p>
                                                                ))}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='flex justify-evenly w-32 items-center'>
                                                    <div className=''><SpotifyTrackLike trackData={playlist.track} like={playlist.trackLike} img={playlist.track.albumOfTrack ? playlist.track.albumOfTrack.coverArt.sources[0].url : null} /></div>
                                                    <p className='text-neutral-400'>
                                                        {formatTime(playlist.track.duration.totalMilliseconds)}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                        <h1 className='md:ml-5 mt-10 mb-5 font-bold text-2xl'>Discography</h1>
                                        <div className=' md:ml-5 flex flex-wrap gap-y-2 md:gap-x-4 gap-x-2 my-5'>
                                            <button className={`py-1 px-2 md:text-base text-sm rounded-full bg-neutral-800 hover:bg-neutral-700 text-white ${album ? "text-neutral-800" : null} `} style={{ backgroundColor: album ? 'white' : null, color: album ? '#262626' : null }} onClick={handleAlbum}>Album</button>
                                            <button className={`py-1 px-2 rounded-full bg-neutral-800 hover:bg-neutral-700 text-white ${compilations ? "text-neutral-800" : null} `} style={{ backgroundColor: compilations ? 'white' : null, color: compilations ? '#262626' : null }} onClick={handleCompilations}>compilations</button>
                                            <button className={`py-1 px-2 rounded-full bg-neutral-800 hover:bg-neutral-700 text-white ${singles ? "text-neutral-800" : null} `} style={{ backgroundColor: singles ? 'white' : null, color: singles ? '#262626' : null }} onClick={handleSingles}>singles</button>
                                            <button className={`py-1 px-2 rounded-full bg-neutral-800 hover:bg-neutral-700 text-white ${popularReleases ? "text-neutral-800" : null} `} style={{ backgroundColor: popularReleases ? 'white' : null, color: popularReleases ? '#262626' : null }} onClick={handlePopularReleases}>popularReleases</button>
                                        </div>
                                        {album ?
                                            <div className="mx-10">
                                                <div className="relative">
                                                    <Swiper
                                                        navigation={true} modules={[Navigation]}
                                                        spaceBetween={50}
                                                        slidesPerView={5}
                                                        slidesPerGroup={5}
                                                        speed={700}
                                                        breakpoints={{
                                                            240: {
                                                                slidesPerView: 2,
                                                                spaceBetween: 20,
                                                                slidesPerGroup: 2,
                                                            },
                                                            768: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 40,
                                                                slidesPerGroup: 4,

                                                            },
                                                            1024: {
                                                                slidesPerView: 5,
                                                                spaceBetween: 50,
                                                                slidesPerGroup: 5,

                                                            },
                                                        }}
                                                    >
                                                        {albumData.discography.albums.items.map((playlist, index) => (

                                                            <SwiperSlide className="bg-neutral-900 p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer" key={index} onClick={() => playlistPage(playlist.releases.items[0].id)}>
                                                                <div className="relative  ">
                                                                    <div className="absolute z-20 bottom-0 right-3 cursor-pointer opacity-0 image_spotify transition-all ease-in-out duration-300" >
                                                                        <div className={`bg-green-500 hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 p-3 rounded-full cursor-pointer shadow-xl `} >
                                                                            <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto transition-all duration-500`}>
                                                                                <path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    {/* <img className="rounded-xl" src={playlistsSection ? playlist.images[0].url : null} alt={playlist.title} /> */}
                                                                    <Palette src={playlist.releases && playlist.releases.items[0].coverArt.sources[0] ? playlist.releases.items[0].coverArt.sources[0].url : null} crossOrigin="anonymous" format="hex" colorCount={4}>
                                                                        {({ data, loading }) => (
                                                                            <div className='aspect-square rounded-lg' style={{ backgroundColor: data ? data[1] : null }}>
                                                                                <LazyLoadImage
                                                                                    src={playlist.releases && playlist.releases.items[0].coverArt.sources[0] ? playlist.releases.items[0].coverArt.sources[0].url : null}
                                                                                    alt={playlist.title}
                                                                                    effect="blur"
                                                                                    className='rounded-lg inline'
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Palette>
                                                                </div>
                                                                <div className="mt-5">
                                                                    <p className=" truncate text-white font-bold">{playlist.releases.items[0].name}</p>
                                                                    <p className=" truncate text-gray-400 text-xs">{playlist.releases.items[0].type}</p>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div> : null}
                                        {compilations ?
                                            <div className="mx-10">
                                                <div className="relative">
                                                    <Swiper
                                                        navigation={true} modules={[Navigation]}
                                                        spaceBetween={50}
                                                        slidesPerView={5}
                                                        slidesPerGroup={5}
                                                        speed={700}
                                                        breakpoints={{
                                                            240: {
                                                                slidesPerView: 2,
                                                                spaceBetween: 20,
                                                                slidesPerGroup: 2,
                                                            },
                                                            768: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 40,
                                                                slidesPerGroup: 4,

                                                            },
                                                            1024: {
                                                                slidesPerView: 5,
                                                                spaceBetween: 50,
                                                                slidesPerGroup: 5,

                                                            },
                                                        }}
                                                    >
                                                        {albumData.discography.compilations.items.map((playlist, index) => (

                                                            <SwiperSlide className="bg-neutral-900 p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer" key={index} onClick={() => playlistPage(playlist.releases.items[0].id)}>
                                                                <div className="relative  ">
                                                                    <div className="absolute z-20 bottom-0 right-3 cursor-pointer opacity-0 image_spotify transition-all ease-in-out duration-300" >
                                                                        <div className={`bg-green-500 hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 p-3 rounded-full cursor-pointer shadow-xl `} >
                                                                            <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto transition-all duration-500`}>
                                                                                <path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    {/* <img className="rounded-xl" src={playlistsSection ? playlist.images[0].url : null} alt={playlist.title} /> */}
                                                                    <Palette src={playlist.releases && playlist.releases.items[0].coverArt.sources[0] ? playlist.releases.items[0].coverArt.sources[0].url : null} crossOrigin="anonymous" format="hex" colorCount={4}>
                                                                        {({ data, loading }) => (
                                                                            <div className='aspect-square rounded-lg' style={{ backgroundColor: data ? data[1] : null }}>
                                                                                <LazyLoadImage
                                                                                    src={playlist.releases && playlist.releases.items[0].coverArt.sources[0] ? playlist.releases.items[0].coverArt.sources[0].url : null}
                                                                                    alt={playlist.title}
                                                                                    effect="blur"
                                                                                    className='rounded-lg inline'
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Palette>
                                                                </div>
                                                                <div className="mt-5">
                                                                    <p className=" truncate text-white font-bold">{playlist.releases.items[0].name}</p>
                                                                    <p className=" truncate text-gray-400 text-xs">{playlist.releases.items[0].type}</p>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div> : null}
                                        {singles ?
                                            <div className="mx-10">
                                                <div className="relative">
                                                    <Swiper
                                                        navigation={true} modules={[Navigation]}
                                                        spaceBetween={50}
                                                        slidesPerView={5}
                                                        slidesPerGroup={5}
                                                        speed={700}
                                                        breakpoints={{
                                                            240: {
                                                                slidesPerView: 2,
                                                                spaceBetween: 20,
                                                                slidesPerGroup: 2,
                                                            },
                                                            768: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 40,
                                                                slidesPerGroup: 4,

                                                            },
                                                            1024: {
                                                                slidesPerView: 5,
                                                                spaceBetween: 50,
                                                                slidesPerGroup: 5,

                                                            },
                                                        }}
                                                    >
                                                        {albumData.discography.singles.items.map((playlist, index) => (

                                                            <SwiperSlide className="bg-neutral-900 p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer" key={index} onClick={() => playlistPage(playlist.releases.items[0].id)}>
                                                                <div className="relative  ">
                                                                    <div className="absolute z-20 bottom-0 right-3 cursor-pointer opacity-0 image_spotify transition-all ease-in-out duration-300" >
                                                                        <div className={`bg-green-500 hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 p-3 rounded-full cursor-pointer shadow-xl `} >
                                                                            <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto transition-all duration-500`}>
                                                                                <path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    {/* <img className="rounded-xl" src={playlistsSection ? playlist.images[0].url : null} alt={playlist.title} /> */}
                                                                    <Palette src={playlist.releases && playlist.releases.items[0].coverArt.sources[0] ? playlist.releases.items[0].coverArt.sources[0].url : null} crossOrigin="anonymous" format="hex" colorCount={4}>
                                                                        {({ data, loading }) => (
                                                                            <div className='aspect-square rounded-lg' style={{ backgroundColor: data ? data[1] : null }}>
                                                                                <LazyLoadImage
                                                                                    src={playlist.releases && playlist.releases.items[0].coverArt.sources[0] ? playlist.releases.items[0].coverArt.sources[0].url : null}
                                                                                    alt={playlist.title}
                                                                                    effect="blur"
                                                                                    className='rounded-lg inline'
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Palette>
                                                                </div>
                                                                <div className="mt-5">
                                                                    <p className=" truncate text-white font-bold">{playlist.releases.items[0].name}</p>
                                                                    <p className=" truncate text-gray-400 text-xs">{playlist.releases.items[0].type}</p>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div> : null}
                                        {popularReleases ?
                                            <div className="mx-10">
                                                <div className="relative">
                                                    <Swiper
                                                        navigation={true} modules={[Navigation]}
                                                        spaceBetween={50}
                                                        slidesPerView={5}
                                                        slidesPerGroup={5}
                                                        speed={700}
                                                        breakpoints={{
                                                            240: {
                                                                slidesPerView: 2,
                                                                spaceBetween: 20,
                                                                slidesPerGroup: 2,
                                                            },
                                                            768: {
                                                                slidesPerView: 4,
                                                                spaceBetween: 40,
                                                                slidesPerGroup: 4,

                                                            },
                                                            1024: {
                                                                slidesPerView: 5,
                                                                spaceBetween: 50,
                                                                slidesPerGroup: 5,

                                                            },
                                                        }}
                                                    >
                                                        {albumData.discography.popularReleasesAlbums.items.map((playlist, index) => (

                                                            <SwiperSlide className="bg-neutral-900 p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer" key={index} onClick={() => playlistPage(playlist.id)}>
                                                                <div className="relative  ">
                                                                    <div className="absolute z-20 bottom-0 right-3 cursor-pointer opacity-0 image_spotify transition-all ease-in-out duration-300" >
                                                                        <div className={`bg-green-500 hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 p-3 rounded-full cursor-pointer shadow-xl `} >
                                                                            <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto transition-all duration-500`}>
                                                                                <path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    {/* <img className="rounded-xl" src={playlistsSection ? playlist.images[0].url : null} alt={playlist.title} /> */}
                                                                    <Palette src={playlist.coverArt.sources[0] ? playlist.coverArt.sources[0].url : null} crossOrigin="anonymous" format="hex" colorCount={4}>
                                                                        {({ data, loading }) => (
                                                                            <div className='aspect-square' style={{ backgroundColor: data ? data[1] : null }}>
                                                                                <LazyLoadImage
                                                                                    src={playlist && playlist.coverArt.sources[0] ? playlist.coverArt.sources[0].url : null}
                                                                                    alt={playlist.title}
                                                                                    effect="blur"
                                                                                    className='rounded-lg inline'
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        height: '100%',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Palette>
                                                                </div>
                                                                <div className="mt-5">
                                                                    <p className=" truncate text-white font-bold">{playlist.name}</p>
                                                                    <p className=" truncate text-gray-400 text-xs">{playlist.type}</p>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                            </div> : null
                                        }
                                    </div>
                                )}


                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <p className='text-center'>Loading... </p> // Display a loading message while waiting for data and images
            )}
        </div>
    );
}

export default SpotifyTracklistArtist;
