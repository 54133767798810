import React, { createContext, useContext, useState } from 'react';

const IdvContext = createContext();
  
export function useIdvContext() {
  return useContext(IdvContext);
}

export function IdvProvider({ children }) {
  const [selectedIdv, setSelectedIdv] = useState(null);

  const value = {
    selectedIdv,
    setSelectedIdv,
  };

  return (
    <IdvContext.Provider value={value}>
      {children}
    </IdvContext.Provider>
  );
}
