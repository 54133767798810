import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Updated imports
import { useSearchParams } from 'react-router-dom';
import Mp3 from './Mp3.js';
import Podcast from './Podcast.js';

import SearchResults from '../../SearchResults';
import PlaylistMp3 from './PlaylistMp3.js';
import Video from './Video.js';

function Search() {
    const [searchInput, setSearchInput] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate(); // Use navigate for URL navigation
    const { query } = useParams();

    useEffect(() => {
        const queryFromUrl = searchParams.get('query');
        if (queryFromUrl) {
            setSearchInput(queryFromUrl);
        }
    }, [searchParams]);

    useEffect(() => {
        if (query) {
            fetchData(query);
            setSearchInput(query); // Optional: Set the search input field to the query
        }
    }, [query]);

    const fetchData = async (query) => {
        const apiUrl = `https://play.radiojavan.com/api/p/search?query=${query}`;

        try {
            const response = await fetch(
                `http://65.109.181.122:3000/proxy?url=${encodeURIComponent(apiUrl)}`
            );
            if (response.ok) {
                const data = await response.json();
                setSearchResults(data);
            } else {
                console.error('Failed to fetch data from the API');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const newQuery = e.target.value;
        setSearchInput(newQuery);
        // Update the URL with the new query as part of the path
        navigate(`/search/${encodeURIComponent(newQuery)}`);
    };

    return (
        <>
            <nav className="fixed flex z-30 items-center top-3 h-16 w-1/3 right-0 left-1/3">
                <div className="relative md:w-2/4 w-5/6 mx-auto">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </div>
                    <input
                        placeholder="Search RadioJavan..."
                        value={searchInput}
                        onClick={() => {
                            // Change the URL and navigate to /search/:query
                        }}
                        onChange={handleInputChange}
                        type="text" id="simple-search" className=" text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-[#121212] dark:border-[#282828] dark:placeholder-[#282828] dark:text-white dark:focus:border-white" />
                </div>
            </nav>
            <SearchResults searchResults={searchResults} />
            <Mp3  />
            <Podcast  />
            <Video />
            <PlaylistMp3  />
        </>
    );
}

export default Search;
