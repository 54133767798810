import React, { useState, useEffect, useRef } from 'react'

import { Routes, Route, Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";

import rjimg from './rj.png'
import spimg from './spotify.png'
import refresh from './refresh.gif'
import refreshrj from './refresh-rj.gif'
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';
import withAuth from './auth/withAuth';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Color, { Palette } from "color-thief-react";



const [minWidth, maxWidth] = [80, 700];

function Menu() {
    const navigate = useNavigate();
    const { spotify, setSpotify } = useSpotifyStreamLink()
    const { isClicked, setIsClicked } = useSpotifyStreamLink();
    const { library, setLibrary } = useSpotifyStreamLink();
    const { width, setWidth } = useSpotifyStreamLink();
    const { userId, setSetUserId } = useSpotifyStreamLink();
    const { userName, setUserName } = useSpotifyStreamLink();



    const isResized = useRef(false);



    const svgStyle = {
        fill: '#fff',
        margin: '15px',
        width: '70%'
    };
    const location = useLocation();

    const isHiddenMenu = location.pathname.includes('/dj_effic');
    const isHiddenMenu2 = location.pathname.includes('/login');
    const [playlist, setPlaylist] = useState([]);
    const [album, setAlbum] = useState([]);


    useEffect(() => {
        window.addEventListener("mousemove", (e) => {
            if (!isResized.current) {
                return;
            }

            setWidth((previousWidth) => {
                const newWidth = previousWidth + e.movementX / 2;

                const isWidthInRange = newWidth >= minWidth && newWidth <= maxWidth;

                return isWidthInRange ? newWidth : previousWidth;
            });
        });

        window.addEventListener("mouseup", () => {
            isResized.current = false;
        });
        if (width > 300) {
            setLibrary(true)
        }
    }, []);
    useEffect(() => {
        if (width > 300) {
            setLibrary(true)
        } else if (width < 200) {
            setLibrary(false)
        }
    }, [width]);

    useEffect(() => {
        // Define the API endpoint
        const apiUrl = `http://65.109.181.122:3000/show-saved-playlists/${userId}`;
        const customHeaders = {
            Authorization: `${localStorage.getItem('authToken')}`
        };

        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl, {
                    headers: customHeaders,
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setPlaylist(data); // Assuming the API response has an 'items' property
                console.log(data);
            } catch (error) {
                console.error('Error fetching saved albums:', error.message || error);
            }
        };

        const timeoutId = setTimeout(() => {
            fetchData();
        }, 1500); // Set the timeout duration (in milliseconds), e.g., 1000ms for 1 second

        // Clear the timeout if the component unmounts or if the dependencies change
        return () => clearTimeout(timeoutId);
    }, [isClicked, userId]);

    useEffect(() => {
        // Define the API endpoint
        const apiUrl = `http://65.109.181.122:3000/show-saved-albums/${userId}`;
        const customHeaders = {
            Authorization: `${localStorage.getItem('authToken')}`
        };

        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl, {
                    headers: customHeaders,
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setAlbum(data); // Assuming the API response has an 'items' property
                console.log(data);
            } catch (error) {
                console.error('Error fetching saved albums:', error.message || error);
            }
        };

        const timeoutId = setTimeout(() => {
            fetchData();
        }, 1500); // Set the timeout duration (in milliseconds), e.g., 1000ms for 1 second

        // Clear the timeout if the component unmounts or if the dependencies change
        return () => clearTimeout(timeoutId);
    }, [isClicked, userId]);

    if (isHiddenMenu || isHiddenMenu2) {
        // Return null to hide the menu
        return (
            <div>

            </div>
        );
    }
    const handleChangeSpotify = () => {
        if (spotify) {
            setSpotify(false);
            navigate('/radiojavan');
        } else {
            setSpotify(true);
            navigate('/spotify');
        }
    }
    const handleLibrary = () => {
        if (!library) {
            setLibrary(true)
            setWidth(320)
        } else {
            setLibrary(false)
            setWidth(80)
        }
    }


    const isSearchRoute = location.pathname.startsWith('/spotifySearch');


    const playlistPage = (playlistId) => {
        navigate(`/spotify/playlist/${playlistId}`);
    };
    const albumPage = (albumId) => {
        navigate(`/spotify/album/${albumId}`);
    };
    const likeSongPage = () => {
        navigate(`/spotify/collection/tracks`);
    };
    const libraryPage = () => {
        navigate(`/spotify/library`);
    };



    return (
        <div className='flex'>
            <section className="fixed  md:hidden block z-[60] left-0 w-full h-16 bottom-0 bg-[#121212]">
                <ul className=" flex justify-around h-full items-center">
                    {spotify ? <>
                        <li className="text-[#ABABAB]">
                            <NavLink to="/spotify">
                                {location.pathname === "/spotify"
                                    ? <svg data-encore-id="icon" role="img" aria-hidden="true" className="fill-white mx-auto w-6 h-6" viewBox="0 0 24 24"><path d="M13.5 1.515a3 3 0 0 0-3 0L3 5.845a2 2 0 0 0-1 1.732V21a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6h4v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V7.577a2 2 0 0 0-1-1.732l-7.5-4.33z"></path></svg>
                                    : <svg fill="#ABABAB" viewBox="0 0 24 24" className="mx-auto w-6 h-6 "><path d="M12.5 3.247a1 1 0 0 0-1 0L4 7.577V20h4.5v-6a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v6H20V7.577l-7.5-4.33zm-2-1.732a3 3 0 0 1 3 0l7.5 4.33a2 2 0 0 1 1 1.732V21a1 1 0 0 1-1 1h-6.5a1 1 0 0 1-1-1v-6h-3v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.577a2 2 0 0 1 1-1.732l7.5-4.33z"></path></svg>
                                }</NavLink>
                        </li>
                        <li className="text-[#ABABAB]">
                            <NavLink to="/spotifySearch" exact>
                                {location.pathname === "/spotifySearch" || isSearchRoute
                                    ? <svg data-encore-id="icon" role="img" aria-hidden="true" className="fill-white mx-auto w-6 h-6" viewBox="0 0 24 24"><path d="M15.356 10.558c0 2.623-2.16 4.75-4.823 4.75-2.664 0-4.824-2.127-4.824-4.75s2.16-4.75 4.824-4.75c2.664 0 4.823 2.127 4.823 4.75z"></path><path d="M1.126 10.558c0-5.14 4.226-9.28 9.407-9.28 5.18 0 9.407 4.14 9.407 9.28a9.157 9.157 0 0 1-2.077 5.816l4.344 4.344a1 1 0 0 1-1.414 1.414l-4.353-4.353a9.454 9.454 0 0 1-5.907 2.058c-5.18 0-9.407-4.14-9.407-9.28zm9.407-7.28c-4.105 0-7.407 3.274-7.407 7.28s3.302 7.279 7.407 7.279 7.407-3.273 7.407-7.28c0-4.005-3.302-7.278-7.407-7.278z"></path></svg>
                                    : <svg fill="#ABABAB" viewBox="0 0 24 24" className="mx-auto w-6 h-6"><path d="M10.533 1.279c-5.18 0-9.407 4.14-9.407 9.279s4.226 9.279 9.407 9.279c2.234 0 4.29-.77 5.907-2.058l4.353 4.353a1 1 0 1 0 1.414-1.414l-4.344-4.344a9.157 9.157 0 0 0 2.077-5.816c0-5.14-4.226-9.28-9.407-9.28zm-7.407 9.279c0-4.006 3.302-7.28 7.407-7.28s7.407 3.274 7.407 7.28-3.302 7.279-7.407 7.279-7.407-3.273-7.407-7.28z"></path></svg>
                                }</NavLink>
                        </li>
                        <li>
                            <NavLink to="/spotify/library" className="text-[#ABABAB] py-2 m-1 flex items-center">
                                {location.pathname === "/spotify/library"
                                    ? <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" class="fill-white mx-auto w-6 h-6"><path d="M3 22a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v18a1 1 0 0 1-1 1zM15.5 2.134A1 1 0 0 0 14 3v18a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V6.464a1 1 0 0 0-.5-.866l-6-3.464zM9 2a1 1 0 0 0-1 1v18a1 1 0 1 0 2 0V3a1 1 0 0 0-1-1z"></path></svg>
                                    : <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" class="fill-[#ABABAB] hover:fill-white mx-auto w-6 h-6"><path d="M14.5 2.134a1 1 0 0 1 1 0l6 3.464a1 1 0 0 1 .5.866V21a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V3a1 1 0 0 1 .5-.866zM16 4.732V20h4V7.041l-4-2.309zM3 22a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v18a1 1 0 0 1-1 1zm6 0a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v18a1 1 0 0 1-1 1z"></path></svg>
                                }</NavLink>
                        </li>
                    </> : <><li className="">
                        <NavLink to="/" className="text-[#ABABAB]">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mx-auto w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                            </svg>
                        </NavLink>
                    </li>
                        <li className="text-[#ABABAB]">
                            <handleLibrary to="/search">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mx-auto w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </handleLibrary>
                        </li></>
                    }


                    <div className="relative w-10 border-b-[1px]  border-[#282828] cursor-pointer " onClick={handleChangeSpotify}>
                        {spotify ? <img className='w-10 h-10 rounded-lg border-2 border-white  ' src={spimg}></img> : <img className='w-10 h-10 rounded-lg border-2 border-white ' src={rjimg}></img>
                        }
                        <img className='absolute w-5 -top-2 mx-auto -left-2' src={refresh}></img>
                    </div>
                </ul>

            </section>



            {/* desktop */}
            <div className='hidden md:grid z-40 ml-3 mt-3 gap-y-2 ' style={{ width: `${width / 16}rem` }}>
                <section className=" rounded-lg bg-[#121212] row-span-2">
                    <div className=" border-b-[1px]  border-[#282828] cursor-pointer transition-all pb-7 duration-500 ease-in-out" onClick={handleChangeSpotify}>
                        {spotify ? <img className='w-16 h-16 rounded-lg border-2 border-white mx-auto' src={spimg}></img> : <img className='w-16 h-16 rounded-lg border-2 border-white mx-auto' src={rjimg}></img>
                        }
                        <img className='w-6 mx-auto' src={refresh}></img>

                    </div>
                    <ul>
                        {spotify ?
                            <div className="">
                                <li>
                                    <NavLink to="/spotify" className="text-[#ABABAB] py-2 m-1 flex items-center">
                                        {location.pathname === "/spotify"
                                            ? <svg data-encore-id="icon" role="img" aria-hidden="true" className="fill-white ml-7 w-6 h-6" viewBox="0 0 24 24"><path d="M13.5 1.515a3 3 0 0 0-3 0L3 5.845a2 2 0 0 0-1 1.732V21a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6h4v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V7.577a2 2 0 0 0-1-1.732l-7.5-4.33z"></path></svg>
                                            : <svg fill="#ABABAB" viewBox="0 0 24 24" className="ml-7 w-6 h-6"><path d="M12.5 3.247a1 1 0 0 0-1 0L4 7.577V20h4.5v-6a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v6H20V7.577l-7.5-4.33zm-2-1.732a3 3 0 0 1 3 0l7.5 4.33a2 2 0 0 1 1 1.732V21a1 1 0 0 1-1 1h-6.5a1 1 0 0 1-1-1v-6h-3v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.577a2 2 0 0 1 1-1.732l7.5-4.33z"></path></svg>
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/spotifySearch" className="text-[#ABABAB] py-2 m-1 flex items-center">
                                        {location.pathname === "/spotifySearch" || isSearchRoute
                                            ? <svg data-encore-id="icon" role="img" aria-hidden="true" className="fill-white ml-7 w-6 h-6" viewBox="0 0 24 24"><path d="M15.356 10.558c0 2.623-2.16 4.75-4.823 4.75-2.664 0-4.824-2.127-4.824-4.75s2.16-4.75 4.824-4.75c2.664 0 4.823 2.127 4.823 4.75z"></path><path d="M1.126 10.558c0-5.14 4.226-9.28 9.407-9.28 5.18 0 9.407 4.14 9.407 9.28a9.157 9.157 0 0 1-2.077 5.816l4.344 4.344a1 1 0 0 1-1.414 1.414l-4.353-4.353a9.454 9.454 0 0 1-5.907 2.058c-5.18 0-9.407-4.14-9.407-9.28zm9.407-7.28c-4.105 0-7.407 3.274-7.407 7.28s3.302 7.279 7.407 7.279 7.407-3.273 7.407-7.28c0-4.005-3.302-7.278-7.407-7.278z"></path></svg>
                                            : <svg fill="#ABABAB" viewBox="0 0 24 24" className="ml-7 w-6 h-6"><path d="M10.533 1.279c-5.18 0-9.407 4.14-9.407 9.279s4.226 9.279 9.407 9.279c2.234 0 4.29-.77 5.907-2.058l4.353 4.353a1 1 0 1 0 1.414-1.414l-4.344-4.344a9.157 9.157 0 0 0 2.077-5.816c0-5.14-4.226-9.28-9.407-9.28zm-7.407 9.279c0-4.006 3.302-7.28 7.407-7.28s7.407 3.274 7.407 7.28-3.302 7.279-7.407 7.279-7.407-3.273-7.407-7.28z"></path></svg>
                                        }</NavLink>
                                </li>
                            </div> :
                            <div>
                                <li >
                                    <NavLink to="/radiojavan" className="text-[#ABABAB] py-2 m-1 flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mx-auto w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                        </svg>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/search" className="text-[#ABABAB] py-2 m-1 flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mx-auto w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                        </svg>
                                    </NavLink>
                                </li>
                            </div>
                        }
                    </ul>
                </section>
                <ul className='rounded-lg grid gap-y-2 bg-[#121212] max-h-[54vh]'>
                    <li className='flex items-center gap-x-5 mt-2 h-16'>
                        <button onClick={handleLibrary} className="text-[#ABABAB] py-2 flex items-center">
                            {library
                                ? <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" class="fill-white ml-7 w-6 h-6"><path d="M3 22a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v18a1 1 0 0 1-1 1zM15.5 2.134A1 1 0 0 0 14 3v18a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V6.464a1 1 0 0 0-.5-.866l-6-3.464zM9 2a1 1 0 0 0-1 1v18a1 1 0 1 0 2 0V3a1 1 0 0 0-1-1z"></path></svg>
                                : <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" class="fill-[#ABABAB] hover:fill-white ml-7 w-6 h-6"><path d="M14.5 2.134a1 1 0 0 1 1 0l6 3.464a1 1 0 0 1 .5.866V21a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V3a1 1 0 0 1 .5-.866zM16 4.732V20h4V7.041l-4-2.309zM3 22a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v18a1 1 0 0 1-1 1zm6 0a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v18a1 1 0 0 1-1 1z"></path></svg>
                            }
                        </button>
                        {library && <p className='font-bold cursor-pointer' onClick={libraryPage}>Your Library</p>}
                    </li>
                    <ul className='overflow-scroll no-scrollbar h-[95%] '>
                        <p className='text-xs text-neutral-500 text-center mb-2'>Songs</p>
                        <li className='flex gap-x-2 mb-4 cursor-pointer' onClick={likeSongPage}>
                            <Palette src="https://misc.scdn.co/liked-songs/liked-songs-640.png" crossOrigin="anonymous" format="hex" colorCount={4}>
                                {({ data, loading }) => (
                                    <div className='w-12 h-12 ml-4 rounded-lg' style={{ backgroundColor: data ? data[0] : null }}>
                                        <LazyLoadImage
                                            src="https://misc.scdn.co/liked-songs/liked-songs-640.png"
                                            effect="blur"
                                            className='w-12 h-12 rounded-lg'
                                        />
                                    </div>
                                )}
                            </Palette>
                            {library &&
                                <div className='truncate'>
                                    <p className='font-semibold truncate'>Liked Songs</p>
                                    <p className='text-neutral-400 text-sm truncate'>{userName}</p>
                                </div>
                            }
                        </li>
                        <p className='text-xs text-neutral-500 text-center mb-2'>Playlists</p>
                        {playlist.items && playlist.items.slice().reverse().map((song) => (
                            <li className='flex gap-x-2 mb-4 cursor-pointer' onClick={() => playlistPage(song.id)}>
                                <Palette src={song.images[0].url} crossOrigin="anonymous" format="hex" colorCount={4}>
                                    {({ data, loading }) => (
                                        <div className='w-12 h-12 ml-4 rounded-lg' style={{ backgroundColor: data ? data[0] : null }}>
                                            <LazyLoadImage
                                                src={song.images[0].url}
                                                effect="blur"
                                                className='w-12 h-12 rounded-lg'
                                            />
                                        </div>
                                    )}
                                </Palette>
                                {library &&
                                    <div className='truncate'>
                                        <p className='font-semibold truncate'>{song.name}</p>
                                        <p className='text-neutral-400 text-sm truncate'>{song.owner.display_name}</p>
                                    </div>
                                }
                            </li>
                        ))
                        }
                        <p className='text-xs text-neutral-500 text-center mb-2'>Albums</p>
                        {album.items && album.items.slice().reverse().map((song) => (
                            <li className='flex gap-x-2 mb-4 cursor-pointer' onClick={() => albumPage(song.id)}>
                                <Palette src={song.images[0].url} crossOrigin="anonymous" format="hex" colorCount={4}>
                                    {({ data, loading }) => (
                                        <div className='w-12 h-12 ml-4 rounded-lg' style={{ backgroundColor: data ? data[0] : null }}>
                                            <LazyLoadImage
                                                src={song.images[0].url}
                                                effect="blur"
                                                className='w-12 h-12 rounded-lg'
                                            />
                                        </div>
                                    )}
                                </Palette>
                                {library &&
                                    <div className='truncate'>
                                        <p className='font-semibold truncate'>{song.name}</p>
                                        <p className='text-neutral-400 text-sm truncate'>{song.artists[0].name}</p>
                                    </div>
                                }
                            </li>
                        ))
                        }
                    </ul>
                </ul>
            </div>
            <div
                className="hidden md:block w-2 cursor-col-resize bg-black border-l-[1px] border-black hover:border-[#ABABAB]"
                onMouseDown={() => {
                    isResized.current = true;
                }}
            />
        </div>
    )

}
export default withAuth(Menu);