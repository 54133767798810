// Mp3.js
import React, { useEffect, useState } from 'react';
import { usePlaylistContext } from '../context/PlaylistContext';
import { useIdpContext } from '../context/IdpContext';
import { useMusicContext } from '../context/MusicContext';


function Podcast(props) {
  const [data, setData] = useState(null);
  const { playlistItem,setPlaylistItem } = usePlaylistContext();
  const { selectedIdp} = useIdpContext();
  const { setCurrentIndex } = useMusicContext();



  useEffect(() => {
    // Check if selectedId is not null or undefined before making the API request
    if (selectedIdp !== null && selectedIdp !== undefined) {
      const apiUrl = `https://play.radiojavan.com/api/p/podcast?id=${selectedIdp}`;
  
      fetch(`http://65.109.181.122:3000/proxy?url=${encodeURIComponent(apiUrl)}`, {
        method: 'GET',
        headers: {
          // You can add any headers needed for your proxy server here
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((responseData) => {
          setData(responseData);
          setPlaylistItem([responseData, ...responseData.related]);
          setCurrentIndex(1);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [selectedIdp,setPlaylistItem,setCurrentIndex]);

  
  return null;
}

export default Podcast;
