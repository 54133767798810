import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useSpotifyStreamLink } from "./components-spotify/spotifyStreamLinkContext";
import withAuth from "./auth/withAuth";





const SpotifyNavbar = () => {
    const navigate = useNavigate();
    const [logout, setLogout] = useState()
    const { library, setLibrary } = useSpotifyStreamLink();
    const {userData, setUserData} = useSpotifyStreamLink();
    const { width, setWidth } = useSpotifyStreamLink();
    const { navColor, setNavColor } = useSpotifyStreamLink();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)


    const location = useLocation();

    const isHiddenMenu2 = location.pathname.includes('/login');









    const handleLogout = () => {
        setLogout(!logout)
    }
    const clearTokensFromLocalStorage = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        
        navigate('/login')
    };
    const navigateUserAdmin= () => {
        navigate('/spotify/admin')
    };
    const navigateUserProfile= () => {
        navigate('/spotify/profile')
    };
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    const containerStyle = {
        width: '100%',
        background: navColor,
    };

    if (windowWidth >= 768) {
        containerStyle.width = `calc(100% - ${width}px - 28px)`;
    } else {
        containerStyle.width = '100%'
    }


    if (isHiddenMenu2) {
        // Return null to hide the menu
        return (
            null
        );
    }

    return (
        <div className={`flex flex-row-reverse right-0 md:mr-2 top-3 z-10 h-16 justify-between items-center rounded-lg fixed navbar `} style={containerStyle}>
            <div className='flex items-center my-4 mx-6 gap-x-2 relative'>
                <button className='bg-black rounded-full hover:scale-110' onClick={handleLogout}>
                    <svg data-encore-id="icon" role="img" aria-hidden="true" data-testid="user-icon" viewBox="0 0 16 16" className="w-4 h-4 fill-white m-2"><path d="M6.233.371a4.388 4.388 0 0 1 5.002 1.052c.421.459.713.992.904 1.554.143.421.263 1.173.22 1.894-.078 1.322-.638 2.408-1.399 3.316l-.127.152a.75.75 0 0 0 .201 1.13l2.209 1.275a4.75 4.75 0 0 1 2.375 4.114V16H.382v-1.143a4.75 4.75 0 0 1 2.375-4.113l2.209-1.275a.75.75 0 0 0 .201-1.13l-.126-.152c-.761-.908-1.322-1.994-1.4-3.316-.043-.721.077-1.473.22-1.894a4.346 4.346 0 0 1 .904-1.554c.411-.448.91-.807 1.468-1.052zM8 1.5a2.888 2.888 0 0 0-2.13.937 2.85 2.85 0 0 0-.588 1.022c-.077.226-.175.783-.143 1.323.054.921.44 1.712 1.051 2.442l.002.001.127.153a2.25 2.25 0 0 1-.603 3.39l-2.209 1.275A3.25 3.25 0 0 0 1.902 14.5h12.196a3.25 3.25 0 0 0-1.605-2.457l-2.209-1.275a2.25 2.25 0 0 1-.603-3.39l.127-.153.002-.001c.612-.73.997-1.52 1.052-2.442.032-.54-.067-1.097-.144-1.323a2.85 2.85 0 0 0-.588-1.022A2.888 2.888 0 0 0 8 1.5z"></path></svg>
                </button>
                <div className={`absolute w-52 bg-neutral-800 top-10 right-0 p-1 rounded-md transition-all ease-in-out duration-300 ${logout ? 'opacity-100' : 'opacity-0'}`}>
                    <button className="w-full pl-6 text-left py-2 bg-neutral-800  hover:bg-neutral-700" onClick={navigateUserProfile}>Account</button>
                    {userData.admin?<button className="w-full pl-6 text-left py-2 bg-neutral-800 hover:bg-neutral-700" onClick={navigateUserAdmin}>Admin</button>:null}
                    <button className="w-full pl-6 text-left py-2 bg-neutral-800  hover:bg-neutral-700 border-t-[1px] border-neutral-700" onClick={clearTokensFromLocalStorage}>Log Out</button>
                </div>
            </div>
            <div className='flex items-center gap-x-2 my-4 mx-6 '>
                <button data-testid="top-bar-back-button" aria-label="Go back" className="hover:scale-110 bg-black rounded-full disabled:bg-slate-300 disabled:cursor-not-allowed" onClick={() => { navigate(-1); }}>
                    <svg data-encore-id="icon" role="img" aria-hidden="true" className="w-4 h-4 fill-white m-2 " viewBox="0 0 16 16"><path d="M11.03.47a.75.75 0 0 1 0 1.06L4.56 8l6.47 6.47a.75.75 0 1 1-1.06 1.06L2.44 8 9.97.47a.75.75 0 0 1 1.06 0z"></path></svg>
                </button>
                <button data-testid="top-bar-forward-button" aria-label="Go forward" className=" bg-black rounded-full hover:scale-110" onClick={() => { navigate(1); }}>
                    <svg data-encore-id="icon" role="img" aria-hidden="true" className="w-4 h-4 fill-white m-2" viewBox="0 0 16 16"><path d="M4.97.47a.75.75 0 0 0 0 1.06L11.44 8l-6.47 6.47a.75.75 0 1 0 1.06 1.06L13.56 8 6.03.47a.75.75 0 0 0-1.06 0z"></path></svg>
                </button>
            </div>
        </div>
    )
}
export default withAuth(SpotifyNavbar);
