import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import Color, { Palette } from "color-thief-react";
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';






import { useParams } from 'react-router-dom';
import { useToken } from './components-spotify/TokenContext';
import PlaylistSpotify from './components-spotify/playlistSpotify';
import SpotifyNavbar from './spotifyNavbar';
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';


const SpotifyGenre = () => {
    const { genreId } = useParams()
    const { tokenSpotify, refreshAccessTokenSpotify } = useToken(); // Access token and refreshAccessToken from context
    const { navColor, setNavColor } = useSpotifyStreamLink();

    const [dataGenre, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const apiURL = `https://api.spotify.com/v1/views/${genreId}`
    const apiURL = `http://65.109.181.122:3000/proxyParams?url=https://api-partner.spotify.com/pathfinder/v1/query?operationName=browsePage&variables=%7B%22pagePagination%22%3A%7B%22offset%22%3A0%2C%22limit%22%3A10%7D%2C%22sectionPagination%22%3A%7B%22offset%22%3A0%2C%22limit%22%3A10%7D%2C%22uri%22%3A%22spotify%3Apage%3A${genreId}%22%7D&extensions=%7B%22persistedQuery%22%3A%7B%22version%22%3A1%2C%22sha256Hash%22%3A%22ac9839f932d7ddcc8e6daa384564db70a68542fafed2705360a77ba6e6866823%22%7D%7D`
    const navigate = useNavigate()



    const spotifyApi = Axios.create({
        baseURL: apiURL,
        headers: {
            'Authorization': `Bearer ${tokenSpotify}`,
        },
    });

    spotifyApi.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response && error.response.status === 401) {
                // Token is expired, attempt to refresh it
                const newToken = await refreshAccessTokenSpotify();
                if (newToken) {
                    // Retry the original request with the new token
                    error.config.headers['Authorization'] = `Bearer ${newToken}`;
                    return Axios.request(error.config);
                }
            }
            return Promise.reject(error);
        }
    );

    const fetchData = async () => {
        try {
            const response = await spotifyApi.get('');
            if (response.status === 200) {
                const result = response.data;
                setData(result)
                console.log(result);
            } else {
                console.error('Failed to fetch data from the Spotify API');
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [tokenSpotify]);
    const playlistPage = (playlistId) => {
        const extractedValue = playlistId.split(':')[2];
        navigate(`/spotify/playlist/${extractedValue}`);
    };


    const genreSection = dataGenre.data ? dataGenre.data.browse : null
    setNavColor(genreSection && genreSection.header ? genreSection.header.color.hex : null)

    return (
        <div className='rounded-lg md:mr-3 mt-3 overflow-scroll no-scrollbar '>
            <div className='flex'>
                <div className='w-full rounded-lg' style={{
                    background: `linear-gradient(to bottom, ${genreSection && genreSection.header ? genreSection.header.color.hex : null} 0%, #121212 75vh, #121212 100%)`
                }}>
                    <div className='fixed z-10 h-16 rounded-lg' style={{
                        background: `${genreSection && genreSection.header ? genreSection.header.color.hex : null}`,
                    }} >
                        <SpotifyNavbar />
                    </div>
                    <div className='w-[92%] h-16'></div>

                    <div className='md:flex gap-x-5 items-center p-5'>
                        <div className='truncate'>
                            <h1 className='font-black md:text-7xl text-2xl truncate'>{genreSection && genreSection.header ? genreSection.header.title.transformedLabel : null}</h1>
                        </div>
                    </div>
                    <div className="rounded-lg" style={{
                        // background: `linear-gradient(to bottom, ${color}, #121212)`,
                    }} >
                        <div className='p-5' style={{ backgroundColor: 'rgba(18, 18, 18, 0.15)' }}>
                            {genreSection && genreSection.sections.items.map((item, index) => (

                                <div className="mx-10 my-8">
                                    <h2 className="text-2xl font-bold my-4" >{item.data.title ? item.data.title.transformedLabel : null}</h2>
                                    <div className="relative">
                                        <Swiper
                                            navigation={true} modules={[Navigation]}
                                            spaceBetween={50}
                                            slidesPerView={5}
                                            slidesPerGroup={5}
                                            speed={700}
                                            breakpoints={{
                                                240: {
                                                    slidesPerView: 2,
                                                    spaceBetween: 20,
                                                    slidesPerGroup: 2,
                                                },
                                                768: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 40,
                                                    slidesPerGroup: 4,

                                                },
                                                1024: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 50,
                                                    slidesPerGroup: 5,

                                                },
                                            }}
                                        >
                                            {item.sectionItems.items.map((playlist, index) => (

                                                <SwiperSlide className="bg-neutral-900 p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer" key={index} onClick={() => playlistPage(playlist.content.data.uri)}>
                                                    <div className="relative  ">
                                                        <div className="absolute bottom-0 right-3 cursor-pointer opacity-0 image_spotify transition-all ease-in-out duration-300" >
                                                            <div className={`bg-green-500 hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 p-3 rounded-full cursor-pointer shadow-xl `} >
                                                                <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto transition-all duration-500`}>
                                                                    <path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className='aspect-square'>
                                                            <LazyLoadImage
                                                                src={playlist.content.data.images && playlist.content.data.images.items[0].sources[0].url}
                                                                alt={playlist.title}
                                                                effect="blur"
                                                                className='rounded-xl inline'
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-5">
                                                        <p className=" truncate text-white font-bold">{playlist.content.data.name}</p>
                                                        <p className=" truncate text-gray-400 text-xs">{playlist.content.data.description}</p>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            ))

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default SpotifyGenre;