import React, { createContext, useContext, useState } from 'react';

const IdplaylistContext = createContext();
  
export function useIdplaylistContext() {
  return useContext(IdplaylistContext);
}

export function IdplaylistProvider({ children }) {
  const [selectedIdplaylist, setSelectedIdplaylist] = useState(null);

  const value = {
    selectedIdplaylist,
    setSelectedIdplaylist,
  };

  return (
    <IdplaylistContext.Provider value={value}>
      {children}
    </IdplaylistContext.Provider>
  );
}
