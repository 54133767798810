import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import AlbumSpotify from './components-spotify/albumSpotify';
import { useToken } from './components-spotify/TokenContext';
import PlaylistSpotify from './components-spotify/playlistSpotify';
import withAuth from './auth/withAuth';
import SpotifyNavbar from './spotifyNavbar';
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';
import Skeleton from '@mui/material/Skeleton';


const SpotifyHome = () => {
  const [data, setData] = useState(null);
  const [dataPlaylist, setDataPlaylist] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token, refreshAccessToken } = useToken(); // Access token and refreshAccessToken from context
  const { width, setWidth } = useSpotifyStreamLink();
  const { navColor, setNavColor } = useSpotifyStreamLink();



  const apiURL = 'http://65.109.181.122:3000/proxySpotify?url=https://api.spotify.com/v1/browse/new-releases';
  const playlistApiURL = 'http://65.109.181.122:3000/proxySpotify?url=https://api.spotify.com/v1/users/spotify/playlists';

  // Create an Axios instance for Spotify API requests
  const albumApi = Axios.create({
    baseURL: apiURL,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  const playlistApi = Axios.create({
    baseURL: playlistApiURL,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  // Add a response interceptor to refresh token if it's expired
  albumApi.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        // Token is expired, attempt to refresh it
        const newToken = await refreshAccessToken();
        if (newToken) {
          // Retry the original request with the new token
          error.config.headers['Authorization'] = `Bearer ${newToken}`;
          return Axios.request(error.config);
        }
      }
      return Promise.reject(error);
    }
  );

  const fetchData = async () => {
    try {
      const response = await albumApi.get('');
      if (response.status === 200) {
        const result = response.data;
        setData(result);
        console.log(result);
      } else {
        console.error('Failed to fetch data from the Spotify API');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    } finally {
      setNavColor("#212121")
      setLoading(false)
    }
  };


  useEffect(() => {
    fetchData();
  }, [token]);

  const fetchPlaylistData = async () => {
    try {
      const response = await playlistApi.get('');
      if (response.status === 200) {
        const result = response.data;
        setDataPlaylist(result);
        console.log(result);
      } else {
        console.error('Failed to fetch data from the Spotify API');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    } finally {
    }
  };
  useEffect(() => {
    fetchPlaylistData();
  }, [data]);

  const albumsSection = data ? data.albums : null;
  const playlistsSection = dataPlaylist ? dataPlaylist : null;

  return (
    <div className="rounded-lg mt-3 py-16 md:mr-2 relative overflow-scroll bg-gradient-to-b from-[#212121] to-[#121212] no-scrollbar">
      <h1 className='ml-10 mt-10 mb-5 font-bold text-2xl'>Spotify Playlists</h1>
      <PlaylistSpotify playlistsSection={playlistsSection} loading={loading} />
      <h1 className='ml-10 mt-10 mb-5 font-bold text-2xl'>New Albums & Singles</h1>
      <AlbumSpotify albumsSection={albumsSection} loading={loading} />
    </div>
  );
};

export default SpotifyHome;
