import React, { useEffect, useRef, useState } from 'react';
import { usePlaylistContext } from '../context/PlaylistContext';
import { useIdpContext } from '../context/IdpContext';
import { useIdContext } from '../context/IdContext';
import { useMusicContext } from '../context/MusicContext';
import { usePlayContext } from '../context/PlayContext';
import Slider from 'rc-slider';
import Hls from 'hls.js';



function ItemPlaylist() {
    const { showPlaylist, setShowPlaylist } = usePlaylistContext();
    const { playlistItem, setPlaylistItem } = usePlaylistContext();
    const { setSelectedIdp } = useIdpContext();
    const { setSelectedId } = useIdContext();
    const { image, setImage } = useMusicContext();
    const { song, setSong } = useMusicContext();
    const { artist, setArtist } = useMusicContext();
    const { bg, setBg } = useMusicContext();
    const { play, setPlay } = usePlayContext();
    const { idHls, setIdHls } = usePlayContext();
    const { mp3Ref, setMp3Ref } = usePlayContext();
    const { VideoRef } = usePlayContext();
    const { currentTime, setCurrentTime } = usePlayContext();
    const { currentIndex, setCurrentIndex } = useMusicContext();
    const { selectedPlaylist, setSelectedPlaylist } = usePlaylistContext();
    const hlsInstance = useRef(null);
    const next = playlistItem ? playlistItem[currentIndex] : null; // Check if playlist is not null
    const prev = playlistItem ? playlistItem[currentIndex - 1] : null;





    const handlePlayClick = (playlist, index) => {
        setSelectedIdp(null);
        setIdHls(playlist.id);
        setImage(playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
        setSong(playlist.song ? playlist.song : playlist.title);
        setArtist(playlist.artist ? playlist.artist : playlist.podcast_artist);
        setBg(playlist.bg_colors)
        setSelectedPlaylist(playlist);
        setCurrentIndex(index)
        setPlay(true);
        if (mp3Ref && idHls === playlist.id) {
            mp3Ref.play();
        }
    };

    const handlePlay = () => {
        mp3Ref.play();
        setPlay(true);
    };


    const handlePause = (playlist) => {
        mp3Ref.pause();
        setPlay(false);
    };
    const IndexNext = () => {
        // Increment the currentIndex to play the next item
        if (currentIndex < playlistItem.length - 1) {
            hlsInstance.current = new Hls();
            if (hlsInstance.current) {
                let hlsNext = next.hq_hls ? next.hq_hls : next.hls
                if (hlsNext.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    hlsNext = hlsNext.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');  
                }else if (hlsNext.startsWith('https://host2.mediacon-rj.app')) {
                    hlsNext = hlsNext.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');  
                }
                hlsInstance.current.loadSource(hlsNext);
                hlsInstance.current.attachMedia(mp3Ref);
                if (next.podcast_artist) {
                    setSelectedPlaylist(next);
                    setSelectedIdp(null);
                    setImage(next.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                    setSong(next.title);
                    setArtist(next.podcast_artist);
                    setIdHls(next.id);
                    setBg(next.bg_colors);
                } else {
                    setSelectedPlaylist(next);
                    setImage(next.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                    setSong(next.song);
                    setArtist(next.artist);
                    setSelectedId(null);
                    setIdHls(next.id);
                    setBg(next.bg_colors);
                }
            }
            setCurrentIndex(currentIndex + 1);



        } else {
            // If at the end, loop back to the beginning (assuming a circular playlist)
            setCurrentIndex(0);
        }
    };
    navigator.mediaSession.metadata = new MediaMetadata({
        title: song,
        artist: artist,
        artwork: [
            {
                src: selectedPlaylist && selectedPlaylist.thumbnail
                    ? selectedPlaylist.thumbnail.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com')
                    : selectedPlaylist && selectedPlaylist.photo,
                type: 'image/jpeg'
            },
            // You can provide multiple artwork sizes and types for better compatibility
        ],
    });
   

    const IndexPrev = () => {
        // Increment the currentIndex to play the prev item
        if (currentIndex < playlistItem.length - 1) {
            hlsInstance.current = new Hls();
            if (hlsInstance.current) {
                let hlsPrev = prev.hq_hls ? prev.hq_hls : prev.hls
                if (hlsPrev.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    hlsPrev = hlsPrev.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');  
                }else if (hlsPrev.startsWith('https://host2.mediacon-rj.app')) {
                    hlsPrev = hlsPrev.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');  
                }
                hlsInstance.current.loadSource(hlsPrev);
                hlsInstance.current.attachMedia(mp3Ref);
                if (prev.podcast_artist) {
                    setSelectedPlaylist(prev);
                    setImage(prev.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                    setSong(prev.title);
                    setArtist(prev.podcast_artist);
                    setSelectedIdp(null);
                    setIdHls(prev.id);
                    setBg(prev.bg_colors);

                } else {
                    setSelectedPlaylist(prev);
                    setImage(prev.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                    setSong(prev.song);
                    setArtist(prev.artist);
                    setSelectedId(null);
                    setBg(prev.bg_colors);
                }
            }
            setCurrentIndex(currentIndex - 1);



        } else {
            // If at the end, loop back to the beginning (assuming a circular playlist)
            setCurrentIndex(0);
        }
    };



    const handleSeek = (seekTime) => {
        if (mp3Ref) {
            mp3Ref.currentTime = seekTime;
            setCurrentTime(seekTime); // Update the current time state if needed
        }
    };
    const formatTime = (timeInSeconds) => {
        if (!isNaN(timeInSeconds)) {
            const minutes = Math.floor(timeInSeconds / 60);
            const seconds = Math.floor(timeInSeconds % 60);
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        } else {
            // Handle the case when timeInSeconds is not a valid number
            return '00:00';
        }
    };

    const handleDownloadClick = () => {
        // Create an invisible anchor element
        const link = document.createElement('a');
        link.href = selectedPlaylist.link;
        if (link.href.startsWith('https://host1.mediacon-rj.app')) {
            // Replace the starting part of the URL
            link.href = link.href.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
        } else if (link.href.startsWith('https://host2.mediacon-rj.app')) {
            link.href = link.href.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
        }

        link.target = '_blank'; // Open the link in a new tab
        link.click();
    };

    const secondsNext = () => {
        if (mp3Ref) {
            mp3Ref.currentTime += 15
        }
    }
    const secondsPrev = () => {
        if (mp3Ref) {
            mp3Ref.currentTime -= 15
        }
    }
    const handleShowPlaylist = () => {
        if (showPlaylist) {
            setShowPlaylist(false);
        } else {
            setShowPlaylist(true);
        }
    };





    return (
        <div className={`fixed z-40 flex justify-end bottom-0 w-full overflow-scroll transition-all ease-in-out duration-700  ${!showPlaylist ? 'opacity-0 h-0' : 'opacity-100 h-full'}`} style={{ background: `linear-gradient(to bottom, ${bg ? bg[0] : 'black'}, ${bg ? bg[1] : 'white'})` }}>
            <div className='md:flex mx-4 md:mx-0 md:w-9/12'>
                <div className="md:fixed left-0 md:w-3/12 ">
                    <div className=' pt-4 cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 mx-auto" onClick={handleShowPlaylist}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>
                    <div className="flex flex-wrap justify-center md:ml-8">
                        <img className="rounded-lg md:mt-16 mt-4 md:mb-9 mb-4" src={image}></img>
                        <div className=" text-center">
                            <p className="truncate font-semibold text-sm">{song}</p>
                            <p className="truncate text-gray-400 text-xs">{artist}</p>
                        </div>
                    </div>
                    <div className="md:hidden mx-6">
                        <div className=" flex items-center md:my-6 my-2 flex-wrap">
                            <div className=" flex justify-center w-full md:mb-5 mb-3 gap-x-5  items-center">
                                <button onClick={IndexPrev} className=" disabled:text-gray-400" disabled={currentIndex === 0}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z" />
                                </svg>
                                </button>
                                <button onClick={secondsPrev} className=" disabled:text-gray-400" >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                    </svg>
                                </button>
                                <button onClick={play === false ? handlePlay : handlePause}>{play === false
                                    ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                    </svg>

                                    : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                                    </svg>

                                }
                                </button>
                                <button onClick={secondsNext}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
                                    </svg>
                                </button>
                                <button onClick={IndexNext}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z" />
                                </svg>
                                </button>

                            </div>
                            <div className="flex w-full flex-wrap items-center gap-x-3 ">


                                <Slider
                                    min={0}
                                    max={mp3Ref && !isNaN(mp3Ref.duration) ? mp3Ref.duration.toString() : '0'}
                                    value={currentTime}
                                    onChange={handleSeek} // Pass the function directly
                                    keyboard="false"
                                    className="custom-slider w-full"
                                />
                                <div className='flex justify-between w-full'>
                                    <p className="text-xs text-white">
                                        {formatTime(currentTime)}
                                    </p>
                                    <p className="text-xs text-white">
                                        {formatTime(mp3Ref ? mp3Ref.duration : 0)}
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-wrap justify-center items-center w-full'>
                                <button onClick={handleDownloadClick}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                                </button>
                                <p className='w-full text-center text-[8px]'>
                                    Download
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-16 w-full">
                    {playlistItem ? playlistItem.map((item, index) => (
                        <div key={item.id} className="flex bg-[#181818] rounded-lg my-4 md:mx-8 p-2 mx-auto">
                            <div className="absolute h-24 " >
                                <div className="absolute top-3 left-3 cursor-pointer" >
                                    <div className={`bg-black bg-opacity-40 p-2 rounded-full cursor-pointer ${idHls === item.id ? 'hidden' : ''}`} onClick={() => handlePlayClick(item, index)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6  ${idHls === item.id ? 'hidden' : ''}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                        </svg>
                                    </div>
                                    <div className={`bg-black bg-opacity-40 p-2 rounded-full cursor-pointer ${play === true && idHls === item.id ? '' : 'hidden'}`} onClick={play === false ? () => handlePlayClick(item, index) : () => handlePause(item)} >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${play === true && idHls === item.id ? '' : 'hidden'}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                                        </svg>
                                    </div>
                                    <div className={`bg-black bg-opacity-40 p-2 rounded-full cursor-pointer ${idHls === item.id && play === false ? '' : 'hidden'}`} onClick={play === false ? () => handlePlayClick(item, index) : () => handlePause(item)}  >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${idHls === item.id && play === false ? '' : 'hidden'}`}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <img className="rounded-lg w-16" src={item.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com')}></img>
                            </div>
                            <div className="md:w-full w-60 mt-3 ml-4">
                                <p className="truncate ">{item.song ? item.song : item.title}</p>
                                <p className="truncate  ">{item.artist ? item.artist : item.podcast_artist}</p>
                            </div>
                        </div>
                    ))
                        : <div className='w-full h-36' ></div>
                    }
                    <div className='w-full h-36' ></div>
                </div>
            </div>
        </div>
    )
}
export default ItemPlaylist;