import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import Color, { Palette } from "color-thief-react";
import Lottie from 'lottie-react';
import likeAnimate from './lottie/like.json'
import dislikeAnimate from './lottie/dislike.json'
import { LazyLoadImage } from 'react-lazy-load-image-component';







import { useParams } from 'react-router-dom';
import { useToken } from './components-spotify/TokenContext';
import AudioPlayer from './spotifyPlayer';
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';
import SpotifyNavbar from './spotifyNavbar';
import SpotifyLike from './spotifyLike';
import SpotifyTrackLike from './spotifyTrackLike';

const SpotifyTracklistPlaylist = () => {
    const { playlistId } = useParams();
    const [playlistData, setPlaylistData] = useState([]);
    const [color, setColor] = useState([]);
    const { token, refreshAccessToken } = useToken(); // Access token and refreshAccessToken from context
    const [loading, setLoading] = useState(true);
    const [isDataLoaded, setDataLoaded] = useState(false); // New state for data loading
    const { streamLink, setStreamLink } = useSpotifyStreamLink();
    const { currentIndex, setCurrentIndex } = useSpotifyStreamLink(); // Provide a default value
    const { selectedTrack, setselectedTrack } = useSpotifyStreamLink(); // Provide a default value
    const { selectedPlaylist, setSelectedPlaylist } = useSpotifyStreamLink(); // Provide a default value
    const { isPlaying, setIsPlaying } = useSpotifyStreamLink(); // Store play/pause state
    const { navColor, setNavColor } = useSpotifyStreamLink();
    const { isClicked, setIsClicked } = useSpotifyStreamLink();

    const apiURL = `http://65.109.181.122:3000/proxySpotify?url=https://api.spotify.com/v1/playlists/${playlistId}`;
    const [nextTracksUrl, setNextTracksUrl] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [like, setLike] = useState(false);
    const [trackLike, setTrackLike] = useState(null);
    const scrollContainerRef = useRef(null);
    const { userId, setSetUserId } = useSpotifyStreamLink();







    useEffect(() => {
        setLike(false)
        setIsClicked(false)
        setTrackLike(null)
        setPlaylistData([])
        fetchData();
        fetchDataUser()
    }, [playlistId, token]);



    const spotifyApi = Axios.create({
        baseURL: apiURL,
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });

    spotifyApi.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response && error.response.status === 401) {
                // Token is expired, attempt to refresh it
                const newToken = await refreshAccessToken();
                if (newToken) {
                    // Retry the original request with the new token
                    error.config.headers['Authorization'] = `Bearer ${newToken}`;
                    return Axios.request(error.config);
                }
            }
            return Promise.reject(error);
        }
    );

    const fetchData = async () => {
        try {
            const response = await spotifyApi.get('');
            if (response.status === 200) {
                const result = response.data;
                setPlaylistData(result); // Set the retrieved data to the state
                // setSelectedPlaylist(result.tracks.items)
                if (result.owner.id !== undefined) {
                    const userApi = Axios.create({
                        baseURL: `http://65.109.181.122:3000/proxySpotify?url=https://api.spotify.com/v1/users/${result.owner.id}`,
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    try {
                        const response2 = await userApi.get('');
                        if (response2.status === 200) {
                            const result = response2.data;
                            setUserProfile(result.images[0].url); // Use optional chaining for images[0]
                            console.log(result);
                        } else {
                            console.error('Failed to fetch data from the Spotify API');
                        }
                    } catch (error) {
                        console.error('Error while fetching data:', error);
                    }
                } else {
                    console.error('playlistData.owner.id is undefined');
                }
                console.log(result);
                if (result.tracks.next) {
                    setNextTracksUrl(result.tracks.next)
                }
                console.log(result.tracks.next);


                setDataLoaded(true); // Mark data as loaded
                // Call fetchUserData after data is loaded
            } else {
                console.error('Failed to fetch data from the Spotify API');
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
    };



    // const fetchUserData = async () => {
    //     if (playlistData.owner.id !== undefined) {
    //         const userApi = Axios.create({
    //             baseURL: `https://api.spotify.com/v1/users/${playlistData.owner.id}`,
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //             },
    //         });

    //         try {
    //             const response = await userApi.get('');
    //             if (response.status === 200) {
    //                 const result = response.data;
    //                 setUserProfile(result.images[0].url); // Use optional chaining for images[0]
    //                 console.log(result);
    //             } else {
    //                 console.error('Failed to fetch data from the Spotify API');
    //             }
    //         } catch (error) {
    //             console.error('Error while fetching data:', error);
    //         }
    //     } else {
    //         console.error('playlistData.owner.id is undefined');
    //     }
    // };
    const fetchDataUser = async () => {
        try {
            // Perform the initial data fetch
            const customHeaders = {
                Authorization: `${localStorage.getItem('authToken')}`
            };
            const response = await fetch(`http://65.109.181.122:3000/show-saved-playlists/${userId}`, {
                headers: customHeaders,
            });
            const data = await response.json();

            // Check if the current playlist is saved
            const foundItem = data.items.find(item => item.id === playlistId);
            setLike(!!foundItem);  // Set like to true if the playlist is found, otherwise false
        } catch (error) {
            console.error('Error fetching saved playlists:', error);
        }
    };


    const fetchDataTracksUser = async () => {
        try {
            // Perform the initial data fetch
            const customHeaders = {
                Authorization: `${localStorage.getItem('authToken')}`
            };
            const response = await fetch(`http://65.109.181.122:3000/show-saved-tracks/${userId}`, {
                // headers: customHeaders,
            });
            const data = await response.json();

            const updatedItems = playlistData.tracks.items.map(item => {
                // Check if the current item's track id is present in the saved tracks data
                const isTrackLiked = data.items.some(savedItem => savedItem.track.id === item.track.id);

                // Set the trackLike property based on the condition
                return {
                    ...item,
                    trackLike: isTrackLiked,
                };
            });

            // Update the state after processing all items
            setTrackLike(updatedItems);
            console.log(trackLike)
        } catch (error) {
            console.error('Error fetching saved playlists:', error);
        } finally {
            setLoading(false);
        }
    };












    const formatTime = (timeInSeconds) => {
        if (!isNaN(timeInSeconds)) {
            const totalSeconds = Math.floor(timeInSeconds / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        } else {
            return '00:00';
        }
    };
    const handlePlayPause = () => {
        if (selectedTrack) {
            setIsPlaying(!isPlaying); // Toggle play/pause state
        }
    };

    const imgSrc = playlistData.images && playlistData.images[0] ? playlistData.images[0].url : "";


    const playAlbum = async (albumId, index) => {
        setselectedTrack(albumId);
        setSelectedPlaylist(playlistData.tracks.items)
        setCurrentIndex(index + 1);
        setIsPlaying(false)
        try {
            const response = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${albumId.track.id}`);
            if (response.status === 200) {
                const Link = response.data.link;
                setStreamLink(Link);
            } else {
                console.error('Failed to fetch stream link from the external API');
            }
        } catch (error) {
            console.error('Error while fetching the stream link:', error);
        } finally {
            setIsPlaying(true);
            if (selectedPlaylist) {
                const responseNext = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${selectedPlaylist[currentIndex + 1].track.id}`);
            }

        }
    };
    const fetchNextTracks = async () => {
        try {
            if (nextTracksUrl) {
                const response = await Axios.get(nextTracksUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (response.status === 200) {
                    const newTracks = response.data.items;
                    console.log(newTracks);
                    setPlaylistData((prevData) => {
                        return {
                            ...prevData,
                            tracks: {
                                ...prevData.tracks,
                                items: [...prevData.tracks.items, ...newTracks],
                            },
                        };
                    });
                    console.log(playlistData);
                    // Update the nextTracksUrl for future requests
                    setNextTracksUrl(response.data.next);
                    // setSelectedPlaylist((prevItems) => [...prevItems, ...newTracks]);

                } else {
                    console.error('Failed to fetch more tracks from the Spotify API');
                }
            }
        } catch (error) {
            console.error('Error while fetching more tracks:', error);
        }
    };
    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const scrolledToBottom = container.scrollHeight - container.scrollTop <= container.clientHeight - 100;

            if (scrolledToBottom) {
                // User has scrolled to the bottom, fetch more tracks
                fetchNextTracks();
            }
        }
    };
    useEffect(() => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            container.addEventListener('scroll', handleScroll);

            // Clean up the event listener when the component unmounts
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollContainerRef]);

    useEffect(() => {
        fetchDataTracksUser()
    }, [playlistData]);






    // const { data, loading, error } = useColor(IMAGE_URL, 'hex');
    return (

        <div className='rounded-lg md:mr-2 mt-3 overflow-scroll no-scrollbar' ref={scrollContainerRef}>
            {isDataLoaded && !loading && playlistData.name ? (
                <div className="flex justify-end rounded-lg flex-wrap">
                    <Palette src={imgSrc} crossOrigin="anonymous" format="hex" colorCount={4}>
                        {({ data, loading }) => {
                            setColor(data ? data[0] : null)
                            setNavColor(data ? data[0] : null);
                            return (
                                null
                            );
                        }}
                    </Palette>
                    <div className="w-full rounded-lg " style={{
                        background: `linear-gradient(to bottom, ${color} 0%, #121212 75vh, #121212 100%)`,
                    }} >

                        <div className='w-[92%] h-16'></div>

                        <div className='md:flex gap-x-5 items-center p-5'>
                            <img src={imgSrc} className='md:w-56 md:h-56 w-40 h-40 md:mx-0 mx-auto shadow-2xl' alt="playlist Cover"></img>
                            <div className='truncate'>
                                <h1 className='font-black md:text-7xl text-2xl truncate'>{playlistData.name}</h1>
                                <p className='text-sm text-neutral-300 font-semibold truncate'>{playlistData.description}</p>
                                <div className='flex gap-x-2 items-center mt-10'>
                                    <img className={`w-7 h-7 rounded-full ${userProfile ? '' : 'hidden'}`} src={userProfile}></img>
                                    <p>{playlistData.owner.display_name}</p>
                                    <div className='w-1 h-1 bg-white rounded-full'></div>
                                    <p>{playlistData.followers.total} likes</p>
                                    <div className='w-1 h-1 bg-white rounded-full'></div>
                                    <p>{playlistData.tracks.total} songs</p>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-lg" style={{
                            // background: `linear-gradient(to bottom, ${color}, #121212)`,
                        }} >
                            <div className='p-5' style={{ backgroundColor: 'rgba(18, 18, 18, 0.15)' }}>
                                {trackLike && (
                                    <div className=''>
                                        <div className='flex items-center gap-x-8'>
                                            {playlistData.tracks.items === selectedPlaylist && selectedTrack
                                                ? <button className={`bg-green-500 relative hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 w-16 h-16 rounded-full cursor-pointer shadow-xl `} onClick={handlePlayPause}>

                                                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto top-4 left-4 absolute transition-all duration-500 ${!isPlaying ? 'rotate-90 opacity-0 -scale-50 w-0' : ''
                                                        }`} ><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>

                                                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto top-4 left-4 absolute transition-all duration-500 ${isPlaying ? '-rotate-90 opacity-0 -scale-50 w-0' : ''
                                                        }`}><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>
                                                </button>
                                                : <button className={`bg-green-500 relative hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 w-16 h-16 rounded-full cursor-pointer shadow-xl `} onClick={() => playAlbum(playlistData.tracks.items[0], 0)}>

                                                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto top-4 left-4 absolute transition-all duration-500 ${playlistData.tracks.items == selectedPlaylist ? 'hidden' : ''
                                                        }`}><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>
                                                </button>
                                            }
                                            <SpotifyLike playlistData={playlistData} like={like} />
                                        </div>

                                        <div className='border-b-[1px] flex justify-between items-center border-neutral-400 border-opacity-40 my-4'>
                                            <div className='flex gap-x-5 my-2'>
                                                <p className='ml-5 text-neutral-400'>#</p>
                                                <p className=' text-neutral-400'>Title</p>
                                            </div>
                                            <div className='md:inline hidden ml-48'>
                                                <p className=' text-neutral-400'>Album</p>
                                            </div>
                                            <div className='mr-6'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-neutral-400">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                        {trackLike.map((playlist, index) => (
                                            <div className='flex items-center justify-between py-1 rounded-md spotify-list' key={index} onClick={() => playAlbum(playlist, index)}>
                                                <div className='flex items-center '>
                                                    <p className='mx-5 text-neutral-400 w-4 number-s-list md:inline hidden'>{index + 1}</p>
                                                    <button className='px-5 py-4 hidden play-s-list' onClick={() => playAlbum(playlist, index)}>
                                                        {isPlaying && selectedTrack && playlist.track.id === selectedTrack.track.id ?
                                                            <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto"><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>
                                                            :
                                                            <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto"><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>
                                                        }
                                                    </button>

                                                    <div>
                                                        {isPlaying && selectedTrack && playlist.track.id === selectedTrack.track.id ?
                                                            <div className='w-10 flex justify-center' >
                                                                <img className='w-4 h-4 ' src='https://open.spotifycdn.com/cdn/images/equaliser-animated-green.f5eb96f2.gif' ></img>
                                                            </div>
                                                            : (playlist.track.album.images[0] ?
                                                                // <img className='w-10 h-10 md:rounded-none rounded-lg' src={playlist.track.album.images[0].url}></img>
                                                                <Palette src={playlist.track.album.images[0].url} crossOrigin="anonymous" format="hex" colorCount={4}>
                                                                    {({ data, loading }) => (
                                                                        <div className='aspect-square md:rounded-none rounded-lg' style={{ backgroundColor: data ? data[1] : null }}>
                                                                            <LazyLoadImage
                                                                                src={playlist.track.album.images[0].url}
                                                                                alt={playlist.track.name}
                                                                                effect="blur"
                                                                                className='w-10 h-10 md:rounded-none rounded-lg inline'
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Palette>
                                                                : null
                                                            )
                                                        }
                                                    </div>
                                                    <div className='ml-5 w-48 '>
                                                        {selectedTrack && playlist.track.id === selectedTrack.track.id
                                                            ? <p className='font-semibold truncate text-green-400'>{playlist.track.name}</p>
                                                            : <p className='font-semibold truncate'>{playlist.track.name}</p>
                                                        }

                                                        {playlist.track.artists && (
                                                            <p className='truncate'>
                                                                {playlist.track.artists.map((artist, index) => (
                                                                    <p className='inline mr-1 text-sm text-neutral-400 truncate' key={index} >{artist.name},</p>
                                                                ))}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='md:inline hidden'>
                                                    <p className='mr-5 text-neutral-400'>{playlist.track.album.name}</p>
                                                </div>
                                                <div className='w-32 flex justify-evenly items-center'>
                                                    <div className=''><SpotifyTrackLike trackData={playlist.track} like={playlist.trackLike} /></div>
                                                    <p className=' text-neutral-400'>
                                                        {formatTime(playlist.track.duration_ms)}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                        <button
                                            className="p-4 font-semibold cursor-pointer shadow-xl"
                                            onClick={fetchNextTracks}
                                        >
                                            Load More Tracks
                                        </button>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <p className='text-center'>Loading... </p> // Display a loading message while waiting for data and images
            )}
        </div>
    );
}

export default SpotifyTracklistPlaylist;
