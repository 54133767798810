import React, { useEffect , useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-web';
import tape from '../tape.json';
import bgSpotify from './spotifyBgRegister';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactSVG } from 'react-svg';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required('Username is required')
    .min(5, 'Username must be at least 6 characters')
    .matches(/^\S*$/, 'Username must not contain spaces'),
  password: Yup.string().required('Password is required').min(5),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  email: Yup.string().email('Invalid email'),
  fullname: Yup.string(),
});

const Register = () => {
  const navigate = useNavigate();
  const lastToken = localStorage.getItem('authToken');
  const [register, setRegister] = useState(false);
  const [showPassword, setShowPassword] =useState(false);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      email: '',
      fullname: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const endpoint = register ? 'register' : 'login';
        const response = await fetch(`http://65.109.181.122:3000/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          const { token, refreshToken } = await response.json();
          localStorage.setItem('authToken', token);
          localStorage.setItem('refreshToken', refreshToken);
          navigate('/');
        } else {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.error || 'Operation failed';
          toast.error(`${errorMessage}`);
        }
      } catch (error) {
        console.error('Error during registration:', error);
        toast.error(`${error}`);
      }
    },
  });

  useEffect(() => {
    // Initialize Lottie only once when the component mounts
    const animationContainer = document.getElementById('tap-container');
    if (lastToken) {
      navigate('/');
    }
    if (animationContainer && !animationContainer.hasChildNodes()) {
      Lottie.loadAnimation({
        container: animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: tape,
      });
    }
  }, []);

  const handleRegisterState = () => {
    setRegister(!register);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='fixed h-full rounded-lg w-full md:mx-3 right-0 mt-3 overflow-scroll'>
      <ToastContainer />
      <img src={bgSpotify} className='w-full -z-50 absolute blur-md'></img>

      <div className='w-96 fixed overflow-hidden p-[1px] mx-auto bg-neutral-900 bg-opacity-70 rounded-2xl left-0 right-0 top-10  transition-all duration-300 ease-in-out'>
        <div class="animate-spin-slow absolute -top-40 -bottom-40 left-10 right-10 bg-gradient-to-r from-transparent via-white/90 to-transparent visible"></div>
        <div class="relative h-[85vh] overflow-y-scroll overflow-x-hidden  rounded-[15px] flex justify-center flex-wrap bg-neutral-900 p-6 no-scrollbar">
          <div className='absolute mt-5'>
            <p className='font-black text-3xl w-full mx-auto'>Cassette!</p>
          </div>
          <div className=''>
            <div id="tap-container" className='w-72 -mt-5 rotate-90'>
            </div>
          </div>
          <form className='w-full' onSubmit={formik.handleSubmit}>
            {!register ? (
              <div className='w-full grid grid-cols-1 px-5  -mt-16 '>
                <input
                  type="text"
                  placeholder="Username"
                  className='text-sm rounded-xl block w-full mb-3 pl-10 p-4 z-10 bg-neutral-800'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  name='username'
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className='text-red-500 mb-5'>{formik.errors.username}</div>
                ) : null}
                <input
                  type={showPassword?'text':'password'}
                  placeholder="Password"
                  className='text-sm rounded-xl block w-full pl-10 p-4 z-10 mb-3 bg-neutral-800'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name='password'
                />
                <button className='relative z-50 -top-12 left-64' onClick={handleShowPassword}>
                  {!showPassword
                  ?<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                  :<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>
                
                  }
                </button>
                {formik.touched.password && formik.errors.password ? (
                  <div className='text-red-500 mb-5'>{formik.errors.password}</div>
                ) : null}
                <button
                  type='submit'
                  className='bg-white rounded-full py-4 px-14 text-black hover:scale-110 mb-4 text-sm font-semibold tracking-wider'
                >
                  LOG IN
                </button>
              </div>
            ) : (
              <div className='w-full grid grid-cols-1 px-5 gap-y-3 -mt-14 mb-0'>
                <input
                  type="text"
                  placeholder="Username"
                  className='text-sm rounded-xl block w-full pl-10 p-4 z-10 bg-neutral-800'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  name='username'
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className='text-red-500'>{formik.errors.username}</div>
                ) : null}
                <input
                  type="email"
                  placeholder="Email"
                  className='text-sm rounded-xl block w-full pl-10 p-4 z-10  bg-neutral-800'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name='email'
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='text-red-500'>{formik.errors.email}</div>
                ) : null}
                <input
                  type="text"
                  placeholder="Full Name"
                  className='text-sm rounded-xl block w-full pl-10 p-4 z-10  bg-neutral-800'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullname}
                  name='fullname'
                />
                {formik.touched.fullname && formik.errors.fullname ? (
                  <div className='text-red-500'>{formik.errors.fullname}</div>
                ) : null}
                <input
                  type={showPassword?'text':'password'}
                  placeholder="Password"
                  className='text-sm rounded-xl block w-full pl-10 p-4 z-10  bg-neutral-800'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name='password'
                />
                <button className='relative z-50 -top-12 left-64' onClick={handleShowPassword}>
                  {!showPassword
                  ?<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                  :<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="absolute w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                </svg>
                
                  }
                </button>
                {formik.touched.password && formik.errors.password ? (
                  <div className='text-red-500'>{formik.errors.password}</div>
                ) : null}
                <input
                  type="password"
                  placeholder="Retype Password"
                  className='text-sm rounded-xl block w-full pl-10 p-4 z-10  bg-neutral-800'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  name='confirmPassword'
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className='text-red-500'>{formik.errors.confirmPassword}</div>
                ) : null}
                <button
                  type='submit'
                  className='bg-white rounded-full py-4 px-14 text-black hover:scale-110 mb-4 text-sm font-semibold tracking-wider'
                >
                  Sign Up
                </button>
              </div>
            )}
          </form>
          <button className="text-neutral-400 my-4" onClick={handleRegisterState}>
            {!register ? "Don't have an account?" : "have an account?"}
            <span className='text-white underline'>{!register ? 'SIGNUP' : 'LOGIN'}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
