import { createContext, useContext, useState ,useRef } from 'react';

const SpotifyStreamLinkContext = createContext();

export function useSpotifyStreamLink() {
  return useContext(SpotifyStreamLinkContext);
}

export function SpotifyStreamLinkProvider({ children }) {
  const [streamLink, setStreamLink] = useState(); // Provide a default value
  const [currentIndex, setCurrentIndex] = useState(1); // Provide a default value
  const [selectedTrack, setselectedTrack] = useState(null); // Provide a default value
  const [selectedPlaylist, setSelectedPlaylist] = useState(null); // Provide a default value
  const [isPlaying, setIsPlaying] = useState(false); // Store play/pause state
  const [queue, setQueue] = useState(false); // Store play/pause state
  const [lyrics, setLyrics] = useState(false); // Store play/pause state
  const [spotify, setSpotify] = useState(true)
  const [userId, setSetUserId] = useState(true)
  const [userName, setUserName] = useState(null)
  const [isClicked, setIsClicked] = useState(false);
  const [library, setLibrary] = useState(false);
  const [width, setWidth] = useState(80);
  const [navColor, setNavColor] = useState("#212121");
  const [lyricsData, setLyricsData] = useState(null);
  const [currentTime, setCurrentTime] = useState(0); // Store current time
  const playerRef = useRef(null);
  const [userData, setUserData] = useState({});

  
  const setPlayerRef = (player) => {
    playerRef.current = player;
  };









  return (
    <SpotifyStreamLinkContext.Provider value={{userData, setUserData,playerRef,setPlayerRef,currentTime, setCurrentTime,lyricsData, setLyricsData,lyrics, setLyrics,userName, setUserName,navColor, setNavColor, width, setWidth,streamLink, setStreamLink,currentIndex,library, setLibrary, queue,spotify,userId,isClicked, setIsClicked, setSetUserId, setSpotify, setQueue, setCurrentIndex,selectedTrack, setselectedTrack,selectedPlaylist, setSelectedPlaylist , isPlaying, setIsPlaying }}>
      {children}
    </SpotifyStreamLinkContext.Provider>
  );
}
