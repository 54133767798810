// Mp3.js
import React, { useEffect, useState } from 'react';
import { usePlaylistContext } from '../context/PlaylistContext';
import { useIdpContext } from '../context/IdpContext';
import { useMusicContext } from '../context/MusicContext';
import Button from '../home_item/Button.js';
import { useNavigate, useParams } from 'react-router-dom'; // Updated imports





import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import required modules
import { Navigation } from 'swiper/modules';


function Effic() {
    const [data, setData] = useState(null);
    const { playlistItem, setPlaylistItem } = usePlaylistContext();
    const { selectedIdp } = useIdpContext();
    const { setCurrentIndex } = useMusicContext();
    const navigate = useNavigate(); // Use navigate for URL navigation




    useEffect(() => {
        // Check if selectedId is not null or undefined before making the API request
        fetch('http://65.109.181.122:3000/podcast')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((responseData) => {
                setData(responseData);
                setCurrentIndex(1);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <div className="">
        <div className="" >
          {data && (
            <div className="mx-10">
              <div className="relative">
                <Swiper
                  navigation={true} modules={[Navigation]}
                  spaceBetween={50}
                  slidesPerView={5}
                  slidesPerGroup={5}
                  speed={700}
                  breakpoints={{
                    240: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                      slidesPerGroup: 2,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                      slidesPerGroup: 4,
  
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 50,
                      slidesPerGroup: 5,
  
                    },
                  }}
                >
  
                    <SwiperSlide className="" key={data.id}>
                      <div className="hover:blocku relative">
                      <Button playlist={data} />
                      <img className="rounded-xl" src={data.photo} alt={data.title} />
                      </div>
                      <div className="mt-5">
                        <p className="truncate text-white font-bold">{data.title}</p>
                        <p className="truncate text-gray-400 text-xs cursor-pointer" onClick={() => navigate('/dj_effic/')}>{data.podcast_artist}</p>
                      </div>
                    </SwiperSlide>
                  
                </Swiper>
              </div>
            </div>
          )}
        </div >
      </div>
        // <div className='flex justify-end mt-28 mx-10 h-80' >
        //     {data ? (
        //         <>
        //             <div className="hover:blocku w-48 h-48 relative">
        //                 <Button playlist={data} />
        //                 <img className="rounded-xl" src={data.photo} alt={data.title} />
        //             </div>
        //             <div className="mt-5">
        //                 <p className=" truncate text-white font-bold">{data.title}</p>
        //                 <p className=" truncate text-gray-400 text-xs">{data.podcast_artist}</p>
        //             </div>
        //         </>
        //     ) : (
        //         <p>Loading podcast data...</p>
        //     )}
        // </div>
    );
}

export default Effic;
