import Reeact from 'react';
import HlsPlayer from './HlsPlayer';
import { usePlaylistContext } from '../context/PlaylistContext';
import VideoPlayer from './VideoPlayer';




function PlayerBar() {
    const { selectedPlaylist } = usePlaylistContext();
    
    if (!selectedPlaylist) {
        // Handle the case when selectedPlaylist is null
        return <div></div>;
    }


    // if (selectedPlaylist.type === 'video') {
    //     return (
    //         <nav className="fixed z-50 px-5 flex items-center justify-between bottom-0 border-t-[1px] border-[#282828] bg-[#181818] h-24 w-full">
    //             <VideoPlayer />
    //         </nav>
    //     );
    // } else {
        return (
            <nav className="fixed z-30 md:z-50 md:px-5  flex items-center justify-between bottom-0 border-t-[1px] border-[#282828] bg-[#181818] w-full">
                <HlsPlayer />
            </nav>
        );
    

}
export default PlayerBar;