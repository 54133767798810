import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import AlbumSpotify from './components-spotify/albumSpotify';
import PlaylistSpotify from './components-spotify/playlistSpotify';
import { useToken } from './components-spotify/TokenContext';
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';
import TrackSpotify from './components-spotify/trackSpotify';
import SpotifyNavbar from './spotifyNavbar';
import ArtistsSpotify from './components-spotify/artistSpotify';



const SpotifySearch = () => {




    const [data, setData] = useState([]);
    const [item, setItem] = useState([]);
    const [query, setQuery] = useState('');
    const { token, refreshAccessToken } = useToken(); // Access token and refreshAccessToken from context
    const { tokenSpotify, refreshAccessTokenSpotify } = useToken(); // Access token and refreshAccessToken from context
    const [loading, setLoading] = useState(true);
    const [isDataLoaded, setDataLoaded] = useState(false); // New state for data loading
    const {navColor, setNavColor } = useSpotifyStreamLink();


    const navigate = useNavigate(); // Initialize useNavigate
    const { query: urlQuery } = useParams();

    useEffect(() => {
        // Initially, you may want to check if the query is not empty and call fetchData
        if (urlQuery && urlQuery.trim().length > 1) {
            setQuery(urlQuery);
            console.log(query);
            fetchData();

        }
    }, [query, urlQuery,token]);


    const apiURL = `http://65.109.181.122:3000/proxyParams?url=https://api.spotify.com/v1/search?q=${query}&type=album%2Cplaylist%2Cartist%2Ctrack&limit=10`;
    const apiSpotifySearchItemURL = 'http://65.109.181.122:3000/proxyParams?url=https://api-partner.spotify.com/pathfinder/v1/query?operationName=browseAll&variables=%7B%22pagePagination%22%3A%7B%22offset%22%3A0%2C%22limit%22%3A10%7D%2C%22sectionPagination%22%3A%7B%22offset%22%3A0%2C%22limit%22%3A99%7D%7D&extensions=%7B%22persistedQuery%22%3A%7B%22version%22%3A1%2C%22sha256Hash%22%3A%22d7dafb80e90c0861545e78ce3e144e3f706bafb09ea31ed8eb12f05f9b3ff4fa%22%7D%7D';


    // Function to handle input change and update the URL
    const handleInputChange = (event) => {
        const newQuery = event.target.value;
        const encodedQuery = encodeURIComponent(newQuery); // Encode the query
        setQuery(newQuery);
        navigate(`/spotifySearch/${encodedQuery}`); // Use navigate to update the URL
    
        // Check if the query is not empty and then call the API
        if (newQuery.trim().length > 1) {
            fetchData();
        } else {
            setDataLoaded(false);
        }
    };
    const spotifyApi = Axios.create({
        baseURL: apiURL,
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    const spotifySearchItemApi = Axios.create({
        baseURL: apiSpotifySearchItemURL,
        headers: {
            'Authorization': `Bearer ${tokenSpotify}`,
        },
    });



    spotifyApi.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response && error.response.status === 401) {
                // Token is expired, attempt to refresh it
                const newToken = await refreshAccessToken();
                if (newToken) {
                    // Retry the original request with the new token
                    error.config.headers['Authorization'] = `Bearer ${newToken}`;
                    return Axios.request(error.config);
                }
            }
            return Promise.reject(error);
        }
    );
    const fetchData = async () => {
        try {
            const response = await spotifyApi.get('');
            if (response.status === 200) {
                const result = response.data;
                setData(result); // Set the retrieved data to the state
                console.log(result);
                // Mark data as loaded
            } else {
                console.error('Failed to fetch data from the Spotify API');
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            setLoading(false);
            setDataLoaded(true);
        }
    };




    spotifySearchItemApi.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response && error.response.status === 401) {
                // Token is expired, attempt to refresh it
                const newToken = await refreshAccessTokenSpotify();
                if (newToken) {
                    // Retry the original request with the new token
                    error.config.headers['Authorization'] = `Bearer ${newToken}`;
                    return Axios.request(error.config);
                }
            }
            return Promise.reject(error);
        }
    );
    const fetchSpotifyItems = async () => {
        try {
            const response = await spotifySearchItemApi.get('');
            if (response.status === 200) {
                const result = response.data;
                setItem(result); // Set the retrieved data to the state
                console.log(result);
            } else {
                console.error('Failed to fetch data from the Spotify API');
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            setLoading(false);
            setNavColor("#212121")
        }
    };


    useEffect(() => {
        fetchSpotifyItems();
    }, [tokenSpotify]);




    const genrePage = (genreId, index) => {
        if (index === 4) {
            const extractedValue = '0JQ5DAudkNjCgYMM0TZXDr';
            navigate(`/spotify/genre/${extractedValue}`);
        } else {
            const extractedValue = genreId.split(':')[2];
            navigate(`/spotify/genre/${extractedValue}`);
        }

    };




    const albumsSection = data ? data.albums : null;
    const playlistsSection = data ? data.playlists : null;
    const artistsSection = data ? data.artists : null;
    const tracksSection = data ? data.tracks : null;
    const searchItem = item.data ? item.data.browseStart.sections.items[0].sectionItems : null;
    return (
        <div className='rounded-lg md:mr-2 mt-3 overflow-scroll  bg-gradient-to-b from-[#212121] to-[#121212] no-scrollbar ' >
            <div className="flex justify-end mb-5">
                <div className="w-full">
                    <div className='items-center'>
                        <div className="fixed z-20 top-[20px] left-1/4 right-0 md:w-1/3 w-1/2 mx-auto">
                            <div className="absolute flex top-3 items-center pl-3 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </div>
                            <input
                                placeholder="Search Spotify..."
                                onChange={handleInputChange}
                                value={query}
                                type="text"
                                id="simple-search"
                                className="text-sm rounded-xl block w-full pl-10 p-3  bg-[#313131]"
                            />
                        </div>
                        <div className='w-[92%] h-16'></div>
                    </div>
                    {!isDataLoaded &&
                        <div className='flex flex-wrap justify-center gap-y-5 gap-x-5 my-7'>
                            {searchItem && searchItem.items.slice(4).map((dataItem, index) => (
                                <div className='w-44 h-44 rounded-xl relative overflow-hidden cursor-pointer' key={index} style={{
                                    backgroundColor: dataItem.content.data.data && dataItem.content.data.data.cardRepresentation
                                        ? dataItem.content.data.data.cardRepresentation.backgroundColor.hex
                                        : dataItem.content.data.backgroundColor.hex
                                }} onClick={() => { genrePage(dataItem.uri, index) }}>
                                    <p className='mt-4 ml-4 font-bold text-2xl truncate'>
                                        {dataItem.content.data.data && dataItem.content.data.data.cardRepresentation
                                            ? dataItem.content.data.data.cardRepresentation.title.transformedLabel
                                            : dataItem.content.data.title.transformedLabel}
                                    </p>
                                    <img src={dataItem.content.data.data && dataItem.content.data.data.cardRepresentation
                                        ? dataItem.content.data.data.cardRepresentation.artwork.sources[0].url
                                        : dataItem.content.data.artwork.sources[0].url} className='w-24 h-24 rotate-[25deg] absolute -bottom-2 -right-4'></img>
                                </div>
                            ))}
                        </div>
                    }
                    <div>
                        {isDataLoaded && tracksSection !== undefined ? (
                            <>
                                <TrackSpotify tracksSection={tracksSection} />
                                <h1 className='ml-10 mt-10 mb-5 font-bold text-3xl'>Artists</h1>
                                <ArtistsSpotify artistsSection={artistsSection} />
                                <h1 className='ml-10 mt-10 mb-5 font-bold text-3xl'>Albums</h1>
                                <AlbumSpotify albumsSection={albumsSection} />
                                <h1 className='ml-10 mt-10 mb-5 font-bold text-3xl'>Playlists</h1>
                                <PlaylistSpotify playlistsSection={playlistsSection} />
                                <div className='h-36'></div>


                            </>
                        ) : (
                            <p className='text-center'></p> // Display a loading message while waiting for data and images
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default SpotifySearch;
