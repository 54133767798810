import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSpotifyStreamLink } from '../components-spotify/spotifyStreamLinkContext';
import { useNavigate } from 'react-router-dom';


const UserAdmin = () => {
    const { userId, setSetUserId } = useSpotifyStreamLink();
    const { userData, setUserData } = useSpotifyStreamLink();
    const [editMode, setEditMode] = useState(false); // New state for edit mode
    const [expireDate, setExpireDate] = useState('');
    const [users, setUsers] = useState('');
    const lastToken = localStorage.getItem('authToken');
    const navigate = useNavigate();



    useEffect(() => {
        // Fetch user details and expiration date when the component mounts
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`http://65.109.181.122:3000/users`, {
                    headers: {
                        Authorization: `${localStorage.getItem('authToken')}`,
                    }
                });
                const result = response.data;
                setUsers(result);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [userId]);

    if (!userData.admin) {
        navigate("/spotify/profile")

    }


    return (
        <>
            <div className='rounded-lg mt-3 md:mr-2 relative overflow-scroll bg-gradient-to-b from-[#212121] to-[#121212] no-scrollbar'>
                    <table className='my-28 w-2/3 mx-auto text-center'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Username</th>
                                <th>Expire Date</th>
                                <th>Email</th>
                                <th>Full Name</th>
                                <th>Admin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.map((user) => (
                                <tr key={user.id} className=''>
                                    <td className='py-2'>{user.id}</td>
                                    <td>{user.username}</td>
                                    <td>{user.expire_date ? user.expire_date : "0 days"}</td>
                                    <td>{user.email ? user.email : "No Email"}</td>
                                    <td>{user.fullname ? user.fullname : "No Name"}</td>
                                    <td>{user.admin ? "Admin" : "No"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
            </div>
        </>
    );
};

export default UserAdmin;
