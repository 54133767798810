// MyContext.js
import { createContext, useContext, useState } from 'react';

const MusicContext = createContext();

export function useMusicContext() {
  return useContext(MusicContext);
}

export function MusicProvider({ children }) {
  const [image, setImage] = useState(null);
  const [song, setSong] = useState(null);
  const [artist, setArtist] = useState(null);
  const [bg, setBg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <MusicContext.Provider value={{ image, song, artist, currentIndex,bg, setBg, setCurrentIndex, setImage, setSong, setArtist }}>
      {children}
    </MusicContext.Provider>
  );
}
