import React, { useState } from 'react';
import Axios from 'axios';
import { TokenContext } from './TokenContext';


export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState("BQBzmF0H0y2gJPZfV7Lix9MAPo4ELez8w1OpXJbhN15n8P6bXi1XmpyUvzUBN6KHwfmMTnnGJBSu1ycbT5BiijFT-Papvb6bNwB7mv1CeQpJqpNHgSIojl0ZUzptyHrpbFyRzHDRjZH5ZohHsEo8hgEVzBcUIjkVGc0mnua56BBKk_vz_KEhpRWjUGa44Cjy7VDPBeU7w1Qu1Y8MxgxC0g"); // Initial access token
  const [tokenSpotify, setTokenSpotify] = useState("BQA1MFym1bQt4Dc9NNNXdn055azPSXIp13TbHtwHP90VkSQpZKmvd3LBgGuq_POMLAzIwNmAgIQY94C6sejWtBr5hFLs-UTbR7r0Ahklfb2qwgFOFSk"); // Initial access token
  const [tokenLyrics, setTokenLyrics] = useState("BQCji6syzNvhUrp7QR6k1aQ3wsmnnM3ygHaITnR-NWQeyv0exM5DLeJoyGMu2l67Em2LYNrdxKJpow36tWRfn4XQQ5Dun3Kh2HGX5ySw-ExKtvW24hGBHZDLCf9k769mQDxgp_I6qVYRimlAkUxPf1WmUiIP3-ruvHAUnLv1UPmE4Htemi5_ECScXuNY3Z-HuQOcbTkyQu2vGL8PuS-kJkZUV17QE4ImlcpV1PSXnQvUw973_r6pAyqNuCz7ic1PIeKHDceukWlA2AKKrEzdmhcoX8TU5rO8nSZCtUkSq1eMhJdLMT7uf1nBmMv_LDEueUDiFaQNhtcLLYD1mv_A"); // Initial access token

  const refreshAccessToken = async () => {
    try {
      // Make a request to your token refresh endpoint
      const refreshTokenResponse = await Axios.get('http://65.109.181.122:3000/refresh_token');
      const newAccessToken = refreshTokenResponse.data.access_token;
      setToken(newAccessToken);
    } catch (error) {
      console.error('Error while refreshing access token:', error);
    }
  };
  const refreshAccessTokenSpotify = async () => {
    try {
      // Make a request to your token refresh endpoint
      const refreshTokenResponse = await Axios.get('http://65.109.181.122:3000/get_access_token');
      const newAccessToken = refreshTokenResponse.data.accessToken;
      setTokenSpotify(newAccessToken);
    } catch (error) {
      console.error('Error while refreshing access token:', error);
    }
  };
  const refreshAccessTokenLyrics = async () => {
    try {
      // Make a request to your token refresh endpoint
      const refreshTokenResponse = await Axios.get('http://65.109.181.122:3000/get_access_token_lyrics', {
        headers: {
          'cookie': 'OptanonAlertBoxClosed=2023-09-12T05:22:33.898Z; eupubconsent-v2=CPx_5fWPx_5fWAcABBENDWCgAP_AAAAAAAYgJnNBjS-vRwvr-_77brswcY0G0dB9Y-EyhgfFBKABHboUMJ0FwGA5oFXCkKgKCAYgMUZBIEEgCDEUCVEgaoEFDiGAIEiUBLEIICNgEBAAAQBAAAlQFI6gAAAAgbgMCQAAgBKAAFIAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAXWwAAAHBAGMAAQACAAWAA8AC4AHwAZAA8AB8AEOAIwAjgBJACqAFgALYAYgA5gB6AEIAIYARYAjgBLACaAGGAMgAaoA4gBzgDqgHsAfAA_QCBgEHAIuARwAkwBVwC7QGEAYoA3AB6AEXgI9ASIAlYBQ4CmwFsAMWCQCQAKgAgABoAEQAJgATwBSgDvAXmGAUAABAAWABcAEQAKAAVAA8ACEAESAIwAjgBJACYAE8AKgAWwAxABzAEMAKQAVoAyABqgDiAHOASYAloBOgClgFfALsAbIA2wB1AD1AH7AReAkQBQ4CmwGLCAEgAAQAFgAXABEACgAFQAPAAhABEgCMAI4ASQAmABPAC2AGIAOYAhgBSACtAGQAOIAc4BJgCWgE6AKWAV8AuwBtgD1AH7AReAkQBQ4CmwGLCgCAAIgAVQBCAEMAJgAYgBHACkAGqAOIAjgBLQCvgF2AP2Aj0BIIwAcACIAFUAQgBDACYAGIARwApABxAEcAJaAV8AuwB-wEejgIoAAgAFgAPAAuAB-AGIAZAA5AB5AD4AQQAhwBGAEcAJoAVQAsABbAC6AF8AMQAZgA5gB6AEIAIYARYAjgBLACYAE1AKMApABYgCzAGEAMgAaoA4gBzgDqAHcAPYAfoBA4CDgIRARYBFwCOAEmAJsATgApcBVwFZALtAYQBigDcAHUAPIAegBEACKgEXgI9ASCAkQBKwChwFMAKbAWUAtgBggDFh0AoACoAIAAaABEACYAE8AUoA7wF5gMsIAGgAFgAxACCAEMAJoAVQAvgBiAD0AI4AUgAsQBqgDiAHOARwAnABS4CrgKyAXaAwgDFAHUAPQAj0BIIDBCEAIAJgA7yQCIAAQACwALgAxAByADwAI4ATYAqACqAF8AMQAbQBCACGAEcAKQAaoA7gCDgEcAJaAU2Aq4CsgF2AMUAdQBFQCLwEiAJWAWUAtgBiwDLCUAQAIgATAB3gLzKAOAABAALAAuAB-AGIAZAA5AB4AD8AIcARgBHACaAFKAKgAqgBYAC-AGIAMwAbQA5gCEAEMAIsAR0AowCkAFiAMIAaoA4gBzgDqAHcAP0Ag4BFgCOAEtAJsAUuAq4CswF1AXaAwgDFAGvANwAdQA7YB5AD0AIvAR6AkQBQ4CmAFNgLYAYIAxYpAJAAqACAAGgARAAmABPAFKAO8BeYA.f_gAAAAAAAAA; sp_adid=d1ea8afc-e1ae-46ac-87ef-c86ba02a8af6; sp_dc=AQB7DvDcUtQdlBL6u_vjxcyZuuXly_uRP38rRq__-sm7X25mN02NTgzfjBJ8y85l7lKD9gVaydgWEi7MGMi7bZKLrr29YtS3PRgUBOumvpYYwuEwMygC99ClopKFJwN-pYEp7iJqX0Vdj35jedRn0n9AlhPWfjqppXRbWZLCT_KrhD47LVzOPgVmXrr5THJcnhjM-448Es14ajSDcvQCF79hR74; ',
        },
      });
      const newAccessToken = refreshTokenResponse.data.accessToken;
      setTokenLyrics(newAccessToken);
    } catch (error) {
      console.error('Error while refreshing access token:', error);
    }
  };

  return (
    <TokenContext.Provider value={{ tokenLyrics, refreshAccessTokenLyrics,token, tokenSpotify, refreshAccessToken, refreshAccessTokenSpotify }}>
      {children}
    </TokenContext.Provider>
  );
};
