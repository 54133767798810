import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import Color, { Palette } from "color-thief-react";





import { Route, useLocation ,useNavigate } from 'react-router-dom';
import { useToken } from './components-spotify/TokenContext';
import AudioPlayer from './spotifyPlayer';
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';

const Queue = () => {
    const navigate = useNavigate();
    const [playlistData, setPlaylistData] = useState([]);
    const [color, setColor] = useState([]);
    const { token, refreshAccessToken } = useToken(); // Access token and refreshAccessToken from context
    const [loading, setLoading] = useState(true);
    const [isDataLoaded, setDataLoaded] = useState(false); // New state for data loading
    const { streamLink, setStreamLink } = useSpotifyStreamLink();
    const { currentIndex, setCurrentIndex } = useSpotifyStreamLink(); // Provide a default value
    const { selectedTrack, setselectedTrack } = useSpotifyStreamLink(); // Provide a default value
    const { selectedPlaylist, setSelectedPlaylist } = useSpotifyStreamLink(); // Provide a default value
    const { queue, setQueue } = useSpotifyStreamLink(); // Store play/pause state
    const { isPlaying, setIsPlaying } = useSpotifyStreamLink(); // Store play/pause state
    const [nextTracksUrl, setNextTracksUrl] = useState(null);
    const scrollContainerRef = useRef(null);






    const selectedPlaylistSubset = selectedPlaylist?selectedPlaylist.slice(currentIndex):null;
    const location = useLocation();

    useEffect(() => {
      if (location.pathname === '/spotify/queue') {
        // Set queue to true when the URL is /spotify/queue
        setQueue(true);
      } else {
        // Set queue to false when the URL changes to something else
        setQueue(false);
      }
    }, [location.pathname]);








    const formatTime = (timeInSeconds) => {
        if (!isNaN(timeInSeconds)) {
            const totalSeconds = Math.floor(timeInSeconds / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        } else {
            return '00:00';
        }
    };
    const handlePlayPause = () => {
        if (selectedTrack) {
            setIsPlaying(!isPlaying); // Toggle play/pause state
        }
    };

    const imgSrc = playlistData.images && playlistData.images[0] ? playlistData.images[0].url : "";


    const playAlbum = async (albumId) => {
        setselectedTrack(albumId);
        if(selectedPlaylist){
        var index = selectedPlaylist.findIndex(item => item.track.id === albumId.track.id);
        }else{
            var index= 1
        }

        setCurrentIndex(index + 1);
        try {
            const response = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${albumId.track.id}`);
            if (response.status === 200) {
                const Link = response.data.link;
                setStreamLink(Link);
            } else {
                console.error('Failed to fetch stream link from the external API');
            }
        } catch (error) {
            console.error('Error while fetching the stream link:', error);
        } finally {
            setIsPlaying(true);
        }
    };



    return (
        <div className='rounded-lg md:mr-2 right-0 mt-3 overflow-scroll no-scrollbar' ref={scrollContainerRef}>
            {selectedPlaylist ? (
                <div className="flex justify-end rounded-lg flex-wrap">
                    <div className="w-full rounded-lg bg-[#121212]" style={{
                    }} >
                        <div className='gap-x-5 items-center p-5'>
                            <p className='text-2xl font-bold mb-6'>Queue</p>
                            <div className='mb-6'>
                                <p className='font-semibold text-neutral-400'>Now Playing</p>
                                <div className='flex items-center justify-between py-1 rounded-md spotify-list'>
                                    <div className='flex items-center '>
                                        <p className='mx-5 text-neutral-400 w-4 number-s-list md:inline hidden'>1</p>
                                        <button className='px-5 py-4 hidden play-s-list' onClick={handlePlayPause}>
                                            {isPlaying && selectedTrack && selectedTrack.track.id === selectedTrack.track.id ?
                                                <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto"><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>
                                                :
                                                <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto"><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>
                                            }
                                        </button>
                                        <div>
                                            {isPlaying && selectedTrack && selectedTrack.track.id === selectedTrack.track.id ?
                                                <div className='w-10 flex justify-center' >
                                                    <img className='w-6 h-6 ' src='https://open.spotifycdn.com/cdn/images/equaliser-animated-green.f5eb96f2.gif' ></img>
                                                </div>
                                                :
                                                <img className='w-10 h-10 md:rounded-none rounded-lg' src={selectedTrack.track.album.images[0]?selectedTrack.track.album.images[0].url:null}></img>
                                            }
                                        </div>
                                        <div className='ml-5 w-48 '>
                                            <p className='font-semibold text-green-400 truncate'>{selectedTrack.track.name}</p>
                                            {selectedTrack.track.artists && (
                                                <p className='truncate'>
                                                    {selectedTrack.track.artists.map((artist, index) => (
                                                        <p className='inline mr-1 text-sm text-neutral-400 truncate' key={index} >{artist.name},</p>
                                                    ))}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='md:inline hidden'>
                                        <p className='mr-5 text-neutral-400'>{selectedTrack.track.album.name}</p>
                                    </div>
                                    <div>
                                        <p className='mr-5 text-neutral-400'>
                                            {formatTime(selectedTrack.track.duration_ms)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-lg" style={{
                            // background: `linear-gradient(to bottom, ${color}, #121212)`,
                        }} >
                            <div className='p-5' style={{ backgroundColor: 'rgba(18, 18, 18, 0.15)' }}>
                            <p className='font-semibold text-neutral-400'>Next from:</p>
                                {selectedPlaylistSubset && (
                                    <div className=''>
                                        {selectedPlaylistSubset.map((playlist, index) => (
                                            <div className='flex items-center justify-between py-1 rounded-md spotify-list' key={index} onClick={() => playAlbum(playlist)}>
                                                <div className='flex items-center '>
                                                    <p className='mx-5 text-neutral-400 w-4 number-s-list md:inline hidden'>{index + 1}</p>
                                                    <button className='px-5 py-4 hidden play-s-list' onClick={() => playAlbum(playlist)}>
                                                        {isPlaying && selectedTrack && playlist.track.id === selectedTrack.track.id ?
                                                            <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto"><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>
                                                            :
                                                            <svg fill='#fff' viewBox="0 0 24 24" className="w-4 h-4 mx-auto"><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>
                                                        }
                                                    </button>
                                                    <div>
                                                        {isPlaying && selectedTrack && playlist.track.id === selectedTrack.track.id ?
                                                            <div className='w-10 flex justify-center' >
                                                                <img className='w-6 h-6 ' src='https://open.spotifycdn.com/cdn/images/equaliser-animated-green.f5eb96f2.gif' ></img>
                                                            </div>
                                                            :
                                                            <img className='w-10 h-10 md:rounded-none rounded-lg' src={playlist.track.album.images[0]?playlist.track.album.images[0].url:null}></img>
                                                        }
                                                    </div>
                                                    <div className='ml-5 w-48 '>
                                                        <p className='font-semibold truncate'>{playlist.track.name}</p>
                                                        {playlist.track.artists && (
                                                            <p className='truncate'>
                                                                {playlist.track.artists.map((artist, index) => (
                                                                    <p className='inline mr-1 text-sm text-neutral-400 truncate' key={index} >{artist.name},</p>
                                                                ))}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='md:inline hidden'>
                                                    <p className='mr-5 text-neutral-400'>{playlist.track.album.name}</p>
                                                </div>
                                                <div>
                                                    <p className='mr-5 text-neutral-400'>
                                                        {formatTime(playlist.track.duration_ms)}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <p className='text-center'>Loading... </p> // Display a loading message while waiting for data and images
            )}
        </div>
    );
}

export default Queue;
