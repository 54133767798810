import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Color, { Palette } from "color-thief-react";

import Skeleton from '@mui/material/Skeleton';

const AlbumSpotify = (props) => {

    const navigate = useNavigate(); // Get the navigate function
    const playlistPage = (playlistId) => {
        navigate(`/spotify/album/${playlistId}`);
    };

    const playlistsSection = props.albumsSection
    const loading = props.loading
    return (
        <>
            {playlistsSection && !loading ? (
                <div className="mx-10">
                    <h2 className="text-2xl font-bold mb-2" >{ }</h2>
                    <div className="relative">
                        <Swiper
                            navigation={true} modules={[Navigation]}
                            spaceBetween={50}
                            slidesPerView={5}
                            slidesPerGroup={5}
                            speed={700}
                            breakpoints={{
                                240: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                    slidesPerGroup: 2,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                    slidesPerGroup: 4,

                                },
                                1024: {
                                    slidesPerView: 5,
                                    spaceBetween: 50,
                                    slidesPerGroup: 5,

                                },
                            }}
                        >
                            {playlistsSection.items.map((playlist, index) => (

                                <SwiperSlide className="bg-neutral-900 p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer" key={index} onClick={() => playlistPage(playlist.id)}>
                                    <div className="relative  ">
                                        <div className="absolute z-20 bottom-0 right-3 cursor-pointer opacity-0 image_spotify transition-all ease-in-out duration-300" >
                                            <div className={`bg-green-500 hover:scale-110 hover:bg-green-400 transition ease-in-out duration-100 p-3 rounded-full cursor-pointer shadow-xl `} >
                                                <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-8 h-8 mx-auto transition-all duration-500`}>
                                                    <path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        {/* <img className="rounded-xl" src={playlistsSection ? playlist.images[0].url : null} alt={playlist.title} /> */}
                                        <Palette src={playlist && playlist.images[0] ? playlist.images[0].url : null} crossOrigin="anonymous" format="hex" colorCount={4}>
                                            {({ data, loading }) => (
                                                <div className='rounded-lg aspect-square' style={{ backgroundColor: data ? data[1] : null }}>
                                                    <LazyLoadImage
                                                        src={playlist && playlist.images[0] ? playlist.images[0].url : null}
                                                        alt={playlist.title}
                                                        effect="blur"
                                                        className='rounded-lg inline'
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Palette>
                                    </div>
                                    <div className="mt-5">
                                        <p className=" truncate text-white font-bold">{playlist.name}</p>
                                        <p className=" truncate text-gray-400 text-xs">{playlist.artists[0].name}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            ) : (
                <>
                    <div className="mx-10">
                        <div className="relative">
                            <Swiper
                                navigation={true} modules={[Navigation]}
                                spaceBetween={50}
                                slidesPerView={5}
                                slidesPerGroup={5}
                                speed={700}
                                breakpoints={{
                                    240: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                        slidesPerGroup: 2,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 40,
                                        slidesPerGroup: 4,

                                    },
                                    1024: {
                                        slidesPerView: 5,
                                        spaceBetween: 50,
                                        slidesPerGroup: 5,

                                    },
                                }}
                            >
                                <SwiperSlide className="bg-neutral-900 h-full p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer">
                                    <div className="">
                                        <div className='aspect-square'>
                                            <Skeleton className='rounded-lg' animation="wave" sx={{ bgcolor: 'grey.900' }} variant="rectangular" width="100%" height="100%" />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="bg-neutral-900 h-full p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer">
                                    <div className="">
                                        <div className='aspect-square'>
                                            <Skeleton className='rounded-lg' animation="wave" sx={{ bgcolor: 'grey.900' }} variant="rectangular" width="100%" height="100%" />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="bg-neutral-900 h-full p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer">
                                    <div className="">
                                        <div className='aspect-square'>
                                            <Skeleton className='rounded-lg' animation="wave" sx={{ bgcolor: 'grey.900' }} variant="rectangular" width="100%" height="100%" />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="bg-neutral-900 h-full p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer">
                                    <div className="">
                                        <div className='aspect-square'>
                                            <Skeleton className='rounded-lg' animation="wave" sx={{ bgcolor: 'grey.900' }} variant="rectangular" width="100%" height="100%" />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="bg-neutral-900 h-full p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer">
                                    <div className="">
                                        <div className='aspect-square'>
                                            <Skeleton className='rounded-lg' animation="wave" sx={{ bgcolor: 'grey.900' }} variant="rectangular" width="100%" height="100%" />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="bg-neutral-900 h-full p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer">
                                    <div className="">
                                        <div className='aspect-square'>
                                            <Skeleton className='rounded-lg' animation="wave" sx={{ bgcolor: 'grey.900' }} variant="rectangular" width="100%" height="100%" />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="bg-neutral-900 h-full p-4 hover:bottomd hover:bg-neutral-800 rounded-lg transition-all ease-in-out duration-300 cursor-pointer">
                                    <div className="">
                                        <div className='aspect-square'>
                                            <Skeleton className='rounded-lg' animation="wave" sx={{ bgcolor: 'grey.900' }} variant="rectangular" width="100%" height="100%" />
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                        <Skeleton className='rounded-lg' animation="wave" variant="text" sx={{ fontSize: '1rem', bgcolor: 'grey.900' }} />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </>
            )
            }
        </>
    )
}
export default AlbumSpotify;