import React from 'react';
import Button from './Button';







import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import required modules
import { Navigation } from 'swiper/modules';


function Playlist(props) {
  const data = props.data
  // Add a conditional check to ensure data is not null before accessing its properties
  const playlistsSection = data ? data.sections.find(section => section.id === "playlists") : null;
  const playlistsheader = data ? data.sections.find(section => section.id === "playlists_header") : null;
  const playlists = playlistsSection ? playlistsSection.items || [] : [];

  return (
    <div className="">
      <div className="" >
        {playlistsSection && (
          <div className="mx-10">
            <h2 className="text-2xl font-bold mb-2" >{playlistsheader.title}</h2>
            <div className="relative">
              <Swiper
                navigation={true} modules={[Navigation]}
                spaceBetween={50}
                slidesPerView={5}
                slidesPerGroup={5}
                speed={700}
                breakpoints={{
                  240: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    slidesPerGroup: 2,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                    slidesPerGroup: 4,

                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                    slidesPerGroup: 5,

                  },
                }}
              >
                {playlists.map((playlist, index) => (

                  <SwiperSlide className="" key={index}>
                    <div className="hover:blocku relative">
                    <Button playlist={playlist} />
                    <img className="rounded-xl" src={playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com')} alt={playlist.title} />
                    </div>
                    <div className="mt-5">
                      <p className=" truncate text-white font-bold">{playlist.song}</p>
                      <p className=" truncate text-gray-400 text-xs">{playlist.artist}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}
      </div >
    </div>
  );
}

export default Playlist;
