import React, { useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import likeAnimate from './lottie/like.json'
import dislikeAnimate from './lottie/dislike.json'
import { useSpotifyStreamLink } from "./components-spotify/spotifyStreamLinkContext";
import { ToastContainer, toast , Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const SpotifyAlbumLike = (props) => {

    const {isClicked, setIsClicked} = useSpotifyStreamLink();
    const [isClick, setIsClick] = useState(false);
    const {userId, setSetUserId} = useSpotifyStreamLink();
    const albumData = props.albumData
    const like = props.like


 useEffect(()=>{
    if (like){
        setIsClick(true)
        setIsClicked(true)
    }
 },[like])
    const handleClick = async () => {
        if (!isClick){
            setIsClicked(true);
            setIsClick(true);
            const url = 'http://65.109.181.122:3000/save-album';

            // The data you want to send in the request body
            const data = {
              userId: userId,
              albumId: albumData.id,
              imageUrl:albumData.images[0].url,
              artist:albumData.artists[0].name,
              name:albumData.name,
            };
          
            // Options for the fetch function
            const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                 Authorization: `${localStorage.getItem('authToken')}`,
              },
              body: JSON.stringify(data),
            };
          
            try {
              const response = await fetch(url, options);
          
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
          
              // If the response is successful, you can handle the data here
              const result = await response.json();
              console.log('Response:', result);
              toast(result.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 5000, // milliseconds
                hideProgressBar: true,
                transition: Slide ,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                closeButton: false,
                style: { // Custom styles
                  bottom:'100px',
                  textAlign:'center',
                  color:'black',
                  maxWidth:'250px',
                  margin: '10px auto'
                },
              });
            } catch (error) {
              // Handle errors here
              console.error('Error:', error.message);
            }

        }else{
            setIsClicked(false);
            setIsClick(false);

            const url = 'http://65.109.181.122:3000/remove-album';

            // The data you want to send in the request body
            const data = {
              userId: userId,
              albumId: albumData.id,
            };
          
            // Options for the fetch function
            const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${localStorage.getItem('authToken')}`
              },
              body: JSON.stringify(data),
            };
          
            try {
              const response = await fetch(url, options);
          
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
          
              // If the response is successful, you can handle the data here
              const result = await response.json();
              console.log('Response:', result);
              toast(result.message, {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 5000, // milliseconds
                hideProgressBar: true,
                transition: Slide ,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                closeButton: false,
                style: { // Custom styles
                  bottom:'100px',
                  textAlign:'center',
                  color:'black',
                  maxWidth:'250px',
                  margin:'10px auto',
                },
              });
            } catch (error) {
              // Handle errors here
              console.error('Error:', error.message);
            }

        }
    };






    return (
        <div className='relative'>
            <button onClick={handleClick} className='z-10'>
                {/* <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" class="fill-neutral-400 w-8 h-8 hover:fill-white hover:scale-105"><path d="M1.69 2A4.582 4.582 0 0 1 8 2.023 4.583 4.583 0 0 1 11.88.817h.002a4.618 4.618 0 0 1 3.782 3.65v.003a4.543 4.543 0 0 1-1.011 3.84L9.35 14.629a1.765 1.765 0 0 1-2.093.464 1.762 1.762 0 0 1-.605-.463L1.348 8.309A4.582 4.582 0 0 1 1.689 2zm3.158.252A3.082 3.082 0 0 0 2.49 7.337l.005.005L7.8 13.664a.264.264 0 0 0 .311.069.262.262 0 0 0 .09-.069l5.312-6.33a3.043 3.043 0 0 0 .68-2.573 3.118 3.118 0 0 0-2.551-2.463 3.079 3.079 0 0 0-2.612.816l-.007.007a1.501 1.501 0 0 1-2.045 0l-.009-.008a3.082 3.082 0 0 0-2.121-.861z"></path></svg> */}
                {isClick ? (
                    <Lottie
                        animationData={likeAnimate}
                        loop={false}
                        className='w-[65px] h-[65px] absolute z-0 -top-4 -left-4 hover:scale-110'
                    />
                )
                    : (

                        <Lottie
                            animationData={dislikeAnimate}
                            loop={false}
                            className='w-[65px] h-[65px] absolute z-0 -top-4 -left-4 hover:scale-110 '
                        />
                    )
                }
            </button>
            <ToastContainer />
        </div>
    )
}
export default SpotifyAlbumLike;