import React from "react";
import rjimg from './rj.png'
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import spimg from './spotify.png'
import { useSpotifyStreamLink } from "./components-spotify/spotifyStreamLinkContext";
import SpotifyNavbar from "./spotifyNavbar";
import withAuth from "./auth/withAuth";

const Home = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const { spotify, setSpotify } = useSpotifyStreamLink()



    const handleSpotifyClick = () => {
        navigate('/spotify'); // Use navigate to go to the /spotify route
        setSpotify(true)
    };
    const handleRjClick = () => {
        navigate('/radiojavan'); // Use navigate to go to the /spotify route
        setSpotify(false)
    };

    return (
        <div className="rounded-lg mt-3 md:mr-2 relative overflow-scroll bg-gradient-to-b from-[#212121] to-[#121212] no-scrollbar">
        <div className="grid gap-y-5 justify-center">
            <div className='w-[92%] h-16'>
            </div>
            <div className="mb-10 mt-4" onClick={handleSpotifyClick}>
                <p className="font-bold text-2xl text-center mb-4">Spotify</p>
                <img className="w-32 mx-auto rounded-3xl border-2 border-white" src={spimg}></img>
            </div>
            <div onClick={handleRjClick}>
                <p className="font-bold text-2xl text-center mb-4" >RadioJavan</p>
                <img className="w-32 mx-auto rounded-3xl " src={rjimg}></img>
            </div>
        </div>
        </div>
    )
}
export default withAuth(Home);