import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Axios from 'axios';
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Color, { Palette } from "color-thief-react";
import { useParams, useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate
import convert, { rgb } from 'color-convert';
import { saveAs } from 'file-saver';
import { useToken } from './components-spotify/TokenContext';
import Lyrics from './lyrics';





const AudioPlayer = () => {
  const { streamLink, setStreamLink } = useSpotifyStreamLink();
  const { selectedTrack, setselectedTrack } = useSpotifyStreamLink(); // Provide a default value
  const { currentIndex, setCurrentIndex } = useSpotifyStreamLink(); // Provide a default value
  const { selectedPlaylist, setSelectedPlaylist } = useSpotifyStreamLink(); // Provide a default value
  const { tokenLyrics, refreshAccessTokenLyrics } = useToken(); // Access token and refreshAccessToken from context
  const { queue, setQueue } = useSpotifyStreamLink(); // Store play/pause state
  const { lyrics, setLyrics } = useSpotifyStreamLink(); // Store play/pause state
  const { isPlaying, setIsPlaying } = useSpotifyStreamLink(); // Store play/pause state
  const [played, setPlayed] = useState(0);
  const [showPlayer, setShowPlayer] = useState(false);
  const [nextDisable, setNextDisable] = useState(false);
  const [shuffled, setShuffled] = useState(false);
  const [isRepeating, setIsRepeating] = useState(false);
  const [lastPlaylist, setLastPlaylist] = useState([]);
  const [muted, setMuted] = useState(false);
  const { currentTime, setCurrentTime } = useSpotifyStreamLink(); // Store current time
  const [totalDuration, setTotalDuration] = useState(0); // Store total duration
  const [color, setColor] = useState([]);
  const { playerRef, setPlayerRef } = useSpotifyStreamLink();
  const [volume, setVolume] = useState(1); // Initial volume value (0.5 for 50% volume)
  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation();



  const { lyricsData, setLyricsData } = useSpotifyStreamLink(); // Provide a default 

  const isRadioJavan = location.pathname.includes('/radiojavan');




  useEffect(() => {
    if (location.pathname.includes('/lyrics')) {
      setLyrics(true)
    } else {
      setLyrics(false)
    }
    if (location.pathname.includes('/queue')) {
      setQueue(true)
    } else {
      setQueue(false)
    }

  }, [location])




  const randomIndex = selectedPlaylist ? Math.floor(Math.random() * selectedPlaylist.length) : null;



  const handleDuration = (duration) => {
    setTotalDuration(duration); // Set the total duration when available
  };

  const handleProgress = (state) => {
    // Update the played state as the player progresses
    setPlayed(state.played);
    setCurrentTime(state.played * totalDuration);
  };
  const handlePlayPause = () => {
    setIsPlaying(!isPlaying); // Toggle play/pause state
  };
  const handleQueue = () => {
    if (queue) {
      setQueue(false) // Toggle play/pause state
      navigate(-1);
    } else {
      setQueue(true) // Toggle play/pause state
      navigate('/spotify/queue');
    }
  };
  const handleLyrics = () => {
    if (lyrics) {
      setLyrics(false) // Toggle play/pause state
      navigate(-1);
    } else {
      setLyrics(true) // Toggle play/pause state
      navigate('/spotify/lyrics');
    }
  };
  const handleShowPlayer = () => {
    setShowPlayer(!showPlayer); // Toggle play/pause state
  };
  const nextIndex = async () => {
    if (isRepeating) {
      setIsPlaying(false);
      playerRef.current.seekTo(0);
      setIsPlaying(true);
    } else {
      if (currentIndex < selectedPlaylist.length) {
        setselectedTrack(selectedPlaylist[currentIndex]);
        setIsPlaying(false)
        setNextDisable(true)
        try {
          const response = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${selectedPlaylist[currentIndex].track.id}`);

          if (response.status === 200) {
            const Link = response.data.link;
            setStreamLink(Link);
          } else {
            console.error('Failed to fetch stream link from the external API');
          }
        } catch (error) {
          console.error('Error while fetching the stream link:', error);
        } finally {
          setIsPlaying(true)
          if (currentIndex < selectedPlaylist.length) {
            setNextDisable(false)
            const responseNext = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${selectedPlaylist[currentIndex + 1].track.id}`);
          }
        }
        setCurrentIndex(currentIndex + 1);
      } else {
        // If at the end, loop back to the beginning (assuming a circular playlist)
        setCurrentIndex(1);
        setselectedTrack(selectedPlaylist[0]);
        try {
          const response = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${selectedPlaylist[0].track.id}`);
          if (response.status === 200) {
            const Link = response.data.link;
            setStreamLink(Link);
          } else {
            console.error('Failed to fetch stream link from the external API');
          }
        } catch (error) {
          console.error('Error while fetching the stream link:', error);
        }
      }
    }
  };

  const prevIndex = async () => {
    if (isRepeating) {
      setIsPlaying(false);
      playerRef.current.seekTo(0);
      setIsPlaying(true);
    } else {
      if (currentIndex > 1) {
        setselectedTrack(selectedPlaylist[currentIndex - 2]);
        try {
          const response = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${selectedPlaylist[currentIndex - 2].track.id}`);
          if (response.status === 200) {
            const Link = response.data.link;
            setStreamLink(Link);
          } else {
            console.error('Failed to fetch stream link from the external API');
          }
        } catch (error) {
          console.error('Error while fetching the stream link:', error);
        }
        setCurrentIndex(currentIndex - 1);
      } else {
        // If at the end, loop back to the beginning (assuming a circular playlist)
        setCurrentIndex(selectedPlaylist.length);
        setselectedTrack(selectedPlaylist[selectedPlaylist.length - 1]);
        try {
          const response = await Axios.get(`http://65.109.181.122:3000/fetchAndConvertToMP3/${selectedPlaylist[selectedPlaylist.length - 1].track.id}`);
          if (response.status === 200) {
            const Link = response.data.link;
            setStreamLink(Link);
          } else {
            console.error('Failed to fetch stream link from the external API');
          }
        } catch (error) {
          console.error('Error while fetching the stream link:', error);
        }
      }
    }
  };





  ////lyrics


  const spotifyApi = Axios.create({
    baseURL: `http://65.109.181.122:3000/proxyParamsLyrics?url=https://spclient.wg.spotify.com/color-lyrics/v2/track/${selectedTrack ? selectedTrack.track.id : null}/?format=json`,
    headers: {
      'Authorization': `Bearer ${tokenLyrics}`,
      'app-platform': 'WebPlayer'
      // Add any other headers you need
    },
  });

  spotifyApi.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        // Token is expired, attempt to refresh it
        const newToken = await refreshAccessTokenLyrics();
        if (newToken) {
          // Retry the original request with the new token
          error.config.headers['Authorization'] = `Bearer ${newToken}`;
          return Axios.request(error.config);
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const fetchLyricsData = async () => {
      try {
        const response = await spotifyApi.get('')
        if (response.status === 200) {
          setLyricsData(response.data);
        } else {
          console.error('Failed to fetch lyrics data from the external API');
          setLyricsData(null)
        }
      } catch (error) {
        console.error('Error while fetching lyrics data:', error);
        setLyricsData(null)
      }
    };

    fetchLyricsData();
  }, [selectedTrack]);
  const backgroundPlayerHex = lyricsData && lyricsData.colors ? `#${(lyricsData.colors.background >>> 0).toString(16)}` : null;

  const rgbaColor = convert.hex.rgb(backgroundPlayerHex ? backgroundPlayerHex : null);
  let hexColor;
  if (rgbaColor !== null) {
    // Set the alpha channel to 1
    rgbaColor[3] = 1;

    // Convert back to hex with full opacity
    hexColor = convert.rgb.hex(rgbaColor);

  } else {
    console.error('Conversion to RGBA failed. Check the input color or library.');
  }






  ////lyrics

  const handleSeek = (value) => {
    setCurrentTime(value)
  };

  const handleSeekTime = (value) => {
    if (playerRef.current) {
      playerRef.current.seekTo(value);
      setCurrentTime(value)
    }
  };
  const handleMute = () => {
    if (!muted) {
      setMuted(true);
      setVolume(0)
    } else {
      setMuted(false);
      setVolume(1)
    }

  }
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };
  if (selectedTrack) {
    navigator.mediaSession.metadata = new MediaMetadata({

      title: selectedTrack.track.name,
      artist: selectedTrack.track.artists[0].name,
      artwork: [
        {
          src: selectedTrack.track.album.images[0] ? selectedTrack.track.album.images[0].url : null,
          type: 'image/jpeg'
        },
        // You can provide multiple artwork sizes and types for better compatibility
      ],
    });
    // const isIOS = /(iPhone|iPad)/i.test(navigator.userAgent);

    // if (!isIOS) {
    navigator.mediaSession.setActionHandler('previoustrack', function () {
      prevIndex();
    });
    navigator.mediaSession.setActionHandler('play', function () {
      handlePlayPause();
    });
    navigator.mediaSession.setActionHandler('pause', function () {
      handlePlayPause();
    });
    navigator.mediaSession.setActionHandler('seekto', (evt) => {
      handleSeekTime(evt.seekTime)
    });

    navigator.mediaSession.setActionHandler('nexttrack', function () {
      nextIndex();
    });
  }


  const convertHexToRgb = (hexColor) => {
    const rgbColor = convert.hex.rgb(hexColor);
    return `${rgbColor[0] - 20}, ${rgbColor[1] - 20}, ${rgbColor[2] - 20}`;
  };
  const shufflePlaylist = () => {
    // Create a copy of the selectedPlaylist
    if (!shuffled) {
      const shuffledPlaylist = [...selectedPlaylist];
      setShuffled(true)
      setLastPlaylist(selectedPlaylist)

      // Shuffle the copied playlist using the Fisher-Yates algorithm
      for (let i = shuffledPlaylist.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledPlaylist[i], shuffledPlaylist[j]] = [shuffledPlaylist[j], shuffledPlaylist[i]];
      }

      // Update the state with the shuffled playlist
      setSelectedPlaylist(shuffledPlaylist);
      setCurrentIndex(1);
    } else {
      setSelectedPlaylist(lastPlaylist)
      setShuffled(false)
    }
  };
  const toggleRepeat = () => {
    setIsRepeating(!isRepeating);
  };


  // const handleDownloadClick = () => {
  //   // Create an invisible anchor element
  //   const link = document.createElement('a');
  //   link.href = `http://65.109.181.122:3000${streamLink}`;
  //   link.target = '_blank'; // Open the link in a new tab
  //   link.click();
  // };
  const handleDownloadClick = () => {
    // Construct the download link
    const downloadLink = `http://65.109.181.122:3000${streamLink}`;

    // Use FileSaver to save the file
    saveAs(downloadLink, `${selectedTrack.track.artists[0].name}-${selectedTrack.track.name}.mp3`);
  };

  // const handleDownloadClick = async () => {
  //   try {
  //     // Fetch the file from the provided source
  //     const downloadLink = `http://65.109.181.122:3000${streamLink}`;

  //     const response = await fetch(downloadLink);

  //     // Get the file blob
  //     const blob = await response.blob();

  //     // Use FileSaver to save the file with a specific filename

  //     // Save the file to local storage
  //     localStorage.setItem(selectedTrack.track.id , URL.createObjectURL(blob));
  //   } catch (error) {
  //     console.error('Error downloading the file:', error);
  //   }
  // };



  // }

  if (isRadioJavan) {
    // Return null to hide the menu
    return (
      null
    );
  }

  if (!selectedTrack) {
    // If selectedTrack is not available or falsy, render nothing (null)
    return null;
  }


  return (
    <div>
      <ReactPlayer
        className="hidden"
        ref={setPlayerRef} // Use ref to store a reference to the player
        url={`http://65.109.181.122:3000${streamLink}`}
        playsinline={true}
        playing={isPlaying} // Control play/pause state
        volume={volume} // Set the volume here
        onError={(e) => console.log('Error:', e)}
        onProgress={handleProgress} // Handle progress updates
        onDuration={handleDuration}
        onEnded={nextIndex}
      />
      <Palette src={selectedTrack.track.album.images[0] ? selectedTrack.track.album.images[0].url : null} crossOrigin="anonymous" format="hex" colorCount={4}>
        {/* {({ data, loading }) => {
          setColor(data ? data[1] : null)
          return (
            null
          );
        }} */}
        {({ data, loading }) => {
          if (data) {
            const rgbColors = data.map(hexColor => convertHexToRgb(hexColor));
            setColor(rgbColors[0]);
          } else {
            setColor(null);
          }

          return null;
        }}
      </Palette>
      {window.innerWidth < 768
        ? (!showPlayer ? <div className='fixed w-full h-16 md:bottom-0 bottom-[4.5rem] z-40 bg-black' style={{
          background: `rgb(${color})`,
        }} >
          <div className='w-full h-full'>
            <div className='flex w-full h-full justify-between items-center'>
              <div className='ml-5 flex items-center gap-x-5 md:w-[30%]' onClick={handleShowPlayer}>
                <img className=' w-10 h-10 rounded-lg' src={selectedTrack.track.album.images[0].url}></img>
                <div className=' w-32'>
                  <p className=' text-sm font-semibold truncate'>{selectedTrack.track.name}</p>
                </div>
              </div>
              <div className="w-[50%] h-full" onClick={handleShowPlayer}>
                <button className='w-full'></button>
              </div>
              <div className='mr-5 flex gap-x-5'>
                <button onClick={handleDownloadClick}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className=" hover:text-green-400 hover:fill-white w-7 h-7 ">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                  </svg>
                </button>
                <button className='w-6 h-6 pt-1' onClick={handleQueue}>
                  {!queue
                    ? <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-white '><path d="M15 15H1v-1.5h14V15zm0-4.5H1V9h14v1.5zm-14-7A2.5 2.5 0 0 1 3.5 1h9a2.5 2.5 0 0 1 0 5h-9A2.5 2.5 0 0 1 1 3.5zm2.5-1a1 1 0 0 0 0 2h9a1 1 0 1 0 0-2h-9z"></path></svg>

                    :
                    <div className='grid gap-y-1'>
                      <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-green-400'><path d="M15 15H1v-1.5h14V15zm0-4.5H1V9h14v1.5zm-14-7A2.5 2.5 0 0 1 3.5 1h9a2.5 2.5 0 0 1 0 5h-9A2.5 2.5 0 0 1 1 3.5zm2.5-1a1 1 0 0 0 0 2h9a1 1 0 1 0 0-2h-9z"></path></svg>
                      <div className='w-1 h-1 mx-auto bg-green-400 rounded-full'></div>
                    </div>

                  }
                </button>
                <button className='w-8 h-8 rounded-full relative' onClick={handlePlayPause}>

                  <svg data-encore-id="icon" role="img" aria-hidden="true" fill='#fff' viewBox="0 0 24 24" className={`w-8 h-8 mx-auto top-0 left-0 absolute transition-all duration-500 ${!isPlaying ? 'rotate-90 opacity-0 -scale-50' : ''
                    }`}><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>

                  <svg data-encore-id="icon" role="img" aria-hidden="true" fill='#fff' viewBox="0 0 24 24" className={`w-8 h-8 mx-auto top-0 left-0 absolute transition-all duration-500 ${isPlaying ? '-rotate-90 opacity-0 -scale-50' : ''
                    }`}><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>

                </button>

              </div>
            </div>
            <div className='w-full'>
              <Slider
                min={0}
                max={totalDuration}
                value={currentTime}
                onChange={handleSeek}
                onAfterChange={handleSeekTime}
                keyboard="false"
                className="custom-slider-spotify"
              />
            </div>
          </div>
        </div>
          :
          <div className='fixed w-full h-full md:bottom-0 bottom-16 z-40 bg-black px-3 overflow-scroll' style={{
            background: `rgb(${color})`,
          }}>
            <div className='w-full h-full justify-between pt-16 items-center'>
              <button className='w-full py-4' onClick={handleShowPlayer}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8 mx-auto">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </button>
              <div className='items-center gap-x-5'>
                <img className=' w-52 h-52 mx-auto mb-6 shadow-xl' src={selectedTrack.track.album.images[0] ? selectedTrack.track.album.images[0].url : null}></img>
                <div className=' w-9/12 mb-8'>
                  <p className=' text-lg font-bold truncate'>{selectedTrack.track.name}</p>
                  {selectedTrack.track.artists && (
                    <p className='truncate mr-1 text-xs font-semibold text-neutral-400'>
                      {selectedTrack.track.artists.map((artist, index) => (
                        <p className='inline' key={index} >{artist.name},</p>
                      ))}
                    </p>
                  )}
                </div>
              </div>
              <div className='grid gap-y-2'>
                <div className='w-full mx-auto'>
                  <Slider
                    min={0}
                    max={totalDuration}
                    value={currentTime}
                    onChange={handleSeek}
                    onAfterChange={handleSeekTime}
                    keyboard="false"
                    className="custom-slider-spotify2"
                  />
                  <div className='flex justify-between'>
                    <p className='text-neutral-600 text-xs'>{formatTime(currentTime)}</p>
                    <p className='text-neutral-600 text-xs'>{formatTime(totalDuration)}</p>
                  </div>
                </div>
                <div className='flex items-center justify-center gap-x-7'>
                  <button onClick={shufflePlaylist} className='w-5 h-5'>
                    {!shuffled
                      ?
                      <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className=' fill-white hover:fill-[#ABABAB]'><path d="M13.151.922a.75.75 0 1 0-1.06 1.06L13.109 3H11.16a3.75 3.75 0 0 0-2.873 1.34l-6.173 7.356A2.25 2.25 0 0 1 .39 12.5H0V14h.391a3.75 3.75 0 0 0 2.873-1.34l6.173-7.356a2.25 2.25 0 0 1 1.724-.804h1.947l-1.017 1.018a.75.75 0 0 0 1.06 1.06L15.98 3.75 13.15.922zM.391 3.5H0V2h.391c1.109 0 2.16.49 2.873 1.34L4.89 5.277l-.979 1.167-1.796-2.14A2.25 2.25 0 0 0 .39 3.5z"></path><path d="m7.5 10.723.98-1.167.957 1.14a2.25 2.25 0 0 0 1.724.804h1.947l-1.017-1.018a.75.75 0 1 1 1.06-1.06l2.829 2.828-2.829 2.828a.75.75 0 1 1-1.06-1.06L13.109 13H11.16a3.75 3.75 0 0 1-2.873-1.34l-.787-.938z"></path></svg>

                      :
                      <div className='grid gap-y-1'>
                        <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-green-400'><path d="M13.151.922a.75.75 0 1 0-1.06 1.06L13.109 3H11.16a3.75 3.75 0 0 0-2.873 1.34l-6.173 7.356A2.25 2.25 0 0 1 .39 12.5H0V14h.391a3.75 3.75 0 0 0 2.873-1.34l6.173-7.356a2.25 2.25 0 0 1 1.724-.804h1.947l-1.017 1.018a.75.75 0 0 0 1.06 1.06L15.98 3.75 13.15.922zM.391 3.5H0V2h.391c1.109 0 2.16.49 2.873 1.34L4.89 5.277l-.979 1.167-1.796-2.14A2.25 2.25 0 0 0 .39 3.5z"></path><path d="m7.5 10.723.98-1.167.957 1.14a2.25 2.25 0 0 0 1.724.804h1.947l-1.017-1.018a.75.75 0 1 1 1.06-1.06l2.829 2.828-2.829 2.828a.75.75 0 1 1-1.06-1.06L13.109 13H11.16a3.75 3.75 0 0 1-2.873-1.34l-.787-.938z"></path></svg>
                        <div className='w-1 h-1 mx-auto bg-green-400 rounded-full'></div>
                      </div>

                    }
                  </button>
                  <button onClick={prevIndex}>
                    <svg viewBox="0 0 16 16" className='w-5 h-5  fill-white hover:fill-[#ABABAB]'><path d="M3.3 1a.7.7 0 0 1 .7.7v5.15l9.95-5.744a.7.7 0 0 1 1.05.606v12.575a.7.7 0 0 1-1.05.607L4 9.149V14.3a.7.7 0 0 1-.7.7H1.7a.7.7 0 0 1-.7-.7V1.7a.7.7 0 0 1 .7-.7h1.6z"></path></svg>
                  </button>
                  <button className='w-14 h-14 bg-white rounded-full relative' onClick={handlePlayPause}>

                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-7 h-7 mx-auto top-[14px] left-[14px] absolute transition-all duration-500 ${!isPlaying ? 'rotate-90 opacity-0 -scale-50' : ''
                      }`}><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>

                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-7 h-7 mx-auto top-[14px] left-[14px] absolute transition-all duration-500 ${isPlaying ? '-rotate-90 opacity-0 -scale-50' : ''
                      }`}><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>

                  </button>
                  <button onClick={nextIndex} disabled={nextDisable} >
                    <svg viewBox="0 0 16 16" className='w-5 h-5 fill-white hover:fill-[#ABABAB]'><path d="M12.7 1a.7.7 0 0 0-.7.7v5.15L2.05 1.107A.7.7 0 0 0 1 1.712v12.575a.7.7 0 0 0 1.05.607L12 9.149V14.3a.7.7 0 0 0 .7.7h1.6a.7.7 0 0 0 .7-.7V1.7a.7.7 0 0 0-.7-.7h-1.6z"></path></svg>
                  </button>
                  <button onClick={toggleRepeat} className='w-5 h-5'>
                    {!isRepeating
                      ?
                      <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className=' fill-white hover:fill-[#ABABAB]'><path d="M0 4.75A3.75 3.75 0 0 1 3.75 1h8.5A3.75 3.75 0 0 1 16 4.75v5a3.75 3.75 0 0 1-3.75 3.75H9.81l1.018 1.018a.75.75 0 1 1-1.06 1.06L6.939 12.75l2.829-2.828a.75.75 0 1 1 1.06 1.06L9.811 12h2.439a2.25 2.25 0 0 0 2.25-2.25v-5a2.25 2.25 0 0 0-2.25-2.25h-8.5A2.25 2.25 0 0 0 1.5 4.75v5A2.25 2.25 0 0 0 3.75 12H5v1.5H3.75A3.75 3.75 0 0 1 0 9.75v-5z"></path></svg>
                      :
                      <div className='grid gap-y-1'>
                        <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-green-400'><path d="M0 4.75A3.75 3.75 0 0 1 3.75 1h8.5A3.75 3.75 0 0 1 16 4.75v5a3.75 3.75 0 0 1-3.75 3.75H9.81l1.018 1.018a.75.75 0 1 1-1.06 1.06L6.939 12.75l2.829-2.828a.75.75 0 1 1 1.06 1.06L9.811 12h2.439a2.25 2.25 0 0 0 2.25-2.25v-5a2.25 2.25 0 0 0-2.25-2.25h-8.5A2.25 2.25 0 0 0 1.5 4.75v5A2.25 2.25 0 0 0 3.75 12H5v1.5H3.75A3.75 3.75 0 0 1 0 9.75v-5z"></path></svg>
                        <div className='w-1 h-1 mx-auto bg-green-400 rounded-full'></div>
                      </div>

                    }
                  </button>
                </div>
              </div>
              <div className=''>
                <Lyrics />
              </div>
            </div>
          </div>
        )

        // Desctop


        : <div className='fixed w-full h-[5rem] md:bottom-0 bottom-16 z-40 bg-black'>
          <div className='flex w-full h-full justify-between items-center'>
            <div className='ml-5 flex items-center gap-x-5 md:w-[30%]'>
              <img className=' w-14 h-14 rounded-lg' src={selectedTrack.track.album.images[0] ? selectedTrack.track.album.images[0].url : null}></img>
              <div className=' w-9/12'>
                <p className=' text-sm font-semibold truncate'>{selectedTrack.track.name}</p>
                {selectedTrack.track.artists && (
                  <p className='truncate mr-1 text-xs font-semibold text-neutral-400'>
                    {selectedTrack.track.artists.map((artist, index) => (
                      <p className='inline' key={index} >{artist.name},</p>
                    ))}
                  </p>
                )}
              </div>
            </div>
            <div className='grid md:w-[40%] gap-y-2'>
              <div className='flex items-center justify-center gap-x-7'>
                <button onClick={shufflePlaylist} className='w-4 h-4'>
                  {!shuffled
                    ?
                    <svg data-encore-id="icon" role="img" fill='#ABABAB' aria-hidden="true" viewBox="0 0 16 16" className='hover:fill-white'><path d="M13.151.922a.75.75 0 1 0-1.06 1.06L13.109 3H11.16a3.75 3.75 0 0 0-2.873 1.34l-6.173 7.356A2.25 2.25 0 0 1 .39 12.5H0V14h.391a3.75 3.75 0 0 0 2.873-1.34l6.173-7.356a2.25 2.25 0 0 1 1.724-.804h1.947l-1.017 1.018a.75.75 0 0 0 1.06 1.06L15.98 3.75 13.15.922zM.391 3.5H0V2h.391c1.109 0 2.16.49 2.873 1.34L4.89 5.277l-.979 1.167-1.796-2.14A2.25 2.25 0 0 0 .39 3.5z"></path><path d="m7.5 10.723.98-1.167.957 1.14a2.25 2.25 0 0 0 1.724.804h1.947l-1.017-1.018a.75.75 0 1 1 1.06-1.06l2.829 2.828-2.829 2.828a.75.75 0 1 1-1.06-1.06L13.109 13H11.16a3.75 3.75 0 0 1-2.873-1.34l-.787-.938z"></path></svg>

                    :
                    <div className='grid gap-y-1'>
                      <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-green-400'><path d="M13.151.922a.75.75 0 1 0-1.06 1.06L13.109 3H11.16a3.75 3.75 0 0 0-2.873 1.34l-6.173 7.356A2.25 2.25 0 0 1 .39 12.5H0V14h.391a3.75 3.75 0 0 0 2.873-1.34l6.173-7.356a2.25 2.25 0 0 1 1.724-.804h1.947l-1.017 1.018a.75.75 0 0 0 1.06 1.06L15.98 3.75 13.15.922zM.391 3.5H0V2h.391c1.109 0 2.16.49 2.873 1.34L4.89 5.277l-.979 1.167-1.796-2.14A2.25 2.25 0 0 0 .39 3.5z"></path><path d="m7.5 10.723.98-1.167.957 1.14a2.25 2.25 0 0 0 1.724.804h1.947l-1.017-1.018a.75.75 0 1 1 1.06-1.06l2.829 2.828-2.829 2.828a.75.75 0 1 1-1.06-1.06L13.109 13H11.16a3.75 3.75 0 0 1-2.873-1.34l-.787-.938z"></path></svg>
                      <div className='w-1 h-1 mx-auto bg-green-400 rounded-full'></div>
                    </div>

                  }
                </button>
                <button onClick={prevIndex}>
                  <svg fill='#ABABAB' viewBox="0 0 16 16" className='w-4 h-4 hover:fill-white'><path d="M3.3 1a.7.7 0 0 1 .7.7v5.15l9.95-5.744a.7.7 0 0 1 1.05.606v12.575a.7.7 0 0 1-1.05.607L4 9.149V14.3a.7.7 0 0 1-.7.7H1.7a.7.7 0 0 1-.7-.7V1.7a.7.7 0 0 1 .7-.7h1.6z"></path></svg>
                </button>
                <button className='w-8 h-8 bg-white rounded-full relative' onClick={handlePlayPause}>

                  <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-5 h-5 mx-auto top-[6px] left-[6px] absolute transition-all duration-500 ${!isPlaying ? 'rotate-90 opacity-0 -scale-50' : ''
                    }`}><path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path></svg>

                  <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 24 24" className={`w-5 h-5 mx-auto top-[6px] left-[6px] absolute transition-all duration-500 ${isPlaying ? '-rotate-90 opacity-0 -scale-50' : ''
                    }`}><path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path></svg>

                </button>
                <button onClick={nextIndex} disabled={nextDisable}>
                  <svg fill='#ABABAB' viewBox="0 0 16 16" className='w-4 h-4 hover:fill-white'><path d="M12.7 1a.7.7 0 0 0-.7.7v5.15L2.05 1.107A.7.7 0 0 0 1 1.712v12.575a.7.7 0 0 0 1.05.607L12 9.149V14.3a.7.7 0 0 0 .7.7h1.6a.7.7 0 0 0 .7-.7V1.7a.7.7 0 0 0-.7-.7h-1.6z"></path></svg>
                </button>
                <button onClick={toggleRepeat} className='w-4 h-4'>
                  {!isRepeating
                    ?
                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-[#ABABAB] hover:fill-white'><path d="M0 4.75A3.75 3.75 0 0 1 3.75 1h8.5A3.75 3.75 0 0 1 16 4.75v5a3.75 3.75 0 0 1-3.75 3.75H9.81l1.018 1.018a.75.75 0 1 1-1.06 1.06L6.939 12.75l2.829-2.828a.75.75 0 1 1 1.06 1.06L9.811 12h2.439a2.25 2.25 0 0 0 2.25-2.25v-5a2.25 2.25 0 0 0-2.25-2.25h-8.5A2.25 2.25 0 0 0 1.5 4.75v5A2.25 2.25 0 0 0 3.75 12H5v1.5H3.75A3.75 3.75 0 0 1 0 9.75v-5z"></path></svg>
                    :
                    <div className='grid gap-y-1'>
                      <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-green-400'><path d="M0 4.75A3.75 3.75 0 0 1 3.75 1h8.5A3.75 3.75 0 0 1 16 4.75v5a3.75 3.75 0 0 1-3.75 3.75H9.81l1.018 1.018a.75.75 0 1 1-1.06 1.06L6.939 12.75l2.829-2.828a.75.75 0 1 1 1.06 1.06L9.811 12h2.439a2.25 2.25 0 0 0 2.25-2.25v-5a2.25 2.25 0 0 0-2.25-2.25h-8.5A2.25 2.25 0 0 0 1.5 4.75v5A2.25 2.25 0 0 0 3.75 12H5v1.5H3.75A3.75 3.75 0 0 1 0 9.75v-5z"></path></svg>
                      <div className='w-1 h-1 mx-auto bg-green-400 rounded-full'></div>
                    </div>

                  }
                </button>
              </div>
              <div className='flex w-full items-center gap-x-2'>
                <p className='text-neutral-600 text-xs'>{formatTime(currentTime)}</p>
                <Slider
                  min={0}
                  max={totalDuration}
                  value={currentTime}
                  onChange={handleSeek}
                  onAfterChange={handleSeekTime}
                  keyboard="false"
                  className="custom-slider-spotify"

                />
                <p className='text-neutral-600 text-xs'>{formatTime(totalDuration)}</p>
              </div>
            </div>
            <div className='md:w-[30%] flex gap-x-3 justify-end items-center mr-5'>
              <button onClick={handleDownloadClick}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" hover:text-green-400 hover:fill-white w-6 h-6 ">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                </svg>
              </button>
              <button className='w-4 h-4' onClick={handleLyrics}>
                {!lyrics
                  ? <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-neutral-400 hover:fill-white'><path d="M13.426 2.574a2.831 2.831 0 0 0-4.797 1.55l3.247 3.247a2.831 2.831 0 0 0 1.55-4.797zM10.5 8.118l-2.619-2.62A63303.13 63303.13 0 0 0 4.74 9.075L2.065 12.12a1.287 1.287 0 0 0 1.816 1.816l3.06-2.688 3.56-3.129zM7.12 4.094a4.331 4.331 0 1 1 4.786 4.786l-3.974 3.493-3.06 2.689a2.787 2.787 0 0 1-3.933-3.933l2.676-3.045 3.505-3.99z"></path></svg>

                  :
                  <div className='grid gap-y-1'>
                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-green-400'><path d="M13.426 2.574a2.831 2.831 0 0 0-4.797 1.55l3.247 3.247a2.831 2.831 0 0 0 1.55-4.797zM10.5 8.118l-2.619-2.62A63303.13 63303.13 0 0 0 4.74 9.075L2.065 12.12a1.287 1.287 0 0 0 1.816 1.816l3.06-2.688 3.56-3.129zM7.12 4.094a4.331 4.331 0 1 1 4.786 4.786l-3.974 3.493-3.06 2.689a2.787 2.787 0 0 1-3.933-3.933l2.676-3.045 3.505-3.99z"></path></svg>
                    <div className='w-1 h-1 mx-auto bg-green-400 rounded-full'></div>
                  </div>

                }
              </button>
              <button className='w-4 h-4' onClick={handleQueue}>
                {!queue
                  ? <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-neutral-400 hover:fill-white'><path d="M15 15H1v-1.5h14V15zm0-4.5H1V9h14v1.5zm-14-7A2.5 2.5 0 0 1 3.5 1h9a2.5 2.5 0 0 1 0 5h-9A2.5 2.5 0 0 1 1 3.5zm2.5-1a1 1 0 0 0 0 2h9a1 1 0 1 0 0-2h-9z"></path></svg>

                  :
                  <div className='grid gap-y-1'>
                    <svg data-encore-id="icon" role="img" aria-hidden="true" viewBox="0 0 16 16" className='fill-green-400 hover:fill-green-400'><path d="M15 15H1v-1.5h14V15zm0-4.5H1V9h14v1.5zm-14-7A2.5 2.5 0 0 1 3.5 1h9a2.5 2.5 0 0 1 0 5h-9A2.5 2.5 0 0 1 1 3.5zm2.5-1a1 1 0 0 0 0 2h9a1 1 0 1 0 0-2h-9z"></path></svg>
                    <div className='w-1 h-1 mx-auto bg-green-400 rounded-full'></div>
                  </div>

                }
              </button>
              <div className='flex w-28 gap-x-2'>

                <button className='' onClick={handleMute}>
                  {muted
                    ? <svg data-encore-id="icon" role="presentation" aria-label="Volume off" aria-hidden="true" id="volume-icon" viewBox="0 0 16 16" className='fill-neutral-400 hover:fill-white w-4 h-4 '><path d="M13.86 5.47a.75.75 0 0 0-1.061 0l-1.47 1.47-1.47-1.47A.75.75 0 0 0 8.8 6.53L10.269 8l-1.47 1.47a.75.75 0 1 0 1.06 1.06l1.47-1.47 1.47 1.47a.75.75 0 0 0 1.06-1.06L12.39 8l1.47-1.47a.75.75 0 0 0 0-1.06z"></path><path d="M10.116 1.5A.75.75 0 0 0 8.991.85l-6.925 4a3.642 3.642 0 0 0-1.33 4.967 3.639 3.639 0 0 0 1.33 1.332l6.925 4a.75.75 0 0 0 1.125-.649v-1.906a4.73 4.73 0 0 1-1.5-.694v1.3L2.817 9.852a2.141 2.141 0 0 1-.781-2.92c.187-.324.456-.594.78-.782l5.8-3.35v1.3c.45-.313.956-.55 1.5-.694V1.5z"></path></svg>
                    : <svg data-encore-id="icon" role="presentation" aria-label="Volume high" aria-hidden="true" id="volume-icon" viewBox="0 0 16 16" className='fill-neutral-400 hover:fill-white w-4 h-4 '><path d="M9.741.85a.75.75 0 0 1 .375.65v13a.75.75 0 0 1-1.125.65l-6.925-4a3.642 3.642 0 0 1-1.33-4.967 3.639 3.639 0 0 1 1.33-1.332l6.925-4a.75.75 0 0 1 .75 0zm-6.924 5.3a2.139 2.139 0 0 0 0 3.7l5.8 3.35V2.8l-5.8 3.35zm8.683 4.29V5.56a2.75 2.75 0 0 1 0 4.88z"></path><path d="M11.5 13.614a5.752 5.752 0 0 0 0-11.228v1.55a4.252 4.252 0 0 1 0 8.127v1.55z"></path></svg>

                  }

                </button>
                <Slider
                  min={0}
                  max={1} // Volume ranges from 0 to 1
                  step={0.01} // You can adjust the step as needed
                  value={volume}
                  onChange={(value) => setVolume(value)}
                  className="custom-slider-spotify"
                />

              </div>
            </div>
          </div>
        </div>
      }

    </div>
  );
};

export default AudioPlayer;
