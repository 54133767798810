import React from 'react'
import { useIdContext } from './components/context/IdContext';
import { useIdpContext } from './components/context/IdpContext';
import { useIdvContext } from './components/context/IdvContext';
import { useMusicContext } from './components/context/MusicContext';
import { usePlayContext } from './components/context/PlayContext';
import { usePlaylistContext } from './components/context/PlaylistContext';
import Button from './components/home_item/Button';
import { useNavigate } from 'react-router-dom';


function SearchResults(props) {
    const {
        searchResults,
    } = props;
    const navigate = useNavigate();
    const handleSearchClick = () => {
        navigate('/search');
    };









    if (searchResults) {
        return (
            <div className='rounded-lg mt-3 md:mr-2 relative overflow-scroll bg-gradient-to-b from-[#212121] to-[#121212] no-scrollbar'>
                <div className="grid my-28 mx-10 md:grid-cols-3 grid-cols-1 gap-x-7 gap-y-7">
                    {searchResults && searchResults.top.map((playlist, index) => (
                        <div key={index} className="flex hover:blocku items-center bg-[#181818] rounded-lg">
                            <div className=" md:w-48 w-32 relative">
                                <Button playlist={playlist.type === "playlist" ? playlist.playlist : playlist} />
                                
                                <img className="rounded-xl" src={playlist.photo ? playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com') : playlist.playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com')}></img>
                            </div>
                            <div className="w-1/2 mt-3 ml-4">
                                <p className="truncate text-white font-bold">
                                    {playlist.type === "playlist"
                                        ? playlist.playlist.title // Render playlist title
                                        : playlist.type === "podcast"
                                            ? playlist.title // Render podcast title
                                            : playlist.type === "artist"
                                                ? playlist.name
                                                : playlist.song
                                    }
                                </p>
                                <p className="truncate text-gray-400 text-xs">{playlist.artist ? playlist.artist : playlist.podcast_artist}</p>
                            </div>
                        </div>
                    ))}
                </div>


            </div>


        );
    }
}
export default SearchResults;