import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useSpotifyStreamLink } from './components-spotify/spotifyStreamLinkContext';
import { Lrc , useRecoverAutoScrollImmediately } from 'react-lrc';


const Lyrics = () => {
    const { setLyrics } = useSpotifyStreamLink();
    const { selectedTrack, setselectedTrack } = useSpotifyStreamLink(); // Provide a default value
    const { selectedPlaylist, setSelectedPlaylist } = useSpotifyStreamLink(); // Provide a default 
    const { lyricsData } = useSpotifyStreamLink(); // Provide a default 
    const { currentTime, setCurrentTime } = useSpotifyStreamLink(); // Store current time
    const { playerRef } = useSpotifyStreamLink();
    const { navColor, setNavColor } = useSpotifyStreamLink();
    const [currentLine, setCurrentLine] = useState(null)
    const { signal, recoverAutoScrollImmediately } = useRecoverAutoScrollImmediately();


    const lyricsContainerRef = useRef(null);
    



    const convertToLrcString = (lines) => {
        if (!lines || !Array.isArray(lines)) {
            return ''; // or handle the null/undefined case appropriately
        }
    
        return lines
            .map((line) => {
                const timestamp = Math.floor(line.startTimeMs / 1000);
                return `[${formatTime(timestamp)}] ${line.words}`;
            })
            .join('\n');
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
    };

    const padZero = (num) => {
        return num < 10 ? `0${num}` : num;
    };

    // Usage
    const lrcString = convertToLrcString(lyricsData&&lyricsData.lyrics?lyricsData.lyrics.lines:null);



    const convertSecondsToMilliseconds = (currentTimeInSeconds) => {
        return currentTimeInSeconds * 1000;
    };
    const currentTimeMs = convertSecondsToMilliseconds(currentTime);





    const location = useLocation();

    const backgroundHex = lyricsData && lyricsData.colors ? `#${(lyricsData.colors.background >>> 0).toString(16)}` : null;
    setNavColor(backgroundHex)


    useEffect(() => {
        if (location.pathname === '/spotify/lyrics') {
            // Set queue to true when the URL is /spotify/queue
            setLyrics(true);
        } else {
            // Set queue to false when the URL changes to something else
            setLyrics(false);
        }
    }, [location.pathname]);



    const determineLineColor = (line, index) => {
        const startTimeInSeconds = parseInt(lyricsData.lyrics.lines[index].startTimeMs, 10) / 1000;

        let endTimeInSeconds;
        if (index < lyricsData.lyrics.lines.length - 1) {
            endTimeInSeconds = parseInt(lyricsData.lyrics.lines[index + 1].startTimeMs, 10) / 1000;
        }
        if (currentTime >= startTimeInSeconds && currentTime < endTimeInSeconds) {
            return 'white'; // Set current line to white

        }

        if (currentTime >= endTimeInSeconds) {
            return 'white'; // Expired lines are black
        }

        return 'black'; // Lines that have not started yet are gray
    };

    const determineLineOpacity = (line, index) => {
        const startTimeInSeconds = parseInt(line.startTimeMs, 10) / 1000;

        let endTimeInSeconds;
        if (index < lyricsData.lyrics.lines.length - 1) {
            endTimeInSeconds = parseInt(lyricsData.lyrics.lines[index + 1].startTimeMs, 10) / 1000;
        }
        if (currentTime >= startTimeInSeconds && currentTime < endTimeInSeconds) {
            return '1'; // Set current line to white
        }

        if (currentTime >= endTimeInSeconds) {
            return '0.5'; // Expired lines are black
        }

        return '1'; // Lines that have not started yet are gray
    };




    const handleLineClick = (line, index) => {
        const startTimeInSeconds = parseInt(lyricsData.lyrics.lines[index].startTimeMs, 10) / 1000;
        playerRef.current.seekTo(startTimeInSeconds)
    };


    const [hoveredIndex, setHoveredIndex] = useState(null);



   

    return (
        <div className=' rounded-lg md:mr-2 mt-3 ' ref={lyricsContainerRef} style={{ background: backgroundHex }}>
            <div className='w-full flex justify-center'>

            {lyricsData && lyricsData.lyrics.lines && lyricsData.lyrics.lines.length > 0 ?
                <div className='md:py-20 pt-3 pb-10 md:mx-auto md:w-1/2 px-5 h-64 md:h-full flex absolute no-scrollbar' >
                    {/* {lyricsData.lyrics.lines.map((line, index) => (
                        <p
                            className='font-bold text-4xl text-left pb-5 cursor-pointer'
                            key={line.startTimeMs}
                            style={{ color: hoveredIndex === index ? 'white' : determineLineColor(line, index), opacity: hoveredIndex === index ? '1' : determineLineOpacity(line, index) }}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            onClick={() => handleLineClick(line, index)}
                        >
                            {line.words}
                        </p>
                    ))} */}
                    <Lrc
                        lrc={lrcString}
                        currentMillisecond={currentTimeMs}
                        lineRenderer={({ index, active, line }) => (
                            // Customize how each line is rendered based on your needs
                            // You can use the active prop to style the current line differently
                            <p
                                className='font-bold md:text-4xl text-2xl text-center pb-5 cursor-pointer'
                                key={line.startMillisecond}
                                style={{ color: hoveredIndex === index ? 'white' : determineLineColor(line, index), opacity: hoveredIndex === index ? '1' : determineLineOpacity(line, index) }}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                onClick={() => handleLineClick(line, index)}
                            >
                                {line.content}
                            </p>
                        )}
                        recoverAutoScrollSignal={signal}
                        recoverAutoScrollInterval={5000}
                        verticalSpace
                        className='no-scrollbar'
                        />
                </div>
                : <div className='text-center mt-28'><p>No Lyrics Available</p> </div>
                
            }
            </div>
        </div>
    );
};

export default Lyrics;
