import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSpotifyStreamLink } from '../components-spotify/spotifyStreamLinkContext';

const UserProfile = () => {
    const { userId, setSetUserId } = useSpotifyStreamLink();
    const {userData, setUserData} = useSpotifyStreamLink();
    const [editMode, setEditMode] = useState(false); // New state for edit mode
    const [expireDate, setExpireDate] = useState('');

    useEffect(() => {
        // Fetch user details and expiration date when the component mounts
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`http://65.109.181.122:3000/user/${userId}`);
                const { id, username, email, fullname, admin, expire_date } = response.data;
                setUserData({ id, username, email, fullname, admin });
                setExpireDate(expire_date || 'No expiration date');
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleUpdateClick = async () => {
        try {
            // Make a PUT request to update user data
            await axios.put(`http://65.109.181.122:3000/user/${userId}`, userData);
            alert('User data updated successfully!');
        } catch (error) {
            console.error('Error updating user data:', error);
            alert('Error updating user data. Please try again.');
        }
    };
    const handleEditClick = () => {
        setEditMode(!editMode); // Enable edit mode when the user clicks the edit button
    };

    return (
        <div className='rounded-lg mt-3 md:mr-2 relative overflow-scroll bg-gradient-to-b from-[#212121] to-[#121212] no-scrollbar'>
            <div className='md:w-1/2 md:mx-auto mx-10 flex flex-col justify-center my-20 gap-y-2'>
                <div className='mb-10 flex flex-col gap-y-2'>
                    <h1 className='font-bold text-sm'>User Profile</h1>
                    <p className='font-bold text-sm'>Expire Date: {expireDate}</p>
                </div>
                <label className='flex flex-col font-bold text-sm gap-y-2'>
                    Username
                    <input className='bg-transparent border-[1px] border-neutral-400 rounded-md p-3 font-bold' type="text" name="username" value={userData.username} onChange={handleInputChange} />
                </label>
                <br />
                <label className='flex flex-col font-bold text-sm gap-y-2'>
                    Email:
                    <input className='bg-transparent border-[1px] border-neutral-400 rounded-md p-3 font-bold' type="text" name="email" value={userData.email} onChange={handleInputChange} />
                </label>
                <br />
                <label className='flex flex-col font-bold text-sm gap-y-2'>
                    Full Name:
                    <input className='bg-transparent border-[1px] border-neutral-400 rounded-md p-3 font-bold' type="text" name="fullname" value={userData.fullname} onChange={handleInputChange} />
                </label>
                <br />
                <label className={`flex flex-col font-bold text-sm gap-y-2 ${!editMode ? 'text-neutral-500' : 'text-white'}`} disabled={!editMode}>
                    Password:
                    <input
                        className={`bg-transparent border-[1px] border-neutral-400 rounded-md p-3 font-bold ${editMode ? 'cursor-auto' : 'cursor-not-allowed'}`}
                        type="password"
                        name="password"
                        value={userData.password}
                        onChange={handleInputChange}
                        disabled={!editMode}
                    />
                    <p className='cursor-pointer text-white' onClick={handleEditClick}>Edit Password</p>
                </label>
                <br />
                <button className='bg-green-500 w-32 mx-auto p-2 rounded-full text-black font-semibold' onClick={handleUpdateClick}>Save Data</button>
            </div>
        </div>
    );
};

export default UserProfile;
