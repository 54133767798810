import React, { createContext, useContext, useState } from 'react';

const IdpContext = createContext();
  
export function useIdpContext() {
  return useContext(IdpContext);
}

export function IdpProvider({ children }) {
  const [selectedIdp, setSelectedIdp] = useState(null);

  const value = {
    selectedIdp,
    setSelectedIdp,
  };

  return (
    <IdpContext.Provider value={value}>
      {children}
    </IdpContext.Provider>
  );
}
