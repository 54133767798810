// Mp3.js
import React, { useEffect, useState } from 'react';
import { usePlaylistContext } from '../context/PlaylistContext';
import { useIdplaylistContext } from '../context/IdplaylistContext';
import { useMusicContext } from '../context/MusicContext';
import { usePlayContext } from '../context/PlayContext';



function PlaylistMp3(props) {
  const [data, setData] = useState(null);
  const { playlistItem,setPlaylistItem } = usePlaylistContext();
  const { selectedIdplaylist} = useIdplaylistContext();
  const { setCurrentIndex } = useMusicContext();
  const { image, setImage } = useMusicContext();
  const { song, setSong } = useMusicContext();
  const { artist, setArtist } = useMusicContext();
  const { idHls, setIdHls } = usePlayContext();
  const { setSelectedPlaylist } = usePlaylistContext();
  const { bg, setBg } = useMusicContext();





  useEffect(() => {
    // Check if selectedId is not null or undefined before making the API request
    if (selectedIdplaylist !== null && selectedIdplaylist !== undefined) {
      const apiUrl = `https://play.radiojavan.com/api/p/mp3_playlist_with_items?id=${selectedIdplaylist}`;
  
      fetch(`http://65.109.181.122:3000/proxy?url=${encodeURIComponent(apiUrl)}`, {
        method: 'GET',
        headers: {
          // You can add any headers needed for your proxy server here
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((responseData) => {
          setData(responseData);
          setPlaylistItem([...responseData.items]);
          setCurrentIndex(1);
          setIdHls(responseData.items[0].id);
          setImage(responseData.items[0].photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
          setSong(responseData.items[0].song);
          setArtist(responseData.items[0].artist);
          setSelectedPlaylist(responseData.items[0]);
          setBg(responseData.items[0].bg_colors);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [selectedIdplaylist,setPlaylistItem,setCurrentIndex]);

  
  return null;
}

export default PlaylistMp3;
