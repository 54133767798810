import React from "react";
import Effic from "./effic";
import { ReactSVG } from "react-svg";

import logoEffic from "../../logoEffic.svg"




function EfficArtist() {
    const svgStyle = {
        fill: '#fff',
        margin: 'auto',
        width: '200px'
    };


    return (
        <>
            <div className="flex justify-center flex-wrap mt-16">
                <ReactSVG style={svgStyle} src={logoEffic} />
                <div className="w-1/2 flex justify-center">
                    <img src="https://giftomo.s3.ir-thr-at1.arvanstorage.ir/dj_effic.jpg" className="object-cover w-48 h-48 mx-auto mt-10 mb-10 rounded-full"></img>
                </div>
                <div className="flex justify-center w-full my-1 gap-x-10">
                    <a href="https://instagram.com/dj_effic" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0,0,256,256">
                            <g fill="#aa251b" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"   ><g transform="scale(5.12,5.12)"><path d="M16,3c-7.17,0 -13,5.83 -13,13v18c0,7.17 5.83,13 13,13h18c7.17,0 13,-5.83 13,-13v-18c0,-7.17 -5.83,-13 -13,-13zM37,11c1.1,0 2,0.9 2,2c0,1.1 -0.9,2 -2,2c-1.1,0 -2,-0.9 -2,-2c0,-1.1 0.9,-2 2,-2zM25,14c6.07,0 11,4.93 11,11c0,6.07 -4.93,11 -11,11c-6.07,0 -11,-4.93 -11,-11c0,-6.07 4.93,-11 11,-11zM25,16c-4.96,0 -9,4.04 -9,9c0,4.96 4.04,9 9,9c4.96,0 9,-4.04 9,-9c0,-4.96 -4.04,-9 -9,-9z"></path></g></g>
                        </svg>
                    </a>
                    <a href="https://t.me/dj_effic" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0,0,256,256">
                            <g fill="#aa251b" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"  ><g transform="scale(5.12,5.12)"><path d="M25,2c12.703,0 23,10.297 23,23c0,12.703 -10.297,23 -23,23c-12.703,0 -23,-10.297 -23,-23c0,-12.703 10.297,-23 23,-23zM32.934,34.375c0.423,-1.298 2.405,-14.234 2.65,-16.783c0.074,-0.772 -0.17,-1.285 -0.648,-1.514c-0.578,-0.278 -1.434,-0.139 -2.427,0.219c-1.362,0.491 -18.774,7.884 -19.78,8.312c-0.954,0.405 -1.856,0.847 -1.856,1.487c0,0.45 0.267,0.703 1.003,0.966c0.766,0.273 2.695,0.858 3.834,1.172c1.097,0.303 2.346,0.04 3.046,-0.395c0.742,-0.461 9.305,-6.191 9.92,-6.693c0.614,-0.502 1.104,0.141 0.602,0.644c-0.502,0.502 -6.38,6.207 -7.155,6.997c-0.941,0.959 -0.273,1.953 0.358,2.351c0.721,0.454 5.906,3.932 6.687,4.49c0.781,0.558 1.573,0.811 2.298,0.811c0.725,0 1.107,-0.955 1.468,-2.064z"></path></g></g>
                        </svg>
                    </a>
                </div>
            </div>
            <div className="mb-10 mt-6">
                <div className="flex justify-end">
                    <div className="md:w-5/6  w-full">
                        <h2 className="ml-10 text-3xl font-bold my-6">Latest Podcast</h2>
                    </div>
                </div>
                <Effic />
            </div>
            <div className="w-full h-32">

            </div>
        </>
    );
}



export default EfficArtist;
