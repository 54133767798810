import React from 'react'
import { useIdpContext } from '../context/IdpContext';
import { useIdContext } from '../context/IdContext';
import { useIdvContext } from '../context/IdvContext';
import { useIdplaylistContext } from '../context/IdplaylistContext';
import { useMusicContext } from '../context/MusicContext';
import { usePlayContext } from '../context/PlayContext';
import { usePlaylistContext } from '../context/PlaylistContext';

function Button(props) {
    const { playlist } = props;
    const { setSelectedId } = useIdContext();
    const { setSelectedIdv } = useIdvContext();
    const { setSelectedIdp } = useIdpContext();
    const { setSelectedIdplaylist } = useIdplaylistContext();
    const { image, setImage } = useMusicContext();
    const { song, setSong } = useMusicContext();
    const { artist, setArtist } = useMusicContext();
    const { play, setPlay } = usePlayContext();
    const { idHls, setIdHls } = usePlayContext();
    const { bg, setBg } = useMusicContext();
    const { mp3Ref, setMp3Ref } = usePlayContext();
    const { setSelectedPlaylist } = usePlaylistContext();
    const { setPlaylistItem } = usePlaylistContext();
    const { showPlaylist, setShowPlaylist } = usePlaylistContext();



    // const handlePlayClick = (playlist) => {
    //     setSelectedIdp(playlist.item.id);
    //     setIdHls(playlist.item.id);
    //     setImage(playlist.item.photo);
    //     setSong(playlist.song);
    //     setArtist(playlist.artist);
    //     setSelectedPlaylist(playlist.item);
    //     setBg(playlist.item.bg_colors);



    //     setPlay(true);
    //     if (mp3Ref && idHls === playlist.item.id) {
    //         mp3Ref.play();
    //     }
    // };
    const handlePlayClick = (playlist) => {
        if (playlist.type === 'mp3') {
            if (playlist.item) {
                setSelectedId(playlist.id);
                setIdHls(playlist.id);
                setImage(playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                setSong(playlist.item.title);
                setArtist(playlist.item.artist);
                setSelectedPlaylist(playlist.item);
                setBg(playlist.item.bg_colors);
            } else {
                setSelectedId(playlist.id);
                setIdHls(playlist.id);
                setImage(playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                setSong(playlist.song);
                setArtist(playlist.artist);
                setSelectedPlaylist(playlist);
                setBg(playlist.bg_colors);
            }
            setShowPlaylist(true);

            setPlay(true);
            if (mp3Ref && idHls === playlist.id) {
                mp3Ref.play();
            }
        } else if (playlist.type === 'podcast') {
            if (playlist.item) {
                setSelectedIdp(playlist.id);
                setIdHls(playlist.id);
                setImage(playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                setSong(playlist.item.title);
                setArtist(playlist.item.podcast_artist);
                setSelectedPlaylist(playlist.item);
                setBg(playlist.item.bg_colors);
            }
            else {
                setSelectedIdp(playlist.id);
                setIdHls(playlist.id);
                setImage(playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                setSong(playlist.title);
                setArtist(playlist.podcast_artist);
                setSelectedPlaylist(playlist);
                setBg(playlist.bg_colors);
            }
            setShowPlaylist(true);

            setPlay(true);
            if (mp3Ref && idHls === playlist.id) {
                mp3Ref.play();
            }
        } else if (playlist.type === 'playlist') {
            if (playlist.item) {
                setSelectedIdplaylist(playlist.id);
                setIdHls(playlist.id);
                setImage(playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                setSong(playlist.item.title);
                setArtist(playlist.item.podcast_artist);
                setSelectedPlaylist(playlist.item);
                setBg(playlist.item.bg_colors);
            }
            else {
                setSelectedIdplaylist(playlist.id);
                setShowPlaylist(true);
                setBg(playlist.bg_colors);
            }
            setShowPlaylist(true);

            setPlay(true);
            if (mp3Ref && idHls === playlist.id) {
                mp3Ref.play();
            }
        } else if (playlist.podcast_artist === "DJ Effic") {
            setSelectedPlaylist(playlist);
            setPlaylistItem([playlist]);
            setIdHls(playlist.id);
            setImage(playlist.photo);
            setSong(playlist.title);
            setArtist(playlist.podcast_artist);
            setBg(playlist.bg_colors);
            setShowPlaylist(true);

            setPlay(true);
            if (mp3Ref && idHls === playlist.id) {
                mp3Ref.play();
            }
        } else if (playlist.type == 'video') {
            if (playlist.item) {
                setSelectedIdv(playlist.id);
                setIdHls(playlist.id);
                setImage(playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                setSong(playlist.item.title);
                setArtist(playlist.item.artist);
                setSelectedPlaylist(playlist.item);
                setBg(playlist.item.bg_colors);
            } else {
                setSelectedIdv(playlist.id);
                setIdHls(playlist.id);
                setImage(playlist.photo.replace('https://assets.rjassets.com', 'https://assets-rjassets.falsedev.com'));
                setSong(playlist.song);
                setArtist(playlist.artist);
                setSelectedPlaylist(playlist);
                setBg(playlist.bg_colors);

            }
            setShowPlaylist(true);
            setPlay(true);
            if (mp3Ref && idHls === playlist.id) {
                mp3Ref.play();
            }
        }
    };
    const handleDownloadClick = (playlist) => {
        // Create an invisible anchor element
        const link = document.createElement('a');
        if (playlist.type === 'mp3') {
            if (playlist.item) {
                link.href = playlist.item.link;
                if (link.href.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    link.href = link.href.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
                } else if (link.href.startsWith('https://host2.mediacon-rj.app')) {
                    link.href = link.href.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
                }
            } else {
                link.href = playlist.link;
                if (link.href.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    link.href = link.href.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
                } else if (link.href.startsWith('https://host2.mediacon-rj.app')) {
                    link.href = link.href.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
                }
            }
        } else if (playlist.type === 'podcast') {
            if (playlist.item) {
                link.href = playlist.item.link;
                if (link.href.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    link.href = link.href.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
                } else if (link.href.startsWith('https://host2.mediacon-rj.app')) {
                    link.href = link.href.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
                }
            }
            else {
                link.href = playlist.link;
                if (link.href.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    link.href = link.href.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
                } else if (link.href.startsWith('https://host2.mediacon-rj.app')) {
                    link.href = link.href.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
                }
            }
        } else if (playlist.podcast_artist === "DJ Effic") {
            if (playlist) {
                link.href = playlist.link;
                if (link.href.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    link.href = link.href.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
                } else if (link.href.startsWith('https://host2.mediacon-rj.app')) {
                    link.href = link.href.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
                }
            }
        } else if (playlist.type == 'video') {
            if (playlist.item) {
                link.href = playlist.item.link;
                if (link.href.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    link.href = link.href.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
                } else if (link.href.startsWith('https://host2.mediacon-rj.app')) {
                    link.href = link.href.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
                }
            } else {
                link.href = playlist.link;
                if (link.href.startsWith('https://host1.mediacon-rj.app')) {
                    // Replace the starting part of the URL
                    link.href = link.href.replace('https://host1.mediacon-rj.app', 'https://host1-media-rj.falsedev.com');
                } else if (link.href.startsWith('https://host2.mediacon-rj.app')) {
                    link.href = link.href.replace('https://host2.mediacon-rj.app', 'https://host2-media-rj.falsedev.com');
                }
            }
        }
        link.target = '_blank'; // Open the link in a new tab
        link.click();
    };



    const handlePause = (playlist) => {
        if (play === true && idHls === playlist.id) {
            mp3Ref.pause();
            setPlay(false);
        }
    };

    return (
        <div className="absolute w-full h-full rounded-xl bg-gradient-to-t from-black opacity-0 transition ease-in-out duration-300 image_artist">
            <div className="absolute md:bottom-6 md:left-6 bottom-3 left-3 cursor-pointer" >
                <div className={`bg-[rgba(153,27,27,0.7)] hover:bg-red-800 transition ease-in-out duration-500 p-2 rounded-full cursor-pointer ${idHls === playlist.id ? 'hidden' : ''}`} onClick={() => handlePlayClick(playlist)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6  ${idHls === playlist.id ? 'hidden' : ''}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                    </svg>
                </div>
                <div className={`bg-[rgba(153,27,27,0.7)] hover:bg-red-800 transition ease-in-out duration-500 p-2 rounded-full cursor-pointer ${play === true && idHls === playlist.id ? '' : 'hidden'}`} onClick={play === false ? () => handlePlayClick(playlist) : () => handlePause(playlist)} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${play === true && idHls === playlist.id ? '' : 'hidden'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                    </svg>
                </div>
                <div className={`bg-[rgba(153,27,27,0.7)] hover:bg-red-800 transition ease-in-out duration-500 p-2 rounded-full cursor-pointer ${idHls === playlist.id && play === false ? '' : 'hidden'}`} onClick={play === false ? () => handlePlayClick(playlist) : () => handlePause(playlist)}  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${idHls === playlist.id && play === false ? '' : 'hidden'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                    </svg>
                </div>
            </div>
            <div className="absolute md:bottom-6 md:right-6 bottom-3 right-3 cursor-pointer" >
                <div className="duration-700bg-[rgba(0,0,0,0.5)] hover:bg-black transition ease-in-out duration-500 p-2 rounded-full cursor-pointer" onClick={() => handleDownloadClick(playlist)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                </div>
            </div>
        </div>
    )
}
export default Button;